import * as React from "react";
import { useEffect, useState } from "react";
import { Field } from "@progress/kendo-react-form";
import {
    FormDropDown,
    FormInput,
    FormNumericInput,
} from "../../../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../../../resources/Deprecated/formValidators.js";
import BaseService from "../../../../../../../../services/BaseService.js";

export const SqftReceival = (props) => {
    const { record, formRenderProps } = props;
    const [storageLocations, setStorageLocations] = useState(undefined);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const service = new BaseService("Inventory/StorageLocation");
        service.getAll().then((res) => {
            setStorageLocations(res.data);
        });
    }, []);

    useEffect(() => {
        if (storageLocations) {
            renderElements();
        }
    }, [storageLocations]);

    const renderElements = () => {
        let rows = [];
        for (let i = 0; i < record.currentReceived; i++) {
            formRenderProps.onChange(`items[${i}].identifier`, {
                value: `${crypto.randomUUID()}`.slice(0, 8),
            });
            formRenderProps.onChange(`items[${i}].length`, {
                value: record.item.defaultLength,
            });
            formRenderProps.onChange(`items[${i}].width`, {
                value: record.item.defaultWidth,
            });

            rows.push(
                <span key={i}>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "left",
                            alignContent: "center",
                            gap: 10,
                            marginBottom: 10,
                        }}
                    >
                        <Field
                            name={`items[${i}].identifier`}
                            component={FormInput}
                            label={"Identifier:"}
                            validator={requiredValidator}
                            maxLength={8}
                            minLength={0}
                        />
                        <Field
                            name={`items[${i}].length`}
                            component={FormNumericInput}
                            label={"Length (Inch):"}
                            min={0}
                            format={"0"}
                            validator={requiredValidator}
                        />
                        <Field
                            name={`items[${i}].width`}
                            component={FormNumericInput}
                            label={"Width (Inch):"}
                            min={0}
                            format={"0"}
                            validator={requiredValidator}
                        />
                        <Field
                            name={`items[${i}].storageLocation`}
                            data={storageLocations}
                            component={FormDropDown}
                            dataItemKey={"id"}
                            textField={"name"}
                            label={"Storage Location of Item:"}
                            validator={requiredValidator}
                            style={{
                                width: 400,
                            }}
                        />
                    </span>
                    <hr className={"Separator"} />
                </span>
            );
        }

        setRows(rows);
    };

    return <span>{rows}</span>;
};
