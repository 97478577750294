import { ContactType } from "../../../../resources/Enums/ContactType.js";

export class VendorModalHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForNetwork(dataItem) {
        const formattedDataItem = {
            ...dataItem,
            paymentTypeId: dataItem?.paymentType?.id,
            billingTermId: dataItem?.billingTerm?.id,
            businessPhone: {
                typeId: dataItem.businessPhone?.phoneNumberType?.id,
                phoneNumber: dataItem.businessPhone?.phoneNumber,
            },
            incomeAccountId: dataItem.incomeAccount?.id,
            expenseAccountId: dataItem.expenseAccount?.id,
            contacts: [],
        };

        if (dataItem.primaryContact?.name1) {
            dataItem.primaryContact.type = ContactType.Enum.Primary;

            if (dataItem.primaryContact.phone)
                dataItem.primaryContact.phone.typeId =
                    dataItem.primaryContact.phone?.phoneNumberType?.id;

            formattedDataItem.contacts.push(dataItem.primaryContact);
        }

        if (dataItem.secondaryContact?.name1) {
            dataItem.secondaryContact.type = ContactType.Enum.Secondary;

            if (dataItem.secondaryContact.phone)
                dataItem.secondaryContact.phone.typeId =
                    dataItem.secondaryContact.phone?.phoneNumberType?.id;

            formattedDataItem.contacts.push(dataItem.secondaryContact);
        }

        if (dataItem.orderingContact?.name1) {
            dataItem.orderingContact.type = ContactType.Enum.Ordering;

            if (dataItem.orderingContact.phone)
                dataItem.orderingContact.phone.typeId =
                    dataItem.orderingContact.phone?.phoneNumberType?.id;

            formattedDataItem.contacts.push(dataItem.orderingContact);
        }

        if (dataItem.billingContact?.name1) {
            dataItem.billingContact.type = ContactType.Enum.Billing;

            if (dataItem.billingContact.phone)
                dataItem.billingContact.phone.typeId =
                    dataItem.billingContact.phone?.phoneNumberType?.id;

            formattedDataItem.contacts.push(dataItem.billingContact);
        }

        // Convert object into Array, then map over values to give correct naming for BE
        if (dataItem.vendorSocialMedias !== undefined) {
            formattedDataItem.vendorSocialMedias = Object.values(
                dataItem.vendorSocialMedias
            );
            formattedDataItem.vendorSocialMedias.map((socialMedia) => {
                socialMedia.socialMediaId = socialMedia.socialMedia?.id;
            });
        }

        return formattedDataItem;
    }

    /**
     * @function formatDataForModal
     * @description Format data for the event modal
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForModal(dataItem) {
        return {
            ...dataItem,
        };
    }

    /**
     * @function formatStepState
     * @description Format step state for the event modal
     * @param {Object} stepState - The step state object
     * @param {Boolean} isValid - The validation status
     * @return {Object}
     */
    static formatStepState = (stepState, isValid) => {
        const currentStep = stepState.currentStep + 1;

        return {
            ...stepState,
            steps: stepState.steps.map((currentStep, index) => ({
                ...currentStep,
                isValid:
                    index === stepState.currentStep
                        ? isValid
                        : currentStep.isValid,
            })),
            isLastStep: stepState.steps.length - 1 === currentStep,
            currentStep: currentStep,
        };
    };
}
