import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { SocialMediaModal } from "./Components/SocialMediaModal";
import { deleteSocialMedia } from "../../../../../services/Deprecated/general/socialMediaService";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const SocialMediaDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const modal = useModal();

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <SocialMediaModal {...modal} />
            <ActionButton
                onClick={() => modal.open()}
                text={"Add Social Media"}
                icon={"k-icon k-i-plus"}
                requiredPermissions={[PermissionsEnum.CreateSocialMedia]}
            />
            <DataGrid
                uri={"/General/SocialMedia/GetAll?"}
                style={{ maxWidth: "420px" }}
            >
                <GridColumn field="name" title="Name:" width={"250px"} />
                <GridColumn
                    width={150}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() =>
                                deleteSocialMedia(props.dataItem.id).then(() =>
                                    reloadDataContext.triggerReload()
                                )
                            }
                            onEdit={() => modal.open(props.dataItem.id)}
                            permissions={{
                                edit: [PermissionsEnum.UpdateSocialMedia],
                                delete: [PermissionsEnum.DeleteSocialMedia],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
export default SocialMediaDashboardPage;
