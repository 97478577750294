import React, { useContext, useEffect, useRef, useState } from "react";
import { TimeClockHelper } from "./Helpers/TimeClockHelper";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import "./TimeClockPage.module.scss";
import { ContentHeader } from "../../../components/ContentHeader";
import { TCEmployeeSearch } from "./Components/TCEmployeeSearch";
import {
    FormDropDown,
    FormRemoteDropDown,
} from "../../../components/Deprecated/FormComponents";
import { useTimeClockState } from "./Hooks/UseTimeClockState";
import { ActionButton } from "../../../components/Buttons/ActionButton";
import { formatDateOnlyForNetwork } from "../../../resources/Deprecated/dateHelper";
import dayjs from "dayjs";
import { clockTime } from "../../../services/Deprecated/humanResources/timeClockServices";
import { ClockInInformation } from "./Components/ClockInInformation";
import { useDidMount } from "../../../hooks/useDidMount";
import { getCurrentAvailableTasks } from "../../../services/Deprecated/humanResources/employeeServices";
import { ToastContext } from "../../../providers/ToastProvider.jsx";

const InitialFormState = {
    data: {
        job: null,
        event: null,
        task: null,
    },
    loaderVisible: false,
};

export const TimeClockPage = () => {
    const [formState, setFormState] = useState(InitialFormState);
    const {
        employee,
        setEmployee,
        canSearchRef,
        isClockedIn,
        refreshLastTc,
        lastTimeClock,
    } = useTimeClockState();
    const eventUrl = useRef(
        `/Customer/CalendarEvent/GetAll?start=${formatDateOnlyForNetwork(
            dayjs.utc().startOf("day").toDate()
        )}&end=${formatDateOnlyForNetwork(dayjs.utc().endOf("day").toDate())}&`
    );
    const [refreshFormKey, setRefreshFormKey] = useState(0);
    const [refreshTaskDropdownKey, setRefreshTaskDropdownKey] = useState(0);
    const [availableTasks, setAvailableTasks] = useState([]);
    const hasMounted = useDidMount();
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        // Ensure we have mounted so we are not doing data manipulation before we are ready
        if (!hasMounted) return;

        if (!formState.data.event) {
            setAvailableTasks([]);
            return;
        }

        getCurrentAvailableTasks({
            employeeId: employee?.id,
            jobId: formState.data?.job?.id,
            calendarEventId: formState.data?.event?.id,
        }).then((res) => {
            setAvailableTasks(res.data);

            // Refresh the dropdown to ensure the new data is displayed
            setRefreshTaskDropdownKey(refreshTaskDropdownKey + 1);
        });
    }, [formState.data?.event]);

    const onSubmit = () => {
        setFormState({ ...formState, loaderVisible: true });

        clockTime(
            employee,
            formState.data.task,
            formState.data.job,
            formState.data.event
        ).then((res) => {
            if (res.status >= 400) {
                showToast("Time Clock Entry Failed", "error");
                return;
            }

            // If you can search, reset the employee, so we can clock in another employee
            if (canSearchRef.current) {
                setEmployee(undefined);
            }

            setFormState(InitialFormState);
            // Refresh the last time clock, so we can see if we are clocked-in or out
            refreshLastTc();

            // Refresh Form due to Task not being reset when swapping Employees
            setRefreshFormKey(refreshFormKey + 1);

            showToast("Time Clock Entry Successful", "success");
        });
    };

    return (
        <div id={"TimeClockContainer"}>
            <ContentHeader
                title={TimeClockHelper.grabTimeClockContentTitle(
                    canSearchRef.current
                )}
                style={{ marginTop: 0 }}
            />
            <Form
                key={refreshFormKey}
                initialValues={formState.data}
                render={() => (
                    <FormElement>
                        {canSearchRef.current && (
                            <TCEmployeeSearch setter={setEmployee} />
                        )}
                        {employee && !isClockedIn && (
                            <>
                                <FormRemoteDropDown
                                    url={"/Customer/Job/GetAll?"}
                                    onChange={(e) =>
                                        setFormState({
                                            ...formState,
                                            data: {
                                                job: e.value,
                                                event: null,
                                                task: null,
                                            },
                                        })
                                    }
                                    label={"Job:"}
                                    style={{ width: "100%" }}
                                    initialFilters={[
                                        {
                                            field: "IsMarkedDead",
                                            operator: "eq",
                                            value: false,
                                        },
                                        {
                                            field: "IsArchived",
                                            operator: "eq",
                                            value: false,
                                        },
                                    ]}
                                />
                                <span
                                    className={"JustifyLeftAlignLeft"}
                                    style={{ marginTop: 5 }}
                                >
                                    <small>
                                        Hint: If no Job is selected, the Events
                                        shown will be Open or CoA Events
                                    </small>
                                </span>
                                <FormRemoteDropDown
                                    key={formState.data.job?.id}
                                    url={eventUrl.current}
                                    onChange={(e) =>
                                        setFormState({
                                            ...formState,
                                            data: {
                                                ...formState.data,
                                                event: e.value,
                                                task: null,
                                            },
                                        })
                                    }
                                    label={"Event:"}
                                    style={{ width: "100%" }}
                                    textField={"eventDateTitle"}
                                    searchFields={["eventDateTitle"]}
                                    initialFilters={{
                                        field: "JobId",
                                        operator: "eq",
                                        value: formState.data?.job?.id,
                                    }}
                                />
                                <span
                                    className={"JustifyLeftAlignLeft"}
                                    style={{ marginTop: 5 }}
                                >
                                    <small>
                                        Hint: Only shows today&apos;s Events
                                    </small>
                                </span>
                                <Field
                                    key={
                                        formState.data.event?.id +
                                        refreshTaskDropdownKey
                                    }
                                    name={"task"}
                                    keyField={"id"}
                                    textField={"name"}
                                    data={availableTasks}
                                    component={FormDropDown}
                                    onChange={(e) =>
                                        setFormState({
                                            ...formState,
                                            data: {
                                                ...formState.data,
                                                task: e.value,
                                            },
                                        })
                                    }
                                    label={"Task:"}
                                    style={{ width: "100%" }}
                                    disabled={!formState.data.event}
                                />
                            </>
                        )}
                    </FormElement>
                )}
            />
            {employee && (
                <>
                    {isClockedIn && <ClockInInformation data={lastTimeClock} />}
                    <br />
                    <ActionButton
                        text={TimeClockHelper.grabTimeClockSubmitButtonText(
                            isClockedIn
                        )}
                        disabled={!employee}
                        disabledToolTipMessage={"Please select an employee"}
                        onClick={() => onSubmit()}
                    />
                </>
            )}
        </div>
    );
};
