import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { TreeList } from "@progress/kendo-react-treelist";
import { YesNoCell } from "../../../../../components/GridColumnComponents/YesNoCell";
import { TaskCategoryModal } from "./Components/TaskCategoryModal";
import { getAllTaskCategories } from "../../../../../services/Deprecated/customer/eventServices";
import { TaskAndCategoryActionCell } from "./Components/TaskAndCategoryActionCell";
import { TaskModal } from "../Tasks/Components/TaskModal";
import { AddTaskModal } from "../Tasks/Components/AddTaskModal";
import EmployeeSkillModal from "./Components/EmployeeSkillModal";

const DefaultState = {
    data: [],
};

const TaskCategoryDashboardPage = () => {
    const { reloadData } = useContext(ReloadDataContext);
    const taskModal = useModal();
    const addTaskModal = useModal();
    const taskCategoryModal = useModal();
    const employeeSkillModal = useModal();
    const [state, setState] = useState(DefaultState);
    const columns = [
        {
            field: "name",
            title: "Name",
            width: "250px",
            expandable: true,
        },
        {
            field: "isAutoAssigned",
            title: "Is Auto-Assigned",
            width: "200px",
            cell: (props) => <YesNoCell {...props} />,
        },
        {
            title: "Actions",
            width: "200px",
            cell: (props) => (
                <TaskAndCategoryActionCell
                    {...props}
                    parentCategory={grabParentCategory(props.level[0])}
                    modals={{
                        task: taskModal,
                        addTask: addTaskModal,
                        taskCategory: taskCategoryModal,
                        employeeSkill: employeeSkillModal,
                    }}
                />
            ),
        },
    ];

    useEffect(() => {
        getAllTaskCategories().then((res) => {
            const data = res.data.map((category) => {
                category.expanded = category.tasks.length > 0;
                return category;
            });
            setState({ data: data });
        });
    }, [reloadData]);

    /**
     * @function grabParentCategory
     * @description Grabs the parent category of a task.
     * @return {*}
     */
    const grabParentCategory = (level) => {
        return state.data[level];
    };

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <TaskCategoryModal {...taskCategoryModal} />
            <TaskModal {...taskModal} />
            <AddTaskModal {...addTaskModal} />
            <EmployeeSkillModal {...employeeSkillModal} />
            <span className={"DisplayFlex SmallGap"}>
                <ActionButton
                    onClick={() => taskCategoryModal.open()}
                    text={"Create Category"}
                    icon={"k-icon k-i-plus"}
                    requiredPermissions={[PermissionsEnum.CreateTaskCategory]}
                />
            </span>
            <TreeList
                expandField={"expanded"}
                subItemsField={"tasks"}
                data={state.data}
                columns={columns}
            />
        </span>
    );
};
export default TaskCategoryDashboardPage;
