import React from "react";
import { Card, TabStripTab } from "@progress/kendo-react-layout";
import BusinessInformationDashboardPage from "./BusinessInformations/BusinessInformationDashboardPage";
import CommunicationTypeDashboardPage from "./CommunicationTypes/CommunicationTypeDashboardPage";
import SourceDashboardPage from "./Sources/SourceDashboardPage";
import EventTypeDashboardPage from "./EventTypes/EventTypeDashboardPage";
import { ContentHeader } from "../../../../components/ContentHeader";
import TaskCategoryDashboardPage from "./TaskCategories/TaskCategoryDashboardPage";
import TaskDashboardPage from "./Tasks/TaskDashboardPage";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";
import PermissionsHelper from "../../../../resources/PermissionsHelper.js";
import { useUserPermissions } from "../../../../hooks/useUserPermissions.js";
import UnitOfMeasureDashboardPage from "../Shared/UnitOfMeasures/UnitOfMeasureDashboardPage.jsx";

const CustomerTabPage = () => {
    const userPermissions = useUserPermissions();
    const hasPermissionToView = (requiredPermissions, optionalPermissions) => {
        return PermissionsHelper.hasPermission(
            userPermissions,
            requiredPermissions,
            optionalPermissions
        );
    };

    return (
        <div>
            <Card style={{ border: "none" }}>
                <ContentHeader
                    title="Customer Module Configuration"
                    className={"AlignTextLeft"}
                />
                <br />
                <HighlightedTabStrip className="tabs">
                    {hasPermissionToView([
                        PermissionsEnum.ViewBusinessInformation,
                    ]) && (
                        <TabStripTab title="Business Informations">
                            <BusinessInformationDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([
                        PermissionsEnum.ViewCommunicationType,
                    ]) && (
                        <TabStripTab title="Communication Types">
                            <CommunicationTypeDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([PermissionsEnum.ViewLeadSource]) && (
                        <TabStripTab title="Sources">
                            <SourceDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([PermissionsEnum.ReadEventType]) && (
                        <TabStripTab title="Event Types">
                            <EventTypeDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([
                        PermissionsEnum.ViewTaskCategory,
                    ]) && (
                        <TabStripTab title="Categories (Skill-Sets)">
                            <TaskCategoryDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([PermissionsEnum.ViewTask]) && (
                        <TabStripTab title="Tasks (Skills)">
                            <TaskDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([
                        PermissionsEnum.ViewUnitOfMeasure,
                    ]) && (
                        <TabStripTab title="Unit of Measures">
                            <UnitOfMeasureDashboardPage />
                        </TabStripTab>
                    )}
                </HighlightedTabStrip>
            </Card>
        </div>
    );
};

export default CustomerTabPage;
