import React from "react";
import { ActionButton } from "./ActionButton";

/**
 * @function ConfirmationButtons
 * @description
 * Confirmation buttons for a dialog
 * @param {CallableFunction} props.actionOnConfirm - Function to call on confirm
 * @param {CallableFunction} props.actionOnCancel - Function to call on cancel
 * @param {string} props.confirmationButtonText - Text for the confirmation button
 * @param {string} props.confirmationButtonThemeColor - Theme color for the confirmation button
 * @param {string} props.cancelButtonText - Text for the cancel button
 * @param {string} props.cancelButtonThemeColor - Theme color for the cancel button
 * @param {boolean} props.hideCancelButton - Whether to hide the cancel button
 * @return {React.JSX.Element}
 * @constructor
 */
export const ConfirmationButtons = (props) => {
    const {
        actionOnConfirm,
        actionOnCancel,
        confirmationButtonText = "Yes",
        confirmationButtonThemeColor = "error",
        cancelButtonText = "No",
        cancelButtonThemeColor = "primary",
        hideCancelButton = false,
    } = props;

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "10px",
            }}
        >
            {!hideCancelButton && (
                <ActionButton
                    themeColor={cancelButtonThemeColor}
                    onClick={actionOnCancel}
                    text={cancelButtonText}
                    style={{ width: "100px", margin: "10px" }}
                />
            )}
            <ActionButton
                themeColor={confirmationButtonThemeColor}
                onClick={actionOnConfirm}
                text={confirmationButtonText}
                style={{ width: "100px", margin: "10px" }}
            />
        </div>
    );
};
