import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, TabStripTab } from "@progress/kendo-react-layout";
import LeadService from "../../../services/LeadService";
import { ContentHeader } from "../../../components/ContentHeader";
import { ActionButton } from "../../../components/Buttons/ActionButton";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { LeadModal } from "./Components/LeadModal";
import { useModal } from "../../../hooks/useModal";
import { useHistory } from "react-router-dom";
import { CustomerTypeEnum } from "../../../resources/Enums/CustomerStatusEnum";
import { ReloadDataContext } from "../../../providers/ReloadDataProvider";
import LeadDetailsTab from "./Components/Tabs/LeadDetailsTab";
import LeadCommunicationsTab from "./Components/Tabs/LeadCommunicationsTab";
import LeadRemindersTab from "./Components/Tabs/LeadRemindersTab";
import { HighlightedTabStrip } from "../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";

const LeadDetailPage = (props) => {
    const history = useHistory();
    const { reloadData } = useContext(ReloadDataContext);
    const service = useRef(new LeadService("Customer/Lead"));
    const leadModal = useModal();
    const [lead, setLead] = useState(undefined);

    useEffect(() => {
        if (!props?.match?.params?.id) return;

        service.current.get(props?.match?.params?.id).then((res) => {
            setLead(res.data);
        });
    }, [props?.match?.params?.id, reloadData]);

    const onDelete = async () => {
        service.current.delete(lead.id).then(() => {
            history.push("/Lead");
        });
    };

    return (
        lead && (
            <div>
                <ContentHeader
                    title={`${CustomerTypeEnum.ParseToString(lead.type)}: ${
                        lead.combinedName
                    }`}
                    className={"JustifyLeftAlignCenter"}
                />
                <LeadModal {...leadModal} />
                <div className={"JustifyRightAlignCenter"}>
                    <ActionButton
                        text={"Go To Leads"}
                        icon={"k-icon k-i-undo"}
                        onClick={() => history.push("/Lead")}
                        requiredPermissions={[PermissionsEnum.ViewLead]}
                    />
                    <ActionButton
                        text={"Delete"}
                        confirmationState={{
                            needsConfirmation: true,
                        }}
                        onClick={onDelete}
                        requiredPermissions={[PermissionsEnum.DeleteLead]}
                    />
                    <ActionButton
                        text={"Edit"}
                        onClick={() => leadModal.open(lead.id)}
                        requiredPermissions={[PermissionsEnum.UpdateLead]}
                    />
                </div>
                <Card style={{ border: "none" }}>
                    <HighlightedTabStrip>
                        <TabStripTab title="Details">
                            <LeadDetailsTab {...lead} />
                        </TabStripTab>
                        <TabStripTab title="Communications">
                            <LeadCommunicationsTab {...lead} />
                        </TabStripTab>
                    </HighlightedTabStrip>
                </Card>
                <br />
                <Card style={{ border: "none" }}>
                    <HighlightedTabStrip>
                        <TabStripTab title="Reminders">
                            <LeadRemindersTab {...lead} />
                        </TabStripTab>
                    </HighlightedTabStrip>
                </Card>
            </div>
        )
    );
};

export default LeadDetailPage;
