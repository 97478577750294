import React from "react";
import { TabStripTab } from "@progress/kendo-react-layout";
import VendorDetails from "./VendorDetails";
import ContactDetails from "./ContactDetails";
import BillingDetails from "./BillingDetail";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";

const VendorTabs = () => {
    return (
        <HighlightedTabStrip className="tabs">
            <TabStripTab title="Vendor">
                <VendorDetails />
            </TabStripTab>
            <TabStripTab title="Contact">
                <ContactDetails />
            </TabStripTab>
            <TabStripTab title="Billing">
                <BillingDetails />
            </TabStripTab>
        </HighlightedTabStrip>
    );
};

export default VendorTabs;
