import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ActionCell } from "../../../../../../components/GridColumnComponents/ActionCell.jsx";
import { DataGrid } from "../../../../../../components/Grids/DataGrid.jsx";
import { JobDocumentModalContext } from "../Providers/JobDocumentModalProvider.jsx";
import { NewItemTypeEnum } from "../../../../../../resources/Enums/ItemTypeEnum.js";
import styles from "../Styles/JobDocumentModal.module.scss";
import { EditableNumericCell } from "../../../../../../components/GridColumnComponents/EditableNumericCell.jsx";
import { DocumentLineItemContext } from "../../../../../../providers/DocumentLineItemProvider.jsx";
import { EditableTextCell } from "../../../../../../components/GridColumnComponents/EditableTextCell.jsx";

export const JobDocumentDetailGrid = (props) => {
    const {
        dataItem: { id },
    } = props;
    const { canEdit } = useContext(JobDocumentModalContext);
    const { removeLineItem, lineItemsRef } = useContext(
        DocumentLineItemContext
    );

    return (
        <DataGrid
            className={styles.JobDocumentDetailGrid}
            style={{
                maxWidth: "99.99%",
            }}
            // Use the lineItemsRed because we want to edit this reference directly to save properly
            // using DataItem.childen will not save any edits because it is a copy of the original data
            data={lineItemsRef.current.find((item) => item.id === id)?.children}
            useInlineEdit={true}
            initialSort={[]}
            resizable={false}
            disabledPagination={true}
            showSaveChangesButtonGroup={false}
            showColumnsMultiSelect={false}
        >
            <GridColumn
                field={"item.name"}
                title={"Item:"}
                width={290}
                filterable={false}
                sortable={false}
                editable={false}
            />
            <GridColumn
                className={"k-table-td"}
                field="description"
                title="Description:"
                filterable={false}
                sortable={false}
                cell={(props) => (
                    <EditableTextCell
                        {...props}
                        placeholder={"Enter a description..."}
                        editField={"IN_LINE_EDIT"}
                        disabled={!canEdit}
                    />
                )}
            />
            <GridColumn
                field="quantity"
                title="Qty:"
                width={125}
                filterable={false}
                sortable={false}
                cell={(props) => (
                    <EditableNumericCell
                        {...props}
                        dataItem={props.dataItem}
                        format={
                            props.dataItem.type ===
                            NewItemTypeEnum.Enum.PercentageDiscount
                                ? "p"
                                : "n0"
                        }
                    />
                )}
            />
            <GridColumn
                field="price.amount"
                title="Price:"
                width={150}
                format={"{0:c2}"}
                filterable={false}
                sortable={false}
                editable={false}
            />
            <GridColumn
                field="total.amount"
                title="Total:"
                width={100}
                format={"{0:c2}"}
                filterable={false}
                sortable={false}
                editable={false}
            />
            {canEdit && (
                <GridColumn
                    width={150}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            additionalActions={[
                                {
                                    requiredPermissions: [],
                                    onClick: () => {
                                        removeLineItem(props.dataItem.id);
                                    },
                                    icon: "k-icon k-i-delete",
                                    themeColor: "error",
                                    type: "button",
                                    className: "export-hide",
                                },
                            ]}
                        />
                    )}
                />
            )}
        </DataGrid>
    );
};
