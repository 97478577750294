import React, { useContext, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { EventHelper } from "../Helpers/EventHelper";
import { updateEvent } from "../../../../services/Deprecated/customer/eventServices";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormInput } from "../../../../components/Deprecated/FormComponents";
import { FormButtons } from "../../../../components/Buttons/FormButtons";

const SpecificationModal = (props) => {
    const { record, close, visible } = props;
    const [loaderVisible, setLoaderVisible] = useState(false);
    const { triggerReload } = useContext(ReloadDataContext);

    const onSubmit = (dataItem) => {
        if (!dataItem.isValid) {
            return;
        }

        setLoaderVisible(true);

        const data = EventHelper.formatEventForNetwork(record);
        data.specification = dataItem.values.specification;

        updateEvent(data).then((_) => {
            triggerReload();
            close();
            setLoaderVisible(false);
        });
    };

    return (
        visible &&
        record && (
            <ResponsiveDialog
                record={record}
                title={"Enter Specification"}
                onClose={() => close()}
                size={"small"}
            >
                <Form
                    initialValues={record}
                    onSubmitClick={onSubmit}
                    render={() => (
                        <FormElement>
                            <Field
                                name={"specification"}
                                component={FormInput}
                                label={"Specification:"}
                            />
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={() => close()}
                                allowSubmit={true}
                                theme={"primary"}
                                text={"Submit"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};

export default SpecificationModal;
