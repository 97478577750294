import React, { useContext, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Stepper } from "@progress/kendo-react-layout";
import CompanyInformationForm from "./CompanyInformationForm";
import BillingInformationForm from "./BillingInformationForm";
import ContactInformationForm from "./ContactInformationForm";
import VendorSocialMediaForm from "./VendorSocialMediaForm";
import { useModal } from "../../../../hooks/useModal";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { createVendor } from "../../../../services/Deprecated/inventory/vendorService";
import PreCreateVendorSearchModal from "./PreCreateVendorSearchModal";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";
import { VendorModalHelper } from "../Helpers/VendorModalHelper.js";

const VendorCreationComponent = () => {
    const preCreateModal = useModal();
    const { bool: createVisible, toggle: toggleCreateVisible } = useToggle();
    const { triggerReload } = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [formState, setFormState] = React.useState({});
    const InitialStepState = {
        isLastStep: false,
        currentStep: 0,
        steps: [
            {
                label: "Company",
                isValid: undefined,
            },
            {
                label: "Billing",
                isValid: undefined,
            },
            {
                label: "Contact",
                isValid: undefined,
            },
        ],
        onPreviousStepSubmit: () => onPrev(),
    };
    const [stepState, setStepState] = useState(InitialStepState);

    const toggleAndResetForm = () => {
        if (createVisible) {
            setFormState({});
            setLoaderVisible(false);
            setStepState(InitialStepState);
        }
        toggleCreateVisible();
    };

    const onSubmit = (e) => {
        const { isValid, values, event } = e;

        if (!isValid) {
            return;
        }

        const buttonClickedStr = event?.nativeEvent?.submitter?.innerText;
        console.log(buttonClickedStr);
        if (!isValid || buttonClickedStr === "Next") {
            setStepState(VendorModalHelper.formatStepState(stepState, isValid));
            return;
        }

        setLoaderVisible(true);
        const data = VendorModalHelper.formatDataForNetwork(values);

        createVendor(data).then(() => {
            triggerReload();
            toggleAndResetForm();
        });
    };

    const onPrev = () => {
        setStepState({
            ...stepState,
            currentStep: Math.max(stepState.currentStep - 1, 0),
        });
    };

    return (
        <div>
            <ActionButton
                icon={"k-icon k-i-plus"}
                onClick={() => preCreateModal.open()}
                requiredPermissions={[PermissionsEnum.CreateVendor]}
            >
                Create New Vendor
            </ActionButton>
            <PreCreateVendorSearchModal
                {...preCreateModal}
                setFormState={setFormState}
                toggleCreateVisible={toggleCreateVisible}
            />
            {createVisible && (
                <span>
                    <Form
                        onSubmitClick={onSubmit}
                        initialValues={formState}
                        render={(formRenderProps) => (
                            <ResponsiveDialog
                                title={"Create Vendor"}
                                onClose={toggleAndResetForm}
                                size={"large"}
                            >
                                <Stepper
                                    value={stepState.currentStep}
                                    items={stepState.steps}
                                />
                                <FormElement>
                                    {stepState.currentStep === 0 && (
                                        <CompanyInformationForm
                                            formRenderProps={formRenderProps}
                                        />
                                    )}
                                    {stepState.currentStep === 1 && (
                                        <BillingInformationForm />
                                    )}
                                    {stepState.currentStep === 2 && (
                                        <span>
                                            <ContactInformationForm
                                                formRenderProps={
                                                    formRenderProps
                                                }
                                            />
                                            <VendorSocialMediaForm
                                                formState={formState}
                                                formRenderProps={
                                                    formRenderProps
                                                }
                                            />
                                        </span>
                                    )}
                                    <div
                                        style={{
                                            justifyContent: "space-between",
                                            alignContent: "center",
                                            borderTop: "1px solid #ccc",
                                        }}
                                        className={
                                            "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                                        }
                                    >
                                        <span
                                            style={{
                                                alignSelf: "center",
                                            }}
                                        >
                                            Step {stepState.currentStep + 1} of
                                            3
                                        </span>
                                        <FormButtons
                                            loaderVisible={loaderVisible}
                                            actionOnCancel={toggleAndResetForm}
                                            allowSubmit={
                                                formRenderProps.allowSubmit
                                            }
                                            isCreate={true}
                                            stepState={stepState}
                                        />
                                    </div>
                                </FormElement>
                            </ResponsiveDialog>
                        )}
                    />
                </span>
            )}
        </div>
    );
};

export default VendorCreationComponent;
