import React from "react";
import styles from "../Scheduler.module.scss";
import LinkTag from "../../../../components/LinkTag.jsx";
import { utcToLocalFormatter } from "../../../../resources/Deprecated/stringFormatter.js";
import { useModal } from "../../../../hooks/useModal.js";
import { PrintCalendarEventCardModal } from "./PrintCalendarEventCardModal/PrintCalendarEventCardModal.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";
import SchedulerTimeClockViewModal from "./SchedulerTimeClockViewModal.jsx";

export const SchedulerEvent = ({ event }) => {
    if (!event) return;

    const printModal = useModal();
    const timeClockEntryModal = useModal();

    const onLaborerClick = (laborer, eventId) => {
        timeClockEntryModal.open(undefined, {
            employeeId: laborer.id,
            employeeName: laborer.name,
            calendarEventId: eventId,
        });
    };

    return (
        <div
            className={styles.event}
            style={{ backgroundColor: event.eventType.calendarColor }}
        >
            <div id={"event-modals"}>
                <PrintCalendarEventCardModal {...printModal} />
                <SchedulerTimeClockViewModal
                    {...timeClockEntryModal}
                    dateRange={{
                        start: event.eventDate,
                        end: event.eventDate,
                    }}
                />
            </div>
            <div className={styles.eventText}>
                {event.customer ? (
                    <LinkTag
                        breadCrumbInfo={{
                            uri: (() => {
                                let uri = `/Customer/${event.customer.id}`;
                                if (event.job) uri += `/Job/${event.job.id}`;
                                return uri;
                            })(),
                            breadcrumbInfo: (() => {
                                let info = [
                                    {
                                        id: event.customer.id,
                                        text: event.customer.name,
                                    },
                                ];

                                if (event.job)
                                    info.push({
                                        id: event.job.id,
                                        text: event.job.name,
                                    });

                                return info;
                            })(),
                        }}
                        text={event.customer.name}
                        requiredPermissions={[PermissionsEnum.ViewJob]}
                    />
                ) : (
                    <LinkTag
                        onClick={() => console.log("open event click")}
                        text={`OPEN ${event.eventDateTitle}`}
                        requiredPermissions={[
                            PermissionsEnum.CreateCalendarEvent,
                        ]}
                    />
                )}
            </div>
            {event.specification && (
                <div className={styles.eventText}>
                    <span className={styles.eventText}>
                        {event.specification}
                    </span>
                    <span className={styles.eventTextRed}> Specs</span>
                </div>
            )}
            {event.job && (
                <div className={styles.eventText}>
                    <LinkTag
                        breadCrumbInfo={{
                            uri: `/Event/${event.id}`,
                            breadcrumbInfo: [
                                {
                                    id: event.id,
                                    text: event.eventDateTitle,
                                },
                            ],
                        }}
                        text={event.job.name}
                        requiredPermissions={[
                            PermissionsEnum.ReadCalendarEvent,
                        ]}
                    />
                </div>
            )}
            <div className={styles.eventText}>{event.eventType.name}</div>
            <div className={styles.eventTextContainer}>
                {event.appointmentTime ? (
                    <div className={styles.eventText}>
                        {`${utcToLocalFormatter(
                            event.appointmentTime.startTime,
                            "h:mm A"
                        )} - ${utcToLocalFormatter(
                            event.appointmentTime.endTime,
                            "h:mm A"
                        )}`}
                    </div>
                ) : (
                    <div className={styles.eventTextRed}>***NO TIMESLOT***</div>
                )}
                <i
                    className={"k-icon k-i-print"}
                    onClick={() => printModal.open(event.id)}
                />
            </div>
            <div className={styles.eventText}>
                {event.laborers.length ? (
                    event.laborers.map((laborer, index) => (
                        <span key={laborer.id}>
                            <LinkTag
                                onClick={() =>
                                    onLaborerClick(laborer, event.id)
                                }
                                text={laborer.name}
                                requiredPermissions={[
                                    PermissionsEnum.ViewEmployee,
                                ]}
                            />
                            {index < event.laborers.length - 1 && (
                                <span>, </span>
                            )}
                        </span>
                    ))
                ) : (
                    <span className={styles.eventTextRed}>***NO LABOR***</span>
                )}
            </div>
        </div>
    );
};
