import React, { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import PreCreateCustomerSearchModal from "./PreCreateCustomerSearchModal";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import {
    addCustomer,
    getCustomer,
    updateCustomer,
} from "../../../../services/Deprecated/customer/customerServices";
import { ContactType } from "../../../../resources/Enums/ContactType";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormInput } from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import { ContactInput } from "../../../../components/FormInputs/ContactInput";
import { CustomerTypeEnum } from "../../../../resources/Enums/CustomerStatusEnum";
import StringHelper from "../../../../resources/StringHelper";
import { AddressInput } from "../../../../components/FormInputs/AddressInput";
import { InputLayouts } from "../../../../components/FormInputs/InputLayouts.js";

export const CustomerModal = (props) => {
    const { visible, close, id, isCreate, isUpdate, record, setRecord } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        if (isUpdate && id) {
            getCustomer(id).then((res) => {
                setRecord(res.data);
            });
        }
    }, [id, isUpdate, setRecord]);

    const onFormSubmit = async (dataItem) => {
        setLoaderVisible(true);

        const contacts = [];
        const formatContact = (contact, type) => ({
            ...contact,
            type: type,
            label: contact.label || "",
            phone: !StringHelper.isNullOrEmpty(contact.phone?.phoneNumber)
                ? {
                      phoneNumber: contact.phone?.phoneNumber,
                      typeId: contact.phone?.typeId,
                  }
                : undefined,
            address: contact.address ?? {},
        });

        if (dataItem.primaryContact) {
            contacts.push(
                formatContact(dataItem.primaryContact, ContactType.Enum.Primary)
            );
        }

        if (dataItem.secondaryContact) {
            contacts.push(
                formatContact(
                    dataItem.secondaryContact,
                    ContactType.Enum.Secondary
                )
            );
        }

        if (
            Array.isArray(dataItem.otherContacts) &&
            dataItem.otherContacts.length > 0
        ) {
            dataItem.otherContacts.forEach((contact) => {
                contacts.push(formatContact(contact, ContactType.Enum.Other));
            });
        }

        const formattedDataItem = {
            ...dataItem,
            contacts,
        };

        const handleResponse = (response) => {
            if (response.success) {
                setRecord((prevRecord) => ({
                    ...prevRecord,
                    ...formattedDataItem,
                }));
                triggerReload();
                resetForm();
            }
        };

        const service = id ? updateCustomer : addCustomer;

        service(formattedDataItem).then(handleResponse);
    };

    const resetForm = () => {
        close();
        setRecord(undefined);
        setLoaderVisible(false);
    };

    return (
        visible && (
            <>
                {isCreate && !record && (
                    <PreCreateCustomerSearchModal
                        close={close}
                        setRecord={setRecord}
                    />
                )}
                {record && (
                    <Form
                        onSubmit={onFormSubmit}
                        initialValues={record}
                        render={(formRenderProps) => (
                            <ResponsiveDialog
                                title={
                                    !id ? "Create Customer" : "Update Customer"
                                }
                                onClose={resetForm}
                                size={"medium"}
                            >
                                <FormElement>
                                    <span>
                                        {record.type ===
                                        CustomerTypeEnum.Enum.Individual ? (
                                            <span
                                                className={
                                                    "JustifyLeftAlignCenter SmallGap"
                                                }
                                            >
                                                <span
                                                    style={{
                                                        width: "calc(50% - 10px)",
                                                    }}
                                                >
                                                    <Field
                                                        name={"name1"}
                                                        component={FormInput}
                                                        label={"First"}
                                                        validator={
                                                            requiredValidator
                                                        }
                                                        onChange={(e) => {
                                                            formRenderProps.onChange(
                                                                "primaryContact.name1",
                                                                {
                                                                    value: e.value,
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </span>
                                                <span
                                                    style={{
                                                        width: "calc(50% - 10px)",
                                                    }}
                                                >
                                                    <Field
                                                        name={"name2"}
                                                        component={FormInput}
                                                        label={"Last"}
                                                        validator={
                                                            requiredValidator
                                                        }
                                                        onChange={(e) => {
                                                            formRenderProps.onChange(
                                                                "primaryContact.name2",
                                                                {
                                                                    value: e.value,
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </span>
                                            </span>
                                        ) : (
                                            <Field
                                                name={"name1"}
                                                component={FormInput}
                                                label={"Business Name"}
                                                validator={requiredValidator}
                                            />
                                        )}
                                        <Field
                                            name={"address"}
                                            component={AddressInput}
                                            label={"Address"}
                                            isRequired={true}
                                            layout={InputLayouts.Vertical}
                                            formRenderProps={formRenderProps}
                                        />
                                        <br />
                                        <hr className={"Separator"} />
                                        <br />
                                        <ContactInput
                                            field={"primaryContact"}
                                            isRequired={true}
                                            mustHavePhoneAndEmail={!id}
                                            formRenderProps={formRenderProps}
                                            className={
                                                "JustifyLeftAlignLeft SmallGap"
                                            }
                                        />
                                    </span>
                                    <FormButtons
                                        allowSubmit={
                                            formRenderProps.allowSubmit
                                        }
                                        isCreate={!id}
                                        actionOnCancel={resetForm}
                                        loaderVisible={loaderVisible}
                                    />
                                </FormElement>
                            </ResponsiveDialog>
                        )}
                    />
                )}
            </>
        )
    );
};
