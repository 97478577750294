import React, { useContext } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Hint } from "@progress/kendo-react-labels";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import { updateJobCommunication } from "../../../../services/Deprecated/customer/jobServices";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormTextArea } from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";

const JobCommunicationEdit = ({
    visible,
    onCancel,
    record,
    loaderVisible,
    toggleLoaderVisible,
}) => {
    const reloadDataContext = useContext(ReloadDataContext);
    const jobContext = useContext(JobContext);

    const [value, setValue] = React.useState(record.notes || "");

    const max = 500;

    const handleChange = (e) => {
        setValue(e.value);
    };

    const submitJobCommunication = (dataItem) => {
        toggleLoaderVisible();

        dataItem.jobId = jobContext.job.id;
        dataItem.employeeId = jobContext.job.id;

        updateJobCommunication(dataItem)
            .then(() => {
                reloadDataContext.triggerReload(dataItem);
                onCancel();
            })
            .catch((error) => {
                console.error("Error Message: ", error.message);
                if (error.response) {
                    console.error("Response Data: ", error.response.data);
                    console.error("Response Status: ", error.response.status);
                    console.error("Response Headers: ", error.response.headers);
                }
            })
            .finally(() => {
                toggleLoaderVisible();
            });
    };

    return (
        <div>
            {visible && (
                <Form
                    initialValues={record}
                    onSubmit={submitJobCommunication}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Edit Communication"}
                            onClose={onCancel}
                            size={"small"}
                        >
                            <FormElement>
                                <fieldset
                                    className={"k-form-fieldset"}
                                    style={{ textAlign: "left" }}
                                >
                                    <label
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Notes:
                                    </label>
                                    <Field
                                        name={"note"}
                                        component={FormTextArea}
                                        autoSize={true}
                                        rows={4}
                                        validator={requiredValidator}
                                        maxLength={max}
                                        value={value}
                                        onChange={handleChange}
                                    />
                                    <Hint direction={"end"}>
                                        {value.length} / {max}
                                    </Hint>
                                </fieldset>
                                <FormButtons
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={onCancel}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    isCreate={false}
                                />
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default JobCommunicationEdit;
