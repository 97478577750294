import React, { useContext, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../components/Deprecated/DialogWrapper";
import {
    FormDropDown,
    FormNumericInput,
} from "../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../components/Buttons/FormButtons";

export const PriceChangeTypes = {
    Dollar: {
        id: 0,
        name: "dollar(s)",
    },
    Percent: {
        id: 1,
        name: "percent",
    },
    PercentGreaterThanMaxCost: {
        id: 2,
        name: "percent greater than Max Cost",
    },
};

/**
 * @function PriceChangeModal
 * @description A modal for changing the price of items in bulk
 * @param {boolean} props.visible
 * @param {function} props.close
 * @param {object} props.record
 * @param {function} props.submitCallback
 * @param {Array|object} props.fields
 * @param {Array|PriceChangeTypes} props.types
 * @return {Element}
 * @constructor
 */
export const PriceChangeModal = (props) => {
    const { visible, close, submitCallback, fields, types } = props;
    const InitialValues = {
        field: fields[0],
        action: {
            id: 0,
            name: "Increase",
        },
        amount: 0.0,
        type: types[0],
    };
    const { triggerReload } = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [dropDownTypeOptions, setDropDownTypeOptions] = useState(types);
    const [initialValues, setInitialValues] = useState(InitialValues);
    const [refreshFormKey, setRefreshFormKey] = useState(0);

    // Submits the object to the API
    const onSubmit = (event) => {
        if (!event.isValid) return;

        const values = event.values;

        setLoaderVisible(true);

        const data = {
            field: values.field.name,
            action: values.action.name,
            amount: values.amount,
            type: values.type.id,
        };

        submitCallback(data).then((_) => {
            triggerReload();
            close();
            resetState();
            setLoaderVisible(false);
        });
    };

    /**
     * This function handles validation logic on what elements in the type drop-down should
     * be disabled based on the action field.
     * @param e
     * @param formRenderProps
     */
    const onActionChange = (e, formRenderProps) => {
        const action = e.target.value;

        if (!action) {
            setInitialValues({
                ...initialValues,
                action: InitialValues.action,
            });
            return;
        }

        // Reset initial values
        setInitialValues({
            field: formRenderProps.valueGetter("field"),
            action: action,
            amount: formRenderProps.valueGetter("amount"),
            type: InitialValues.type,
        });

        if (action.name !== "Set") {
            setDropDownTypeOptions(
                returnArrayWithDropDownItemDisabled(
                    PriceChangeTypes.PercentGreaterThanMaxCost.name
                )
            );
        } else if (action.name === "Set") {
            setDropDownTypeOptions(
                returnArrayWithDropDownItemDisabled(
                    PriceChangeTypes.Percent.name
                )
            );
        } else {
            setDropDownTypeOptions(types);
        }

        // Increment the key to refresh the form
        setRefreshFormKey(refreshFormKey + 1);
    };

    /**
     * @function returnArrayWithDropDownItemDisabled
     * This function will return an array with a specific item disabled
     * @param {string} name - The name of the item to be disabled
     * @return {((*&{disabled: boolean})|{name: string, id: number}|{name: string, id: number}|{name: string, id: number})[]}
     * @constructor
     */
    const returnArrayWithDropDownItemDisabled = (name) => {
        return types.map((item) => {
            if (item.name === name) {
                return { ...item, disabled: true };
            }
            return item;
        });
    };

    // Resets the state of the modal
    const resetState = () => {
        setDropDownTypeOptions(types);
        setInitialValues(InitialValues);
        setRefreshFormKey(0);
    };

    return (
        visible && (
            <Form
                key={refreshFormKey}
                initialValues={initialValues}
                onSubmitClick={onSubmit}
                render={(formRenderProps) => (
                    <ResponsiveDialog
                        title={"Apply a change to a Retail Price"}
                        onClose={close}
                        size={"medium"}
                    >
                        <p>
                            This price change will be applied to{" "}
                            <strong>all Items currently loaded</strong> in the
                            items table below, including any items on other
                            pages.
                        </p>
                        <FormElement>
                            <span
                                className={
                                    "JustifyCenterAndAlignCenter SmallGap"
                                }
                            >
                                <Field
                                    name={"action"}
                                    component={FormDropDown}
                                    clearButton={false}
                                    data={[
                                        { id: 0, name: "Increase" },
                                        { id: 1, name: "Decrease" },
                                        { id: 2, name: "Set" },
                                    ]}
                                    style={{ width: "150px" }}
                                    validator={requiredValidator}
                                    onChange={(e) =>
                                        onActionChange(e, formRenderProps)
                                    }
                                />
                                <Field
                                    name={"field"}
                                    component={FormDropDown}
                                    clearButton={false}
                                    data={fields}
                                    style={{ width: "150px" }}
                                    validator={requiredValidator}
                                />
                                <p>by</p>
                                <Field
                                    name={"amount"}
                                    component={FormNumericInput}
                                    clearButton={false}
                                    style={{ width: "100px" }}
                                    min={0}
                                    validator={requiredValidator}
                                />
                                <Field
                                    name={"type"}
                                    component={FormDropDown}
                                    clearButton={false}
                                    data={dropDownTypeOptions}
                                    textField="name"
                                    style={{ width: "200px" }}
                                    validator={requiredValidator}
                                    skipDisabledItems={true}
                                />
                            </span>
                            <FormButtons
                                actionOnCancel={close}
                                loaderVisible={loaderVisible}
                                isCreate={false}
                                theme={"primary"}
                                allowSubmit={true}
                                text={"Apply"}
                            />
                        </FormElement>
                    </ResponsiveDialog>
                )}
            />
        )
    );
};
