import React from "react";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import { Field } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useAccounts } from "../../../../../hooks/Deprecated/useAccounts";
import {
    FormDropDown,
    FormInput,
    FormNumericInput,
    FormTextArea,
} from "../../../../../components/Deprecated/FormComponents";
import {
    nameValidator,
    requiredValidator,
} from "../../../../../resources/Deprecated/formValidators";
import ItemCategoryModal from "../../Components/ItemCategoryModal.jsx";
import { ActionButton } from "../../../../../components/Buttons/ActionButton.jsx";
import { useModal } from "../../../../../hooks/useModal.js";
import { AccountDropdownItemRenderer } from "../../../../../components/DropDownRenders/AccountDropDownRender.jsx";

export const DetailItemInformationFormExtension = ({
    item,
    formRenderProps,
}) => {
    const categoryModal = useModal();
    const { expenseAccounts, incomeAccounts, isLoading } = useAccounts();

    return (
        !isLoading && (
            <>
                <GridLayoutItem col={1} row={1}>
                    <Field
                        name={"name"}
                        component={FormInput}
                        label={"Name:"}
                        validator={nameValidator}
                    />
                    <Field
                        name={"retailPrice.amount"}
                        component={FormNumericInput}
                        format={"c2"}
                        label={"Retail Price:"}
                    />
                    <Field
                        name={"projectedCost.amount"}
                        component={FormNumericInput}
                        format={"c2"}
                        label={"Projected Cost:"}
                    />
                    <div style={{ display: "flex", alignItems: "end", gap: 5 }}>
                        <Field
                            style={{ width: "325px" }}
                            name={"category.name"}
                            component={FormInput}
                            label={"Category:"}
                            readOnly={true}
                        />
                        <ItemCategoryModal
                            {...categoryModal}
                            item={item}
                            formRenderProps={formRenderProps}
                        />
                        <ActionButton
                            onClick={() => categoryModal.open()}
                            text={"Change"}
                        />
                    </div>
                    <span
                        style={{ marginTop: 10 }}
                        className={"JustifyLeftAlignCenter FlexColumn LargeGap"}
                    >
                        <Field
                            name={"isActive"}
                            component={Checkbox}
                            label={"Is Active"}
                            rounded={"large"}
                            size={"medium"}
                        />
                        <Field
                            name={"isDescriptionEditAllowed"}
                            component={Checkbox}
                            label={"Is Description Edit Allowed"}
                            rounded={"large"}
                            size={"medium"}
                        />
                    </span>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1}>
                    <Field
                        name={"expenseAccount"}
                        component={FormDropDown}
                        data={expenseAccounts}
                        dataItemKey="id"
                        textField="name"
                        validator={requiredValidator}
                        label={"Expense Account:"}
                        itemRender={AccountDropdownItemRenderer}
                    />
                    <Field
                        name={"incomeAccount"}
                        component={FormDropDown}
                        data={incomeAccounts}
                        dataItemKey="id"
                        textField="name"
                        validator={requiredValidator}
                        label={"Income Account:"}
                        itemRender={AccountDropdownItemRenderer}
                    />
                </GridLayoutItem>
                <GridLayoutItem col={1} row={3} colSpan={2}>
                    <fieldset
                        className={"k-form-fieldset"}
                        style={{ marginTop: 0.5 }}
                    >
                        <h4
                            style={{
                                margin: "0",
                                marginTop: "8px",
                                marginBottom: "8px",
                                borderBottom: "1px solid #ccc",
                                paddingBottom: "5px",
                                width: "55%",
                                textAlign: "left",
                            }}
                        >
                            SE/SO Description:
                        </h4>
                        <Field
                            name={"baseDescription"}
                            component={FormTextArea}
                            validator={requiredValidator}
                            autoSize={true}
                            rows={4}
                            label={"SE/SO Description:"}
                        />
                    </fieldset>
                </GridLayoutItem>
            </>
        )
    );
};
