import * as React from "react";
import { useContext, useRef } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { StorageLocationModal } from "./Components/StorageLocationModal";
import { DataGrid } from "../../../../../components/Grids/DataGrid";
import BaseService from "../../../../../services/BaseService";

const StorageLocationDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const baseService = useRef(new BaseService("Inventory/StorageLocation"));
    const modal = useModal();

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <StorageLocationModal {...modal} />
            <ActionButton
                onClick={() => modal.open()}
                text={"Add Storage Location"}
                icon={"k-icon k-i-plus"}
                requiredPermissions={[PermissionsEnum.CreateStorageLocation]}
            />
            <DataGrid
                uri={"/Inventory/StorageLocation/GetAll?"}
                style={{ maxWidth: "420px" }}
            >
                <GridColumn field="name" title="Name:" width={"250px"} />
                <GridColumn
                    width={150}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() =>
                                baseService.current
                                    .delete(props.dataItem.id)
                                    .then(() =>
                                        reloadDataContext.triggerReload()
                                    )
                            }
                            onEdit={() => modal.open(props.dataItem.id)}
                            permissions={{
                                edit: [PermissionsEnum.UpdateStorageLocation],
                                delete: [PermissionsEnum.DeleteStorageLocation],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
export default StorageLocationDashboardPage;
