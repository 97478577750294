import React, { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import styles from "../BusinessInformationModal.module.scss";
import { Hint } from "@progress/kendo-react-labels";
import BusinessInformationPreview from "./BusinessInformationPreview";
import { BusinessInformationContext } from "../../../../../../providers/Deprecated/Customer/BusinessInformationProvider";
import { CustomerContext } from "../../../../../../providers/Deprecated/Customer/CustomerProvider";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider";
import BusinessInformationService from "../../../../../../services/Deprecated/customer/businessInformationService";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper";
import {
    FormInput,
    FormPhoneInput,
    FormTextArea,
    FormUpload,
} from "../../../../../../components/Deprecated/FormComponents";
import {
    emailFormatValidator,
    filesRequired,
    nameValidator,
} from "../../../../../../resources/Deprecated/formValidators";
import { AddressInput } from "../../../../../../components/FormInputs/AddressInput.jsx";
import { InputLayouts } from "../../../../../../components/FormInputs/InputLayouts.js";
import { ActionButton } from "../../../../../../components/Buttons/ActionButton.jsx";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";

const BusinessInformationModal = () => {
    const businessInfoContext = useContext(BusinessInformationContext);
    const customerContext = useContext(CustomerContext);
    const reloadDataContext = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [initialRecord, setInitialRecord] = useState({});
    const [mappingInitialRecord, setMappingInitialRecord] = useState(true);
    const [previewHeadVisible, setPreviewHeadVisible] = useState(false);

    //? On load we want to check if this is an Edit operation, if so we want to use
    //? the selected BusinessInfo, else we will just use the default empty object
    useEffect(() => {
        const operation = businessInfoContext.selectedBusinessInfo.operation;
        const businessInfo =
            businessInfoContext.selectedBusinessInfo?.businessInformation
                ?.data ?? {};

        //? Check all exported data to see if a IsDefault value exists
        const defaultValues = businessInfoContext.exportedBusinessInfos.flatMap(
            (val) => {
                if (
                    val?.businessInformationId ===
                    businessInfo?.businessInformationId
                ) {
                    return false;
                }

                return val.isDefault;
            }
        );
        const isDefaultRequired = !defaultValues.includes(true);

        if (isDefaultRequired) {
            businessInfo.isDefault = true;
            businessInfo.isDefaultRequired = true;
        }

        if (operation === "Edit" && businessInfo) {
            const logoFile = businessInfo.file;
            //? Formats the file to be compatible with kendo file manager
            const uid = crypto.randomUUID();
            const formattedLogoFile = {
                name: logoFile.fileName,
                extension: logoFile.extension,
                progress: 0,
                size: logoFile.size,
                status: 2,
                uid: uid,
                uri: logoFile.uri,
            };

            businessInfo.file.uid = uid;
            businessInfo.files = [formattedLogoFile];
            setInitialRecord(businessInfo);
        }

        setMappingInitialRecord(false);
    }, [
        businessInfoContext.selectedBusinessInfo.operation,
        businessInfoContext.selectedBusinessInfo.businessInformation,
    ]);

    /**
     *  @function onCancel
     *  @description Resets the selected business info to its default state
     *  @return void
     */
    const onCancel = () => {
        businessInfoContext.resetSelectedBusinessInfo();
    };

    /**
     * @function onSubmit
     * @description Submits the object
     * @returns {void}
     */
    const onSubmit = async (dataItem) => {
        const buttonClicked = dataItem.event?.nativeEvent?.submitter?.innerText;
        const updateOrSubmitClicked =
            buttonClicked === "Create" || buttonClicked === "Update";

        const formattedDataItem = {
            ...dataItem.values,
        };

        if (!dataItem.isValid || !updateOrSubmitClicked) {
            return;
        }

        setLoaderVisible(true);
        const operation = businessInfoContext.selectedBusinessInfo.operation;

        if (operation === "Create") {
            formattedDataItem.file = formattedDataItem.files[0].getRawFile();

            BusinessInformationService.createBusinessInformation(
                formattedDataItem
            ).then((_) => {
                reloadDataContext.triggerReload();
                customerContext.reloadBusinessInfo();
                onCancel();
            });
        } else {
            const files = dataItem.values.files;
            const originalFile = dataItem.values.file;

            BusinessInformationService.updateBusinessInformation(
                formattedDataItem
            ).then(async (_) => {
                if (files[0].uid !== originalFile.uid) {
                    await BusinessInformationService.swapBusinessInformationLogo(
                        files[0].getRawFile(),
                        dataItem.values.id
                    );
                }

                reloadDataContext.triggerReload();
                customerContext.reloadBusinessInfo();

                onCancel();
            });
        }
        setLoaderVisible(false);
    };

    return (
        !mappingInitialRecord && (
            <Form
                onSubmitClick={onSubmit}
                initialValues={initialRecord}
                render={(formRenderProps) => (
                    <ResponsiveDialog
                        title={
                            businessInfoContext.selectedBusinessInfo.operation +
                            " Business Information"
                        }
                        onClose={() => onCancel()}
                        size={"large"}
                    >
                        <FormElement>
                            <div className={styles.FormContainer}>
                                <span style={{ width: "100%" }}>
                                    <Field
                                        name={"nickName"}
                                        component={FormInput}
                                        validator={nameValidator}
                                        label={"Business Info Nick Name:"}
                                        hint={
                                            "Hint: This should be unique so this record can be found easily"
                                        }
                                    />
                                    <Field
                                        name={"businessName"}
                                        component={FormInput}
                                        validator={nameValidator}
                                        label={"Company Name:"}
                                    />
                                    <span className={styles.TagLineContainer}>
                                        <label>Tag Line:</label>
                                        <Field
                                            name={"tagLine"}
                                            component={FormTextArea}
                                            autoSize={true}
                                            rows={2}
                                        />
                                    </span>
                                    <Field
                                        name={"email"}
                                        component={FormInput}
                                        label={"Email:"}
                                        validator={emailFormatValidator}
                                    />
                                    <Field
                                        name={"phone"}
                                        component={FormPhoneInput}
                                        formRenderProps={formRenderProps}
                                        isRequired={true}
                                    />
                                    <Field
                                        name={"website"}
                                        component={FormInput}
                                        label={"Website:"}
                                    />
                                    <span className={styles.LogoContainer}>
                                        <label>Company Logo:</label>
                                        <div>
                                            You can only upload{" "}
                                            <strong>JPEG</strong>,{" "}
                                            <strong>PDF</strong>,{" "}
                                            <strong>JPG</strong> and{" "}
                                            <strong>PNG</strong> files.
                                        </div>
                                    </span>
                                    <Field
                                        name={"files"}
                                        validator={filesRequired}
                                        component={(fieldRenderProps) => {
                                            return (
                                                <FormUpload
                                                    fieldRenderProps={
                                                        fieldRenderProps
                                                    }
                                                    multiple={false}
                                                    restrictions={{
                                                        allowedExtensions: [
                                                            ".jpeg",
                                                            ".pdf",
                                                            ".jpg",
                                                            ".png",
                                                        ],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </span>
                                <span style={{ width: "100%" }}>
                                    <Field
                                        name={"address"}
                                        component={AddressInput}
                                        label={"Address"}
                                        isRequired={true}
                                        layout={InputLayouts.Vertical}
                                        formRenderProps={formRenderProps}
                                    />
                                    <span className={styles.CheckBoxContainer}>
                                        <Field
                                            name={"isDefault"}
                                            component={Checkbox}
                                            label={"Is Default"}
                                            rounded={"large"}
                                            size={"medium"}
                                            disabled={formRenderProps.valueGetter(
                                                "isDefaultRequired"
                                            )}
                                        />
                                        {formRenderProps.valueGetter(
                                            "isDefaultRequired"
                                        ) && (
                                            <Hint style={{ textAlign: "left" }}>
                                                Hint: Must have at least one
                                                default Business Information
                                            </Hint>
                                        )}
                                    </span>
                                </span>
                            </div>
                            {/* PREVIEW HEADER SECTION */}
                            <span>
                                {previewHeadVisible && (
                                    <BusinessInformationPreview
                                        formRenderProps={formRenderProps}
                                        setPreviewHeadVisible={
                                            setPreviewHeadVisible
                                        }
                                    />
                                )}
                                <ActionButton
                                    onClick={() =>
                                        setPreviewHeadVisible(
                                            !previewHeadVisible
                                        )
                                    }
                                    icon={"k-icon k-i-preview"}
                                    style={{
                                        marginTop: "10px",
                                    }}
                                >
                                    Preview Header
                                </ActionButton>
                            </span>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onCancel}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={!initialRecord.id}
                            />
                        </FormElement>
                    </ResponsiveDialog>
                )}
            />
        )
    );
};

export default BusinessInformationModal;
