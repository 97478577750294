import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { DataGrid } from "../../../components/Grids/DataGrid";
import { LinkCell } from "../../../components/GridColumnComponents/LinkCell";
import { EnumCell } from "../../../components/GridColumnComponents/EnumCell";
import { InventoryType } from "../../../resources/Enums/InventoryType";
import { BaseCell } from "../../../components/GridColumnComponents/BaseCell";
import { InventoryModalHelper } from "./Helpers/InventoryModalHelper.js";
import { ActionCell } from "../../../components/GridColumnComponents/ActionCell.jsx";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum.js";
import { ContentHeader } from "../../../components/ContentHeader.jsx";
import { BreadcrumbContext } from "../../../providers/BreadCrumbProvider.jsx";
import { ActionButton } from "../../../components/Buttons/ActionButton.jsx";
import { useModal } from "../../../hooks/useModal.js";
import { InventoryDeletionModal } from "./Components/InventoryDeletionModal.jsx";

const InventoryItemDetailPage = (props) => {
    const itemId = props?.match?.params?.id;
    const deleteModal = useModal();
    const { fetchCrumb, handleRouteChange } = useContext(BreadcrumbContext);

    return (
        <div>
            <InventoryDeletionModal {...deleteModal} />
            <ContentHeader
                title={`Item Inventory: ${fetchCrumb(itemId).text}`}
                style={{
                    textAlign: "left",
                }}
            />
            <span className={"JustifyLeftAlignLeft FlexColumn LargeGap"}>
                <ActionButton
                    text={"Return To Dashboard"}
                    icon={"k-icon k-i-undo"}
                    requiredPermissions={[PermissionsEnum.ViewInventory]}
                    onClick={() =>
                        handleRouteChange({
                            uri: "/Inventory/Dashboard",
                            breadcrumbInfo: [],
                        })
                    }
                />
                <DataGrid
                    style={{
                        maxWidth: "99.99%",
                    }}
                    useInlineEdit={false}
                    uri={"/Inventory/Inventory/GetAll?"}
                    resizable={true}
                    initialSort={[
                        {
                            field: "type",
                            dir: "asc",
                        },
                    ]}
                    initialFilter={{
                        logic: "and",
                        filters: [
                            {
                                field: "item.id",
                                operator: "eq",
                                value: itemId,
                            },
                        ],
                    }}
                >
                    <GridColumn
                        className={"k-table-td"}
                        field={"storageLocation.name"}
                        title={"Location:"}
                        width={250}
                        minResizableWidth={100}
                        locked={true}
                    />
                    <GridColumn
                        className={"k-table-td"}
                        field={"name"}
                        title={"Item:"}
                        width={300}
                        minResizableWidth={100}
                        locked={true}
                        cell={(props) => (
                            <LinkCell
                                uri={`/Inventory/Item/${props.dataItem.item.id}`}
                                {...props}
                                dataItem={props.dataItem.item}
                            />
                        )}
                    />
                    <GridColumn
                        className={"k-table-td"}
                        field={"type"}
                        title={"Status:"}
                        width={150}
                        minResizableWidth={50}
                        cell={(props) => (
                            <EnumCell enumerable={InventoryType} {...props} />
                        )}
                    />
                    <GridColumn
                        className={"k-table-td"}
                        field={"name"}
                        title={"Vendor:"}
                        width={250}
                        minResizableWidth={100}
                        cell={(props) => (
                            <LinkCell
                                uri={`/Inventory/Vendor/${props.dataItem.vendor.id}`}
                                {...props}
                                dataItem={props.dataItem.vendor}
                            />
                        )}
                    />
                    <GridColumn
                        field="cost.amount"
                        title="Cost:"
                        width={125}
                        minResizableWidth={50}
                        format={"{0:c}"}
                    />
                    <GridColumn
                        field="measurements"
                        title="Measurements:"
                        width={250}
                        minResizableWidth={50}
                        cell={(props) => (
                            <BaseCell {...props}>
                                {InventoryModalHelper.displayMeasurement(
                                    props.dataItem
                                )}
                            </BaseCell>
                        )}
                    />
                    <GridColumn
                        field="identifier"
                        title="Identifier:"
                        width={100}
                        minResizableWidth={50}
                    />
                    <GridColumn
                        field="expenseAccount.name"
                        title="Expense Acc:"
                        width={250}
                        minResizableWidth={50}
                    />
                    <GridColumn
                        field="incomeAccount.name"
                        title="Income Acc:"
                        width={250}
                        minResizableWidth={50}
                    />
                    <GridColumn
                        field="modifiedBy.name"
                        title="Last Modified By:"
                        width={250}
                        minResizableWidth={50}
                    />
                    <GridColumn
                        width={150}
                        resizable={false}
                        cell={(props) => (
                            <ActionCell
                                additionalActions={
                                    props.dataItem.type !==
                                        InventoryType.Enum.Allocated && [
                                        {
                                            text: "Remove",
                                            onClick: () =>
                                                deleteModal.open(undefined, {
                                                    inventoryIds: [
                                                        props.dataItem.id,
                                                    ],
                                                    quantity: 1,
                                                    hideQuantity: true,
                                                }),
                                            requiredPermissions: [
                                                PermissionsEnum.DeleteInventory,
                                            ],
                                        },
                                    ]
                                }
                            />
                        )}
                    />
                </DataGrid>
            </span>
        </div>
    );
};

export default InventoryItemDetailPage;
