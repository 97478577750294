import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { CommunicationTypeModal } from "./Components/CommunicationTypeModal";
import { useModal } from "../../../../../hooks/useModal";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import CommunicationTypeService from "../../../../../services/Deprecated/customer/CommunicationTypeService";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const CommunicationTypeDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const modal = useModal();

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <CommunicationTypeModal {...modal} />
            <ActionButton
                onClick={() => modal.open()}
                text={"Add CommunicationType"}
                icon={"k-icon k-i-plus"}
                requiredPermissions={[PermissionsEnum.CreateCommunicationType]}
            />
            <DataGrid
                uri={"/Customer/CommunicationType/GetAll?"}
                style={{ maxWidth: "700px" }}
            >
                <GridColumn
                    field="name"
                    title="Name:"
                    filter={"text"}
                    locked={true}
                    width={"275px"}
                />
                <GridColumn
                    field="followUpTimeInDays"
                    title="Follow Up Time:"
                    filter={"text"}
                    locked={true}
                    width={"250px"}
                />
                <GridColumn
                    filterable={false}
                    sortable={false}
                    width={150}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() =>
                                CommunicationTypeService.delete(
                                    props.dataItem.id
                                ).then(() => reloadDataContext.triggerReload())
                            }
                            onEdit={() => modal.open(props.dataItem.id)}
                            permissions={{
                                edit: [PermissionsEnum.UpdateCommunicationType],
                                delete: [
                                    PermissionsEnum.DeleteCommunicationType,
                                ],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
export default CommunicationTypeDashboardPage;
