import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { ToastContext } from "../providers/ToastProvider.jsx";
import { ActionButton } from "./Buttons/ActionButton.jsx";

/**
 * @function ToastComponent
 * @description Component that displays toast notifications
 * @return {React.JSX.Element}
 * @constructor
 */
export const ToastComponent = () => {
    const { toast, clearToast } = useContext(ToastContext);
    const maxSeconds = 5;
    const intervalRef = useRef();
    const [currentSecond, setCurrentSecond] = useState(maxSeconds);

    useEffect(() => {
        if (!toast) return;

        const timer = setTimeout(() => {
            if (clearToast) {
                setCurrentSecond(maxSeconds);
                clearToast();
            }
        }, maxSeconds * 1000);

        return () => clearTimeout(timer);
    }, [toast]);

    useEffect(() => {
        if (!toast) return;

        if (currentSecond === 0) {
            clearInterval(intervalRef.current);
            setCurrentSecond(maxSeconds);
            return;
        }

        const interval = setInterval(() => {
            setCurrentSecond((prev) => prev - 1);
        }, 1000);

        intervalRef.current = interval;

        return () => clearInterval(interval);
    }, [currentSecond, toast]);

    return (
        <NotificationGroup
            style={{
                top: -2,
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 99999,
            }}
        >
            <Fade>
                {toast && (
                    <Notification
                        style={{
                            width: 500,
                            height: 100,
                            borderRadius: "0 0 20px 20px",
                            position: "relative",
                        }}
                        type={{
                            style: toast.type,
                        }}
                        onClose={() => clearToast()}
                    >
                        <span
                            className={"text-overflow"}
                            style={{
                                height: 80,
                                display: "flex",
                                justifyContent: "center",
                                gap: 10,
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: -1,
                                    left: 0,
                                    height: 5,
                                    width: `${
                                        ((currentSecond - 1) /
                                            (maxSeconds - 1)) *
                                        100
                                    }%`,
                                    backgroundColor: "white",
                                    transition: "width 1.01s linear",
                                }}
                            />
                            <p
                                style={{
                                    margin: 0,
                                    fontSize: 16,
                                    fontWeight: 600,
                                }}
                            >
                                {toast.message}
                            </p>
                            <ActionButton
                                onClick={() => {
                                    clearInterval(intervalRef.current);
                                    setCurrentSecond(maxSeconds);
                                    clearToast();
                                }}
                                themeColor={toast.type}
                                style={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    border: "none",
                                }}
                                icon={"k-icon k-i-x"}
                            />
                        </span>
                    </Notification>
                )}
            </Fade>
        </NotificationGroup>
    );
};
