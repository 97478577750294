import React, { useState } from "react";
import { ConfirmationDialog } from "../Dialogs/ConfirmationDialog";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import PermissionsHelper from "../../resources/PermissionsHelper.js";
import { useUserPermissions } from "../../hooks/useUserPermissions.js";

/**
 * @function ActionButton
 * @param {Function} props.onClick
 * @param {String} props.text
 * @param {Boolean} props.disabled
 * @param {Object} props.confirmationState
 * @param {String} props.toolTipMessage
 * @param {String} props.disabledToolTipMessage
 * @param {String} props.toolTipPosition
 * @param {Number} props.timeOutInterval
 * @param {Array} props.requiredPermissions
 * @param {Array} props.optionalPermissions
 * @param {String} props.themeColor
 * @param {Element} props.children
 * @param {Object} props.others
 * @return {Element}
 * @constructor
 */
export const ActionButton = (props) => {
    const {
        onClick = undefined,
        text = undefined,
        disabled = false,
        confirmationState = {
            needsConfirmation: false,
        },
        toolTipMessage = undefined,
        disabledToolTipMessage = undefined,
        toolTipPosition = "top",
        timeOutInterval = 500,
        requiredPermissions = [],
        optionalPermissions = undefined,
        themeColor = "primary",
        children = undefined,
        ...others
    } = props;
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [buttonTimedOut, setButtonTimedOut] = useState(false);

    const handleClick = () => {
        if (confirmationState.needsConfirmation) {
            setConfirmVisible(true);
            return;
        }

        setButtonTimedOut(true);

        // If the onClick function is provided, call it
        if (onClick) {
            onClick();
        }

        setTimeout(() => {
            setButtonTimedOut(false);
        }, timeOutInterval);
    };

    const onConfirmation = () => {
        if (onClick) {
            onClick();
        }
        setConfirmVisible(false);
    };

    const RenderButton = (onClick) => {
        const hasPermission = PermissionsHelper.hasPermission(
            useUserPermissions(),
            requiredPermissions,
            optionalPermissions
        );
        const isTimedOut = buttonTimedOut && timeOutInterval !== 0;

        if (hasPermission) {
            return (
                <Tooltip anchorElement="target" position={toolTipPosition}>
                    <span
                        title={
                            disabled ? disabledToolTipMessage : toolTipMessage
                        }
                    >
                        <Button
                            disabled={disabled}
                            onClick={!isTimedOut && onClick}
                            themeColor={themeColor}
                            {...others}
                        >
                            {text ?? children}
                        </Button>
                    </span>
                </Tooltip>
            );
        }
    };

    return (
        <>
            {confirmationState.needsConfirmation && (
                <ConfirmationDialog
                    visible={confirmVisible}
                    actionOnConfirm={onConfirmation}
                    actionOnCancel={() => setConfirmVisible(false)}
                    confirmationButtonText={
                        confirmationState.confirmationButtonText ?? "Yes"
                    }
                    confirmationButtonThemeColor={
                        confirmationState.confirmationButtonThemeColor ??
                        "error"
                    }
                    cancelButtonText={
                        confirmationState.cancelButtonText ?? "No"
                    }
                    cancelButtonThemeColor={
                        confirmationState.cancelButtonThemeColor ?? "primary"
                    }
                    confirmationText={
                        confirmationState.confirmationText ??
                        "Are you sure you wish to proceed?"
                    }
                />
            )}
            {RenderButton(handleClick)}
        </>
    );
};
