import * as React from "react";
import { useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { findEmployee } from "../../../../services/Deprecated/humanResources/employeeServices";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormMaskedTextBox } from "../../../../components/Deprecated/FormComponents";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";

const PreCreateEmployeeSearchModal = (props) => {
    const { close, visible, setFormState, toggleCreateVisible } = props;
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [inError, setInError] = useState(false);

    const onSubmit = (props) => {
        if (!props.socialSecurityNumber) {
            throw new ReferenceError("socialSecurityNumber is not defined.");
        }
        setLoaderVisible(true);

        findEmployee(props.socialSecurityNumber).then((serviceResponse) => {
            // This means an Employee exists, thus in error
            if (serviceResponse.success && serviceResponse.data) {
                setInError(true);
                setLoaderVisible(false);
                return;
            }

            // Set the Employee create forms SSN to the searched SSN for ease of use, and open the form
            setFormState({ socialSecurityNumber: props.socialSecurityNumber });
            toggleCreateVisible();
            setLoaderVisible(false);
            close();
        });
    };

    const ssnValidator = (value) => {
        const parsedVal = value?.replace(/[_-]/g, "");

        if (!parsedVal) {
            return "SSN is a required field.";
        }

        if (parsedVal.length < 9) {
            return "SSN must be 9 characters";
        }

        if (inError) {
            return "Employee with provided SSN already exists.";
        }

        return "";
    };

    return (
        visible && (
            <ResponsiveDialog
                title={"Search for existing Employee"}
                onClose={() => close()}
                size={"small"}
            >
                <Form
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <Field
                                name={"socialSecurityNumber"}
                                component={FormMaskedTextBox}
                                label={"Social Security Number:"}
                                mask="000-00-0000"
                                validator={ssnValidator}
                                onChange={() => setInError(false)}
                            />
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={() => close()}
                                allowSubmit={formRenderProps.allowSubmit}
                                text={"Search"}
                                theme={"primary"}
                                icon={"k-i-icon k-i-search"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};

export default PreCreateEmployeeSearchModal;
