import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import { FormInput } from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import { AddressInput } from "../../../../components/FormInputs/AddressInput";
import { LeadModalHelper } from "../Helpers/LeadModalHelper";
import { InputLayouts } from "../../../../components/FormInputs/InputLayouts";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { CustomerTypeEnum } from "../../../../resources/Enums/CustomerStatusEnum";
import { ContactInput } from "../../../../components/FormInputs/ContactInput";
import LeadService from "../../../../services/LeadService";
import LinkTag from "../../../../components/LinkTag";
import { ContentHeader } from "../../../../components/ContentHeader.jsx";
import { ToastContext } from "../../../../providers/ToastProvider.jsx";

/**
 * @function LeadModal
 * @description Handles the modal for creating and editing leads
 * @param props.id {String} - The id of the lead
 * @param props.close {Function} - Function to close the modal
 * @param props.visible {Boolean} - Whether the modal is visible
 * @param props.record {Object} - The record to pre-populate the form with
 * @return {Element}
 * @constructor
 */
export const LeadModal = (props) => {
    const { id, close, visible, record, onSuccessCallback } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const service = useRef(new LeadService("Customer/Lead"));
    const [searchedLeads, setSearchedLeads] = useState([]);
    const [dataItem, setDataItem] = useState({});
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        if (!visible) return;

        if (id) {
            service.current.get(id).then((res) => {
                const data = LeadModalHelper.formatDataForModal(res.data);
                setInitialFormData(data);
            });
        } else {
            setInitialFormData(record);
        }
    }, [visible, id]);

    const onClose = (action) => {
        setInitialFormData(undefined);
        setSearchedLeads([]);
        setLoaderVisible(false);
        close(action);
    };

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        const data = LeadModalHelper.formatDataForNetwork(dataItem);

        // If creating, we want to search for existing leads
        if (!id) {
            const query = LeadModalHelper.buildSearchQuery(dataItem);
            service.current.search(query, initialFormData.type).then((res) => {
                if (res.data.length === 0) {
                    onContinue(data);
                    return;
                }

                setDataItem(data);
                setSearchedLeads(res.data);
            });
            return;
        }

        service.current.update(data).then(() => {
            triggerReload();
            onClose("EDIT");

            if (onSuccessCallback) {
                showToast("Lead updated successfully", "success");
                onSuccessCallback(dataItem);
            }
        });
    };

    const onContinue = (data) => {
        service.current.create(data ?? dataItem).then(() => {
            triggerReload();
            onClose("CREATE");
        });
    };

    useEffect(() => {
        if (!record?.isConvertingToCustomer) return;
        if (!visible && !initialFormData) return;

        const submitBtn = document.getElementById("submit-button");

        // wait 1 second
        setTimeout(() => {
            if (submitBtn) {
                submitBtn.click();
            }
        }, 1000);
    }, [visible, initialFormData]);

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={
                    id
                        ? `Edit ${CustomerTypeEnum.ParseToString(
                              initialFormData.type
                          )} Lead`
                        : `Add ${CustomerTypeEnum.ParseToString(
                              initialFormData.type
                          )} Lead`
                }
                onClose={onClose}
                size={"large"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            {record?.isConvertingToCustomer && (
                                <ContentHeader
                                    style={{
                                        color: "red",
                                    }}
                                    title={
                                        "Lead needs additional information to proceed."
                                    }
                                />
                            )}
                            <GridLayout
                                gap={{
                                    rows: 6,
                                    cols: 10,
                                }}
                                rows={[
                                    {
                                        height: "auto",
                                    },
                                    {
                                        height: "auto",
                                    },
                                    {
                                        height: "auto",
                                    },
                                ]}
                                cols={[
                                    {
                                        width: "auto",
                                    },
                                    {
                                        width: "auto",
                                    },
                                ]}
                            >
                                <GridLayoutItem row={1} col={1}>
                                    <Field
                                        name={"name1"}
                                        component={FormInput}
                                        label={
                                            initialFormData.type ===
                                            CustomerTypeEnum.Enum.Individual
                                                ? "First Name"
                                                : "Business Name"
                                        }
                                        validator={requiredValidator}
                                        onChange={(e) => {
                                            if (
                                                initialFormData.type ===
                                                CustomerTypeEnum.Enum.Business
                                            )
                                                return;

                                            formRenderProps.onChange(
                                                "primaryContact.name1",
                                                { value: e.value }
                                            );
                                        }}
                                    />
                                    {initialFormData.type ===
                                        CustomerTypeEnum.Enum.Individual && (
                                        <Field
                                            name={"name2"}
                                            component={FormInput}
                                            label={"Last Name"}
                                            validator={requiredValidator}
                                            onChange={(e) =>
                                                formRenderProps.onChange(
                                                    "primaryContact.name2",
                                                    { value: e.value }
                                                )
                                            }
                                        />
                                    )}
                                </GridLayoutItem>
                                <GridLayoutItem row={1} col={2}>
                                    <Field
                                        name={"address"}
                                        component={AddressInput}
                                        isRequired={
                                            record?.isConvertingToCustomer
                                        }
                                        layout={InputLayouts.Vertical}
                                        formRenderProps={formRenderProps}
                                    />
                                </GridLayoutItem>
                                <GridLayoutItem row={2} col={1} colSpan={2}>
                                    <h4
                                        className={"Separator AlignTextLeft"}
                                        style={{ marginBottom: 0 }}
                                    >
                                        Primary Contact
                                    </h4>
                                    <ContactInput
                                        field={"primaryContact"}
                                        isRequired={true}
                                        mustHavePhoneAndEmail={
                                            record?.isConvertingToCustomer
                                        }
                                        formRenderProps={formRenderProps}
                                        className={
                                            "JustifyLeftAlignLeft SmallGap"
                                        }
                                    />
                                </GridLayoutItem>
                                {id && (
                                    <GridLayoutItem row={3} col={1} colSpan={2}>
                                        <h4
                                            className={
                                                "Separator AlignTextLeft"
                                            }
                                            style={{ marginBottom: 0 }}
                                        >
                                            Secondary Contact
                                        </h4>
                                        <ContactInput
                                            field={"secondaryContact"}
                                            isRequired={false}
                                            formRenderProps={formRenderProps}
                                            className={
                                                "JustifyLeftAlignLeft SmallGap"
                                            }
                                        />
                                    </GridLayoutItem>
                                )}
                            </GridLayout>
                            {searchedLeads.length > 0 ? (
                                <>
                                    <hr className={"Separator"} />
                                    <span
                                        className={
                                            "JustifyCenterAlignLeft FlexColumn"
                                        }
                                    >
                                        <p>
                                            You can select a Lead below or
                                            continue to{" "}
                                            <LinkTag
                                                onClick={onContinue}
                                                text={"create the new Lead"}
                                            />
                                        </p>
                                        {searchedLeads.map((lead, key) => (
                                            <li key={key}>
                                                <LinkTag
                                                    breadCrumbInfo={{
                                                        uri: `/Lead/${lead.id}/`,
                                                        breadcrumbInfo: [
                                                            {
                                                                id: lead.id,
                                                                text: lead.combinedName,
                                                            },
                                                        ],
                                                    }}
                                                    text={`${lead.combinedName} ${lead.primaryContact?.address?.fullAddress}`}
                                                />
                                            </li>
                                        ))}
                                    </span>
                                </>
                            ) : (
                                <FormButtons
                                    cancelText={id ? "Cancel" : "Back"}
                                    text={id ? "Update" : "Search"}
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={id ? onClose : close}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    isCreate={!id}
                                />
                            )}
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
