import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { useModal } from "../../../../hooks/useModal";
import { markJobDead } from "../../../../services/Deprecated/customer/jobServices";
import JobModal from "./JobModal";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import { CustomDateCell } from "../../../../components/Deprecated/CustomGridCells";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";

const ActiveJobsList = () => {
    const customerContext = useContext(CustomerContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const createModal = useModal();
    const editModal = useModal();

    const jobFilter = {
        filters: [
            {
                field: "isMarkedDead",
                operator: "eq",
                value: false,
            },
            {
                field: "isArchived",
                operator: "eq",
                value: false,
            },
            {
                field: "customerId",
                operator: "eq",
                value: customerContext.customer.id,
            },
        ],
        logic: "and",
    };

    const markDead = (id) => {
        markJobDead(id).then(() => {
            triggerReload();
        });
    };

    const onCreate = () => {
        createModal.open(); // Open create modal
    };

    const onEdit = async (jobId) => {
        editModal.open(jobId); // Open edit modal
    };

    return customerContext.customer.id ? (
        <div>
            {/* Create and Edit Job Modals */}
            <JobModal {...editModal} close={editModal.close} />
            <JobModal {...createModal} />
            {/* Header Section with Create Button */}
            <div
                className="JustifyBetweenAlignCenter MediumGap"
                style={{ marginBottom: "10px" }}
            >
                <ActionButton
                    icon={"k-icon k-i-plus"}
                    requiredPermissions={[PermissionsEnum.CreateJob]}
                    onClick={onCreate} // Trigger modal for creating a job
                >
                    Create New Job
                </ActionButton>
            </div>

            {/* Active Jobs Grid */}
            <DataGrid
                key={customerContext.customer.id}
                uri={`/Customer/Job/GetAll?`}
                searchFields={["name", "description"]}
                initialFilter={jobFilter}
            >
                <GridColumn
                    field="name"
                    title="Name:"
                    locked={true}
                    width={300}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/customer/${customerContext.customer.id}/job/${props.dataItem.id}`}
                        />
                    )}
                />
                <GridColumn
                    field="description"
                    title="Description:"
                    width={300}
                />
                <GridColumn
                    field={"address.fullAddress"}
                    title="Address:"
                    width={300}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                props.dataItem.address.fullAddress
                            )}`}
                            text={props.dataItem.address.fullAddress}
                            target="_blank"
                        />
                    )}
                />
                <GridColumn
                    field="created"
                    title="Date Created:"
                    width={150}
                    cell={(props) => (
                        <CustomDateCell
                            {...props}
                            data={props.dataItem.created}
                        />
                    )}
                />
                <GridColumn
                    field={"totalDue"}
                    title={"Balance Due:"}
                    format="{0:c}"
                    width={150}
                />
                <GridColumn
                    width={200}
                    cell={(props) => (
                        <ActionCell
                            record={props.dataItem}
                            dataId={props.dataItem.id}
                            viewState={{
                                uri: `/customer/${customerContext.customer.id}/job/${props.dataItem.id}`,
                                breadcrumbInfo: [
                                    {
                                        id: props.dataItem.id,
                                        text: customerContext.customer
                                            .combinedName,
                                    },
                                    {
                                        id: props.dataItem.id,
                                        text: props.dataItem.name,
                                    },
                                ],
                            }}
                            onEdit={() => onEdit(props.dataItem.id)}
                            onDelete={() => markDead(props.dataItem.id)}
                            deleteBtnText={"Mark Dead"}
                            permissions={{
                                view: [PermissionsEnum.ViewJob],
                                edit: [PermissionsEnum.UpdateJob],
                                delete: [PermissionsEnum.DeleteJob],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default ActiveJobsList;
