import { BaseCell } from "./BaseCell";
import React, { useContext } from "react";
import { ActionButton } from "../Buttons/ActionButton";
import { BreadcrumbContext } from "../../providers/BreadCrumbProvider";

/**
 * @function ActionCell
 * @description
 * A cell component that renders action buttons.
 * Handles permissions, and confirmation dialogs.
 * @param {Object} props
 * @param {CallableFunction} props.onDelete - Function to call when delete button is clicked
 * @param {CallableFunction} props.onEdit - Function to call when edit button is clicked
 * @param {Object} props.viewState - {
 *     uri: string,
 *     breadcrumbInfo: Array<{Object}>
 * }
 * @param {Object} props.permissions - {
 *     view: [],
 *     edit: [],
 *     delete: [],
 * } - Permissions for view, edit, and delete
 * @param {[string]} props.deleteBtnText - Text for delete button
 * @param {[string]} props.viewBtnText - Text for view button
 * @param {[string]} props.editBtnText - Text for edit button
 * @param {Object[]} props.additionalActions - Additional action buttons
 * @return {React.JSX.Element}
 * @constructor
 */
export const ActionCell = (props) => {
    const {
        onDelete,
        onEdit,
        viewState,
        permissions,
        additionalActions,
        tdProps,
        deleteBtnText,
        viewBtnText,
        editBtnText,
    } = props;
    const { handleRouteChange } = useContext(BreadcrumbContext);

    return (
        <BaseCell
            {...tdProps}
            style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
            }}
        >
            {viewState && (
                <ActionButton
                    onClick={() => handleRouteChange(viewState)}
                    requiredPermissions={permissions.view}
                    text={viewBtnText ?? "View"}
                />
            )}
            {onEdit && (
                <ActionButton
                    onClick={onEdit}
                    requiredPermissions={permissions.edit}
                    text={editBtnText ?? "Edit"}
                />
            )}
            {onDelete && (
                <ActionButton
                    onClick={onDelete}
                    requiredPermissions={permissions.delete}
                    confirmationState={{
                        needsConfirmation: true,
                    }}
                    text={deleteBtnText ?? "Delete"}
                />
            )}
            {additionalActions &&
                additionalActions.map((action, index) => (
                    <ActionButton {...action} key={index} />
                ))}
        </BaseCell>
    );
};
