import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { YesNoCell } from "../../../../../components/GridColumnComponents/YesNoCell";
import { deleteTask } from "../../../../../services/Deprecated/calendar/taskServices";
import { TaskModal } from "./Components/TaskModal";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const EventTypeDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const taskModal = useModal();

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <TaskModal {...taskModal} />
            <span className={"DisplayFlex SmallGap"}>
                <ActionButton
                    onClick={() => taskModal.open()}
                    text={"Create Task"}
                    icon={"k-icon k-i-plus"}
                    requiredPermissions={[PermissionsEnum.CreateTask]}
                />
            </span>
            <DataGrid uri={"/Customer/Task/GetAll?"} style={{ maxWidth: 875 }}>
                <GridColumn field="name" title="Name:" />
                <GridColumn
                    field="isAutoAssigned"
                    title="Is Auto Assigned:"
                    cells={{
                        data: YesNoCell,
                    }}
                />
                <GridColumn
                    width={250}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() =>
                                deleteTask(props.dataItem.id).then(() =>
                                    reloadDataContext.triggerReload()
                                )
                            }
                            onEdit={() => taskModal.open(props.dataItem.id)}
                            permissions={{
                                edit: [PermissionsEnum.UpdateEventType],
                                delete: [PermissionsEnum.DeleteEventType],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
export default EventTypeDashboardPage;
