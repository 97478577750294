import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { BillingTermModal } from "./Components/BillingTermModal";
import { deleteBillingTerm } from "../../../../../services/Deprecated/inventory/billingTermService";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const BillingTermDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const modal = useModal();

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <BillingTermModal {...modal} />
            <ActionButton
                onClick={() => modal.open()}
                text={"Add Billing Term"}
                icon={"k-icon k-i-plus"}
                requiredPermissions={[PermissionsEnum.CreateBillingTerm]}
            />
            <DataGrid
                uri={"/Inventory/BillingTerm/GetAll?"}
                style={{ maxWidth: "420px" }}
            >
                <GridColumn field="name" title="Name:" width={"250px"} />
                <GridColumn
                    width={150}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() =>
                                deleteBillingTerm(props.dataItem.id).then(() =>
                                    reloadDataContext.triggerReload()
                                )
                            }
                            onEdit={() => modal.open(props.dataItem.id)}
                            permissions={{
                                edit: [PermissionsEnum.UpdateBillingTerm],
                                delete: [PermissionsEnum.DeleteBillingTerm],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
export default BillingTermDashboardPage;
