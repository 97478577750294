import React, { useContext } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { createEventNote } from "../../../../services/Deprecated/customer/eventServices";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormTextArea } from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";

const EventNoteCreate = ({ triggerReload }) => {
    const { bool: createVisible, toggle: toggleCreateVisible } = useToggle();
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const eventContext = useContext(EventContext);

    const submitEventNote = (dataItem) => {
        toggleLoaderVisible();

        dataItem.calendarEventId = eventContext.event.id;

        createEventNote(dataItem)
            .then(() => {
                triggerReload(dataItem);
                toggleCreateVisible();
            })
            .catch((error) => {
                console.error("Error Message: ", error.message);
                if (error.response) {
                    console.error("Response Data: ", error.response.data);
                    console.error("Response Status: ", error.response.status);
                    console.error("Response Headers: ", error.response.headers);
                }
            })
            .finally(() => {
                toggleLoaderVisible();
            });
    };

    return (
        <div>
            <ActionButton
                requiredPermissions={[PermissionsEnum.CreateNote]}
                icon={"k-icon k-i-plus"}
                onClick={toggleCreateVisible}
                style={{
                    border: "none",
                }}
            >
                Create
            </ActionButton>
            {createVisible && (
                <Form
                    onSubmit={submitEventNote}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Create Event Note"}
                            onClose={toggleCreateVisible}
                            size={"small"}
                        >
                            <FormElement>
                                <fieldset
                                    className={"k-form-fieldset"}
                                    style={{ textAlign: "left" }}
                                >
                                    <label
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Notes:
                                    </label>
                                    <Field
                                        name={"name"}
                                        component={FormTextArea}
                                        autoSize={true}
                                        rows={4}
                                        validator={requiredValidator}
                                    />
                                </fieldset>
                                <FormButtons
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={toggleCreateVisible}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    isCreate={true}
                                />
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default EventNoteCreate;
