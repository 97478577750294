import React, { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { PaymentContext } from "../../../../providers/Deprecated/Customer/PaymentProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import { getAllJobs } from "../../../../services/Deprecated/customer/jobServices";
import { transferJobPayment } from "../../../../services/Deprecated/customer/paymentServices";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormDropDown } from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";

const TransferModal = () => {
    const paymentContext = useContext(PaymentContext);
    const reloadDataContext = useContext(ReloadDataContext);
    const jobContext = useContext(JobContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [jobs, setJobs] = useState([]);

    //? Set the initial values of the form to the record passed in.
    useEffect(() => {
        getAllJobs(`customerId~eq~'${jobContext.job?.customer.id}'`).then((r) =>
            setJobs(r.data.filter((i) => i.id !== jobContext.job?.id))
        );
    }, []);

    /**
     * @function onSubmit
     * @description Handles submit of the form. For both Edit and Create.
     * @param dataItem
     */
    const onSubmit = async (dataItem) => {
        setLoaderVisible(true);

        const payload = {
            paymentId: paymentContext.transferringPaymentId,
            jobId: dataItem.job.id,
        };

        transferJobPayment(payload).then((_r) => finalizeSubmission());
    };

    /**
     * @function finalizeSubmission
     * @description Finalizes the submission of the form. Reloads the data and closes the modal.
     */
    const finalizeSubmission = () => {
        reloadDataContext.triggerReload();
        setLoaderVisible(false);
        paymentContext.setTransferringPaymentId(undefined);
    };

    return (
        <div>
            {paymentContext.transferringPaymentId && (
                <Form
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Transfer Payment"}
                            onClose={() =>
                                paymentContext.setTransferringPaymentId(
                                    undefined
                                )
                            }
                            size={"small"}
                        >
                            <FormElement>
                                <Field
                                    name={"job"}
                                    component={FormDropDown}
                                    data={jobs}
                                    validator={requiredValidator}
                                    dataItemKey="id"
                                    textField="name"
                                    label={"Job:"}
                                />
                                <FormButtons
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={paymentContext.setTransferringPaymentId(
                                        undefined
                                    )}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    isCreate={false}
                                    text={"Transfer"}
                                />
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default TransferModal;
