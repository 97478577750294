import React, { useContext, useEffect, useState } from "react";
import { Field, FormElement } from "@progress/kendo-react-form";
import { FormInput } from "../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators.js";
import { JobDocumentModalContext } from "../Providers/JobDocumentModalProvider.jsx";
import { useModal } from "../../../../../../hooks/useModal.js";
import styles from "../Styles/JobDocumentModal.module.scss";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper.jsx";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";
import StringHelper from "../../../../../../resources/StringHelper.js";

/**
 * @function JobDocumentTitle
 * @description Display the title of the job document
 * @return {Element}
 * @constructor
 */
export const JobDocumentTitle = () => {
    const { formData } = useContext(JobDocumentModalContext);
    const titleModal = useModal();

    return (
        formData && (
            <span
                style={{
                    fontSize: 16,
                    color: "#666666",
                }}
            >
                <TitleModal {...titleModal} />
                <span>
                    Title:{" "}
                    <DisplayTitle formData={formData} modal={titleModal} />
                </span>
            </span>
        )
    );
};

/**
 * @function DisplayTitle
 * @param props.formData {object} - formData object
 * @param props.modal {object} - modal object
 * @return {Element}
 * @constructor
 */
const DisplayTitle = (props) => {
    const { formData, modal } = props;
    const { canEdit } = useContext(JobDocumentModalContext);

    return canEdit ? (
        <span
            className={`text-overflow ${styles.LinkDisplay}`}
            onClick={() => modal.open()}
            style={!formData.name ? { color: "red" } : undefined}
        >
            <i className={"k-icon k-i-pencil"} style={{ marginBottom: 4 }} />{" "}
            {formData.name ?? "Not provided"}
        </span>
    ) : (
        <strong className={"text-overflow"}>{formData.name}</strong>
    );
};

const TitleModal = (props) => {
    const { close, visible } = props;
    const [data, setData] = useState(undefined);
    const { reloadDocumentInformation, setFormData, formData } = useContext(
        JobDocumentModalContext
    );

    useEffect(() => {
        setData({
            name: formData.name,
        });
    }, [visible]);

    const onClose = () => {
        setData(undefined);
        close();
    };

    const onSubmit = () => {
        setFormData({
            ...formData,
            name: data.name,
        });
        reloadDocumentInformation();
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={"Edit Title"}
                onClose={onClose}
                size={"small"}
            >
                <FormElement>
                    <Field
                        name={"name"}
                        component={FormInput}
                        label={"Title"}
                        validator={requiredValidator}
                        onChange={(e) =>
                            setData({
                                name: e.value,
                                isValid: !StringHelper.isNullOrEmpty(e.value),
                            })
                        }
                    />
                </FormElement>
                <FormButtons
                    loaderVisible={false}
                    actionOnCancel={onClose}
                    allowSubmit={data.isValid}
                    isCreate={false}
                    text={"Apply Changes"}
                    onSubmit={onSubmit}
                />
            </ResponsiveDialog>
        )
    );
};
