import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper";
import { getAllTaskCategories } from "../../../../../../services/Deprecated/customer/eventServices";
import { FormDropDown } from "../../../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators";
import { addTaskCategoryToEventType } from "../../../../../../services/Deprecated/calendar/eventTaskCategory";

export const AddTaskCategoryModal = (props) => {
    const { id, close, visible, record } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [data, setData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        if (!visible) return;

        getAllTaskCategories().then((res) => {
            const data = res?.data ?? [];
            const filteredData = data.filter((t) => {
                return !record.eventType.categories.some(
                    (tc) => tc.id === t.id
                );
            });
            setData(filteredData);
        });
    }, [visible, id]);

    const onClose = () => {
        setLoaderVisible(false);
        setData(undefined);
        close();
    };

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        const data = {
            ...dataItem,
            eventTypeId: record.eventType.id,
            categoryId: dataItem.category.id,
        };

        delete data.category;
        delete data.eventType;

        addTaskCategoryToEventType(data.eventTypeId, data.categoryId).then(
            () => {
                triggerReload();
                setLoaderVisible(false);
                onClose();
            }
        );
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={"Add Category"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div style={{ width: "100%" }}>
                                <Field
                                    name={"category"}
                                    component={FormDropDown}
                                    data={data}
                                    textField={"name"}
                                    valueField={"id"}
                                    label={"Category"}
                                    validator={requiredValidator}
                                />
                            </div>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={true}
                                text={"Add"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
