import * as React from "react";
import "../AccountingModule.module.scss";
import { TabStripTab } from "@progress/kendo-react-layout";
import Journal from "./Components/Journal";
import { HighlightedTabStrip } from "../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";

const AccountingJournalPage = () => {
    return (
        <HighlightedTabStrip id={"AccountingJournal"}>
            <TabStripTab title="Accounting Journal">
                <Journal />
            </TabStripTab>
        </HighlightedTabStrip>
    );
};

export default AccountingJournalPage;
