import React from "react";
import FormField from "../../../../../components/Deprecated/StylingField";
import { CustomerTypeEnum } from "../../../../../resources/Enums/CustomerStatusEnum";
import { addressFormatter } from "../../../../../resources/Deprecated/helpers";

const LeadDetailsTab = (lead) => {
    return (
        lead && (
            <div className={"JustifyLeftAlignLeft LargeGap"}>
                <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
                    {lead.type === CustomerTypeEnum.Enum.Individual ? (
                        <FormField
                            label="Full Name"
                            value={lead.combinedName}
                        />
                    ) : (
                        <FormField label="Company Name" value={lead.name1} />
                    )}
                    <FormField label="Source" value={lead.source.name} />
                    {lead.referrer && (
                        <FormField
                            label="Referrer"
                            value={lead.referrer.name}
                        />
                    )}
                </span>
                <span
                    className={"JustifyLeftAlignLeft SmallGap FlexColumn"}
                    style={{
                        minWidth: 200,
                    }}
                >
                    <FormField
                        label="Address"
                        value={addressFormatter(lead.address, "short")}
                    />
                    <FormField label="City" value={lead.address.city} />
                    <FormField label="State" value={lead.address.state} />
                    <FormField label="Zip" value={lead.address.zipCode} />
                </span>
                <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
                    <FormField
                        label="Primary Contact"
                        value={lead.primaryContact.combinedName}
                    />
                    {lead.primaryContact.email && (
                        <FormField
                            label="Email"
                            value={lead.primaryContact.email}
                        />
                    )}
                    {lead.primaryContact.phone && (
                        <FormField
                            label="Phone"
                            value={`(${lead.primaryContact.phone.phoneNumberType.name}) ${lead.primaryContact.phone.phoneNumber}`}
                        />
                    )}
                </span>
                {lead.secondaryContact && (
                    <span
                        className={"JustifyLeftAlignLeft SmallGap FlexColumn"}
                    >
                        <FormField
                            label="Secondary Contact"
                            value={lead.secondaryContact.combinedName}
                        />
                        {lead.secondaryContact.email && (
                            <FormField
                                label="Email"
                                value={lead.secondaryContact.email}
                            />
                        )}
                        {lead.secondaryContact.phone && (
                            <FormField
                                label="Phone"
                                value={`(${lead.secondaryContact.phone.phoneNumberType.name}) ${lead.secondaryContact.phone.phoneNumber}`}
                            />
                        )}
                    </span>
                )}
            </div>
        )
    );
};

export default LeadDetailsTab;
