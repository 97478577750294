import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../../../../../../providers/ReloadDataProvider.jsx";
import { ItemAllocationHelper } from "../../Helpers/ItemAllocationHelper.js";
import ResponsiveDialog from "../../../../../../../../components/Deprecated/DialogWrapper.jsx";
import { FormButtons } from "../../../../../../../../components/Buttons/FormButtons.jsx";
import { SqftAllocation } from "./Components/SqftAllocation.jsx";
import { EachAllocation } from "./Components/EachAllocation.jsx";
import ItemService from "../../../../../../../../services/ItemService.js";
import InventoryService from "../../../../../../../../services/Deprecated/inventory/InventoryService.js";
import { SqftDeAllocation } from "./Components/SqftDeAllocation.jsx";

export const AllocationModal = (props) => {
    const { close, visible, record } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        if (!record) return;

        const service = new ItemService();
        service.get(record.itemId).then((res) => {
            setInitialFormData({
                selectedItem: res.data,
                retailUom: res.data.retailUom,
                ...record,
            });
        });
    }, [record]);

    const onClose = () => {
        setInitialFormData(undefined);
        close();
    };

    const onSubmit = (e) => {
        if (!e.isValid) return;
        if (!e.values.items) return;

        setLoaderVisible(true);
        const data = ItemAllocationHelper.formatDataForNetwork(
            e.values,
            record.isDeallocate
        );

        const request = record.isDeallocate
            ? InventoryService.deallocate(data)
            : InventoryService.allocate(data);

        request.then(() => {
            triggerReload();
            setLoaderVisible(false);
            onClose();
        });
    };

    const renderFormFields = (props) => {
        const { formRenderProps } = props;

        switch (initialFormData.retailUom.name) {
            case "Sqft":
                return !record.isDeallocate ? (
                    <SqftAllocation
                        record={initialFormData}
                        formRenderProps={formRenderProps}
                    />
                ) : (
                    <SqftDeAllocation record={initialFormData} />
                );
            case "Each":
                return (
                    <EachAllocation
                        record={initialFormData}
                        formRenderProps={formRenderProps}
                    />
                );
            default:
                return (
                    <EachAllocation
                        record={initialFormData}
                        formRenderProps={formRenderProps}
                    />
                );
        }
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={!record.isDeallocate ? "Allocate" : "Return To Stock"}
                onClose={onClose}
                size={"medium"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmitClick={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement
                            className={
                                "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                            }
                        >
                            {renderFormFields({ formRenderProps })}
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={true}
                                text={
                                    record.isDeallocate
                                        ? "Deallocate"
                                        : "Allocate"
                                }
                                hideSubmit={
                                    record.isDeallocate &&
                                    initialFormData.retailUom.name === "Sqft"
                                }
                                isCreate={true}
                                theme={"primary"}
                                icon={record.isDeallocate ? "minus" : "plus"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
