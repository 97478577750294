import React from "react";
import { TabStripTab } from "@progress/kendo-react-layout";
import EmployeeDetails from "./EmployeeDetails";
import EmployeeRelations from "./EmployeeRelations";
import EmergencyContacts from "./EmergencyContact";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";

const EmployeeTabs = () => {
    return (
        <HighlightedTabStrip className="tabs">
            <TabStripTab title="Employee">
                <EmployeeDetails />
            </TabStripTab>
            <TabStripTab title="Relationships">
                <EmployeeRelations />
            </TabStripTab>
            <TabStripTab title="Emergency Contact">
                <EmergencyContacts />
            </TabStripTab>
        </HighlightedTabStrip>
    );
};

export default EmployeeTabs;
