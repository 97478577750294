import React from "react";
import { Card, TabStripTab } from "@progress/kendo-react-layout";
import { ContentHeader } from "../../../../components/ContentHeader";
import BillingTermDashboardPage from "./BillingTerms/BillingTermDashboardPage";
import ItemTypeDashboardPage from "./ItemTypes/ItemTypeDashboardPage";
import StorageLocationDashboardPage from "./StorageLocations/StorageLocationDashboardPage";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";
import { useUserPermissions } from "../../../../hooks/useUserPermissions.js";
import PermissionsHelper from "../../../../resources/PermissionsHelper.js";
import UnitOfMeasureDashboardPage from "../Shared/UnitOfMeasures/UnitOfMeasureDashboardPage.jsx";

const InventoryTabPage = () => {
    const userPermissions = useUserPermissions();
    const hasPermissionToView = (requiredPermissions, optionalPermissions) => {
        return PermissionsHelper.hasPermission(
            userPermissions,
            requiredPermissions,
            optionalPermissions
        );
    };

    return (
        <div>
            <Card style={{ border: "none" }}>
                <ContentHeader
                    title="Inventory Module Configuration"
                    className={"AlignTextLeft"}
                />
                <br />
                <HighlightedTabStrip className="tabs">
                    {hasPermissionToView([PermissionsEnum.ViewBillingTerm]) && (
                        <TabStripTab title="Billing Terms">
                            <BillingTermDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([PermissionsEnum.ViewItemType]) && (
                        <TabStripTab title="Item Types">
                            <ItemTypeDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([
                        PermissionsEnum.ViewStorageLocation,
                    ]) && (
                        <TabStripTab title="Storage Locations">
                            <StorageLocationDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([
                        PermissionsEnum.ViewUnitOfMeasure,
                    ]) && (
                        <TabStripTab title="Unit of Measures">
                            <UnitOfMeasureDashboardPage />
                        </TabStripTab>
                    )}
                </HighlightedTabStrip>
            </Card>
        </div>
    );
};

export default InventoryTabPage;
