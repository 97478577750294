import React from "react";

export const AccountDropdownItemRenderer = (li, itemProps) => {
    const isRoot = itemProps.dataItem.isRoot;
    let itemChildren;

    if (isRoot) {
        itemChildren = (
            <span>
                <strong>{itemProps.dataItem.name}</strong>
            </span>
        );
    } else {
        itemChildren = (
            <span>
                <span>{itemProps.dataItem.parentAccount.name} -</span>
                <span> {itemProps.dataItem.name}</span>
            </span>
        );
    }

    return React.cloneElement(li, li.props, itemChildren);
};
