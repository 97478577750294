import React from "react";
import { TabStripTab } from "@progress/kendo-react-layout";
import LeadDashboardPage from "../LeadDashboardPage";
import DeadLeadDashboardPage from "../DeadLeadDashboardPage";
import HeldLeadDashboardPage from "../HeldLeadDashboardPage";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";

const LeadDashboardTabs = () => {
    return (
        <HighlightedTabStrip>
            <TabStripTab title="Active">
                <LeadDashboardPage />
            </TabStripTab>
            <TabStripTab title="Dead">
                <DeadLeadDashboardPage />
            </TabStripTab>
            <TabStripTab title="Held">
                <HeldLeadDashboardPage />
            </TabStripTab>
        </HighlightedTabStrip>
    );
};

export default LeadDashboardTabs;
