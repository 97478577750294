import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import InventoryService from "../../../../services/Deprecated/inventory/InventoryService";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import {
    FormDropDown,
    FormInput,
    FormNumericInput,
    FormRemoteDropDown,
} from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import { InventoryModalHelper } from "../Helpers/InventoryModalHelper";
import { useAccounts } from "../../../../hooks/Deprecated/useAccounts.js";
import { AccountDropdownItemRenderer } from "../../../../components/DropDownRenders/AccountDropDownRender.jsx";

/**
 * @function InventoryModal
 * @param props.id {string} - The ID of the inventory item.
 * @param props.isUpdate {boolean} - Whether the modal is for updating an existing item.
 * @param props.close {function} - Function to close the modal.
 * @param props.visible {boolean} - Whether the modal is visible.
 * @param props.record {object} - The record to be updated.
 * @param props.setRecord {function} - Function to set the record.
 * @return {unknown}
 * @constructor
 */
export const InventoryModal = (props) => {
    const { id, isUpdate, close, visible, record, setRecord } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(undefined);
    const { expenseAccounts, incomeAccounts } = useAccounts();

    useEffect(() => {
        if (!visible) return;

        if (id) {
            InventoryService.get(id).then((res) => {
                const data = InventoryModalHelper.formatDataForModal(res.data);
                setInitialFormData(data);
            });
        } else {
            setInitialFormData({
                ...record,
                cost: {
                    amount: 0,
                    currency: "USD",
                },
            });
        }
    }, [visible, id]);

    const onClose = () => {
        setInitialFormData(undefined);
        setRecord(undefined);
        close();
    };

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        const data = InventoryModalHelper.formatDataForNetwork(dataItem);

        const service = isUpdate
            ? InventoryService.update
            : InventoryService.create;

        service(data).then(() => {
            triggerReload();
            setLoaderVisible(false);
            onClose();
        });
    };

    const onItemChange = (e, formRenderProps) => {
        const itemType = e.value?.item?.itemType;
        const isSlab = itemType?.name === "Slab";

        setRecord({
            ...record,
            itemType,
        });

        formRenderProps.onChange("identifier", {
            value: isSlab ? `${crypto.randomUUID()}`.slice(0, 8) : "",
        });
        formRenderProps.onChange("length", {
            value: isSlab ? e.value?.item?.defaultLength : "",
        });
        formRenderProps.onChange("width", {
            value: isSlab ? e.value?.item?.defaultWidth : "",
        });
        formRenderProps.onChange("expenseAccount", {
            value: e.value?.expenseAccount,
        });
        formRenderProps.onChange("incomeAccount", {
            value: e.value?.incomeAccount,
        });
        formRenderProps.onChange("cost", {
            value: e.value?.vendorCost,
        });
        formRenderProps.onChange("freight", {
            value: e.value?.freightCharge,
        });
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={isUpdate ? "Edit Inventory" : "Add Inventory"}
                onClose={onClose}
                size={"medium"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement
                            className={
                                "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                            }
                        >
                            <div style={{ width: "100%", maxWidth: "650px" }}>
                                <span>
                                    <Field
                                        name={"storageLocation"}
                                        component={FormRemoteDropDown}
                                        url={
                                            "/Inventory/StorageLocation/GetAll?"
                                        }
                                        label={"Select a Storage Location..."}
                                        style={{ width: "100%" }}
                                        validator={requiredValidator}
                                    />
                                    {!record?.hideVendor && (
                                        <Field
                                            name={"vendor"}
                                            component={FormRemoteDropDown}
                                            url={"/Inventory/Vendor/GetAll?"}
                                            label={"Select a Vendor..."}
                                            style={{ width: "100%" }}
                                            validator={requiredValidator}
                                            onChange={(e) => {
                                                setSelectedVendor(e.value);
                                                formRenderProps.onChange(
                                                    "item",
                                                    {
                                                        value: "",
                                                    }
                                                );
                                            }}
                                        />
                                    )}
                                    {!record?.hideItem && (
                                        <Field
                                            name={"item"}
                                            key={selectedVendor?.id}
                                            component={FormRemoteDropDown}
                                            textField={"item.name"}
                                            url={
                                                "/Inventory/Vendor/Item/GetAll?"
                                            }
                                            searchFields={["item.name"]}
                                            label={"Select a Item..."}
                                            style={{ width: "100%" }}
                                            disabled={!selectedVendor}
                                            initialFilters={[
                                                {
                                                    field: "vendorId",
                                                    operator: "eq",
                                                    value: selectedVendor?.id,
                                                },
                                                {
                                                    field: "item.IsActive",
                                                    operator: "eq",
                                                    value: true,
                                                },
                                            ]}
                                            onChange={(e) =>
                                                onItemChange(e, formRenderProps)
                                            }
                                        />
                                    )}
                                    <Field
                                        name={"expenseAccount"}
                                        component={FormDropDown}
                                        dataItemKey={"id"}
                                        textField={"name"}
                                        data={expenseAccounts}
                                        label={"Select a Expense Account..."}
                                        disabled={
                                            !formRenderProps.valueGetter("item")
                                        }
                                        validator={requiredValidator}
                                        itemRender={AccountDropdownItemRenderer}
                                    />
                                    <Field
                                        name={"incomeAccount"}
                                        component={FormDropDown}
                                        dataItemKey={"id"}
                                        textField={"name"}
                                        data={incomeAccounts}
                                        label={"Select a Income Account..."}
                                        disabled={
                                            !formRenderProps.valueGetter("item")
                                        }
                                        validator={requiredValidator}
                                        itemRender={AccountDropdownItemRenderer}
                                    />
                                    <Field
                                        name={"quantity"}
                                        component={FormNumericInput}
                                        label={"Quantity:"}
                                        min={1}
                                        defaultValue={1}
                                    />
                                </span>
                                <span>
                                    <Field
                                        name={"cost.amount"}
                                        component={FormNumericInput}
                                        format={"c2"}
                                        label={"Cost:"}
                                        min={0}
                                        disabled={
                                            !formRenderProps.valueGetter("item")
                                        }
                                        defaultValue={0}
                                    />
                                    <Field
                                        name={"tax.amount"}
                                        component={FormNumericInput}
                                        format={"c2"}
                                        label={"Tax:"}
                                        min={0}
                                    />
                                    <Field
                                        name={"adjustment.amount"}
                                        component={FormNumericInput}
                                        format={"c2"}
                                        label={"Adjustment:"}
                                        min={0}
                                    />
                                    <Field
                                        name={"freight.amount"}
                                        component={FormNumericInput}
                                        format={"c2"}
                                        label={"Freight:"}
                                        min={0}
                                        disabled={
                                            !formRenderProps.valueGetter("item")
                                        }
                                    />
                                    {record?.itemType?.name === "Slab" && (
                                        <>
                                            <hr
                                                className={"Separator"}
                                                style={{
                                                    marginTop: "15px",
                                                    marginBottom: "15px",
                                                }}
                                            />
                                            <Field
                                                name={"identifier"}
                                                component={FormInput}
                                                label={"Identifier:"}
                                                validator={requiredValidator}
                                                maxLength={8}
                                                minLength={0}
                                            />
                                            <Field
                                                name={"length"}
                                                component={FormNumericInput}
                                                label={"Length (Inch):"}
                                                min={0}
                                                format={"0"}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name={"width"}
                                                component={FormNumericInput}
                                                label={"Width (Inch):"}
                                                min={0}
                                                format={"0"}
                                                validator={requiredValidator}
                                            />
                                        </>
                                    )}
                                </span>
                            </div>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={!isUpdate}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
