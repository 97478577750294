import React, { useContext, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import dayjs from "dayjs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import { updateAppointmentTime } from "../../../../services/Deprecated/customer/eventServices";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const AppointmentTimeEdit = ({
    visible,
    onCancel,
    record,
    loaderVisible,
    toggleLoaderVisible,
    triggerReload,
}) => {
    const eventContext = useContext(EventContext);
    const eventDate = dayjs
        .utc(eventContext.event.eventDate)
        .format("YYYY-MM-DD");

    const defaultStartTime = dayjs
        .utc(record.startTime)
        .local()
        .format("h:mm A");
    const defaultEndTime = dayjs.utc(record.endTime).local().format("h:mm A");

    const [min, setMin] = useState(dayjs.utc(record.startTime).local());
    const [max, setMax] = useState(dayjs.utc(record.endTime).local());
    const [error, setError] = useState(null);

    const generateTimeOptions = () => {
        const times = [];
        const start = dayjs().startOf("day");
        for (let i = 0; i < 48; i++) {
            times.push(start.add(30 * i, "minute").format("h:mm A"));
        }
        return times;
    };

    const timeOptions = generateTimeOptions();

    const onStartDateChange = (e, formRenderProps) => {
        const value = e.value;
        const parsedValue = dayjs(
            eventDate + " " + value,
            "YYYY-MM-DD h:mm A"
        ).utc();
        setMin(parsedValue);
        validateTimeRange(parsedValue, max);

        formRenderProps.onChange("startTime", "enableButton");
    };

    const onEndDateChange = (e, formRenderProps) => {
        const value = e.value;
        const parsedValue = dayjs(
            eventDate + " " + value,
            "YYYY-MM-DD h:mm A"
        ).utc();
        setMax(parsedValue);
        validateTimeRange(min, parsedValue);

        formRenderProps.onChange("endTime", "enableButton");
    };

    const validateTimeRange = (startTime, endTime) => {
        if (startTime.isAfter(endTime)) {
            setError("Start time cannot be after end time.");
        } else {
            setError(null);
        }
    };
    const submitAppointmentTime = (dataItem) => {
        if (error) {
            return;
        }

        toggleLoaderVisible();

        dataItem.startTime = min.toISOString();
        dataItem.endTime = max.toISOString();

        updateAppointmentTime(dataItem)
            .then(() => {
                triggerReload(dataItem);
                onCancel();
            })
            .catch((error) => {
                console.error("Error Message: ", error.message);
                if (error.response) {
                    console.error("Response Data: ", error.response.data);
                    console.error("Response Status: ", error.response.status);
                    console.error("Response Headers: ", error.response.headers);
                }
            })
            .finally(() => {
                toggleLoaderVisible();
            });
    };

    return (
        <div>
            {visible && (
                <Form
                    initialValues={record}
                    onSubmit={submitAppointmentTime}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Edit Appointment Time"}
                            onClose={onCancel}
                            size={"medium"}
                        >
                            <FormElement>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <fieldset
                                        className={"k-form-fieldset"}
                                        style={{
                                            border: "none",
                                            textAlign: "left",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            gap: "10px",
                                            maxWidth: "220px",
                                        }}
                                    >
                                        <label>
                                            <span>Start Time</span>
                                            <ComboBox
                                                name={"startTime"}
                                                data={timeOptions}
                                                filterable={true}
                                                required={true}
                                                onChange={(e) =>
                                                    onStartDateChange(
                                                        e,
                                                        formRenderProps
                                                    )
                                                }
                                                defaultValue={defaultStartTime}
                                            />
                                        </label>
                                        <label>
                                            <span>End Time</span>
                                            <ComboBox
                                                name={"endTime"}
                                                data={timeOptions}
                                                filterable={true}
                                                required={true}
                                                onChange={(e) =>
                                                    onEndDateChange(
                                                        e,
                                                        formRenderProps
                                                    )
                                                }
                                                defaultValue={defaultEndTime}
                                            />
                                        </label>
                                        {error && (
                                            <span style={{ color: "red" }}>
                                                {error}
                                            </span>
                                        )}
                                    </fieldset>
                                </div>
                                <FormButtons
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={onCancel}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    isCreate={false}
                                />
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default AppointmentTimeEdit;
