import * as React from "react";
import { useContext } from "react";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import "../../AccountingModule.module.scss";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell";
import ChartOfAccountsService from "../../../../services/Deprecated/accounting/ChartOfAccountsService";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";

const CoATLActionColumnCell = (props) => {
    const { dataItem, accountModal } = props;
    const { triggerReload } = useContext(ReloadDataContext);

    const onDelete = () => {
        ChartOfAccountsService.deleteAccount(dataItem.id).then((_) => {
            triggerReload();
            accountModal.close();
        });
    };

    return (
        <ActionCell
            additionalActions={[
                {
                    text: "Edit",
                    onClick: () => accountModal.open(props.dataItem.id),
                    requiredPermissions: [PermissionsEnum.UpdateTransaction],
                    disabled: dataItem.isPermanentEntity,
                    disabledToolTipMessage:
                        "This account is permanent and cannot be edited.",
                },
                {
                    text: "Delete",
                    onClick: onDelete,
                    requiredPermissions: [PermissionsEnum.DeleteTransaction],
                    confirmationState: {
                        needsConfirmation: true,
                    },
                    disabled:
                        dataItem.isPermanentEntity ||
                        dataItem.childAccounts.length !== 0 ||
                        dataItem.hasTransactions,
                    disabledToolTipMessage:
                        "This account cannot be deleted because it has child accounts or transactions.",
                },
                {
                    text: "Add Sub-Account",
                    onClick: () =>
                        accountModal.open(undefined, {
                            parentId: dataItem.id,
                            isCreateSub: true,
                            type: dataItem.type,
                        }),
                    requiredPermissions: [PermissionsEnum.CreateAccount],
                },
            ]}
        />
    );
};

export default CoATLActionColumnCell;
