export class ItemAllocationHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @param {Boolean} isDeAllocation - Is deallocation
     * @return {Object}
     */
    static formatDataForNetwork(dataItem, isDeAllocation) {
        if (isDeAllocation) {
            return this.formatDeAllocationData(dataItem);
        } else {
            return this.formatAllocationData(dataItem);
        }
    }

    static formatDeAllocationData(dataItem) {
        const item = Object.values(dataItem.items).filter(
            (i) => i !== undefined
        )[0];

        return {
            itemId: dataItem.itemId,
            quantity: item.quantity,
            storageLocationId: item.storageLocation.id,
            jobId: dataItem.jobId,
            identifier: item.identifier,
        };
    }

    static formatAllocationData(dataItem) {
        const allocationItems = Object.values(dataItem.items).filter(
            (i) => i !== undefined
        );

        return {
            allocationItems: allocationItems.map((item) => {
                return {
                    itemId: dataItem.itemId,
                    quantity: item.quantity,
                    storageLocationId: item.storageLocation.id,
                    jobId: dataItem.jobId,
                    identifier: item.identifier,
                };
            }),
        };
    }
}
