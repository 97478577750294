import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { useModal } from "../../../hooks/useModal";
import { InventoryModal } from "./Components/InventoryModal";
import { ActionButton } from "../../../components/Buttons/ActionButton";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { DataGrid } from "../../../components/Grids/DataGrid";
import { LinkCell } from "../../../components/GridColumnComponents/LinkCell";
import { EnumCell } from "../../../components/GridColumnComponents/EnumCell";
import { InventoryType } from "../../../resources/Enums/InventoryType";
import { BaseCell } from "../../../components/GridColumnComponents/BaseCell";
import { ActionCell } from "../../../components/GridColumnComponents/ActionCell";
import { InventoryDeletionModal } from "./Components/InventoryDeletionModal";
import { InventoryModalHelper } from "./Helpers/InventoryModalHelper.js";
import { BreadcrumbContext } from "../../../providers/BreadCrumbProvider.jsx";

const InventoryDashboardPage = () => {
    const { handleRouteChange } = useContext(BreadcrumbContext);
    const inventoryModal = useModal();
    const deletionModal = useModal();

    const generateActionButtons = (props) => {
        switch (props.dataItem.type) {
            case InventoryType.Enum.Available:
                return [
                    {
                        text: "Edit",
                        onClick: () =>
                            deletionModal.open(undefined, {
                                inventoryIds: props.dataItem.inventoryIds,
                                quantity: props.dataItem.quantity,
                            }),
                        requiredPermissions: [PermissionsEnum.DeleteInventory],
                    },
                    {
                        text: "View",
                        onClick: () =>
                            handleRouteChange({
                                uri: `/Inventory/Dashboard/Item/${props.dataItem.item.id}`,
                                breadcrumbInfo: [
                                    {
                                        id: props.dataItem.item.id,
                                        text: props.dataItem.item.name,
                                    },
                                ],
                            }),
                        requiredPermissions: [PermissionsEnum.ViewInventory],
                    },
                ];
            case InventoryType.Enum.Allocated:
                return [
                    {
                        text: "View",
                        onClick: () =>
                            handleRouteChange({
                                uri: `/Inventory/Dashboard/Item/${props.dataItem.item.id}`,
                                breadcrumbInfo: [
                                    {
                                        id: props.dataItem.item.id,
                                        text: props.dataItem.item.name,
                                    },
                                ],
                            }),
                        requiredPermissions: [PermissionsEnum.ViewInventory],
                    },
                ];
        }
    };

    return (
        <div className={"JustifyLeftAlignLeft FlexColumn LargeGap"}>
            <InventoryModal {...inventoryModal} />
            <InventoryDeletionModal {...deletionModal} />
            <ActionButton
                onClick={() => inventoryModal.open()}
                text={"Add Inventory"}
                icon={"k-icon k-i-plus"}
                requiredPermissions={[PermissionsEnum.CreateInventory]}
            />
            <DataGrid
                style={{
                    maxWidth: "99.99%",
                }}
                useInlineEdit={false}
                uri={"/Inventory/Inventory/GetAllGrouped?"}
                searchFields={["name"]}
                resizable={true}
                initialSort={[
                    {
                        field: "type",
                        dir: "asc",
                    },
                ]}
            >
                <GridColumn
                    className={"k-table-td"}
                    field={"storageLocation.name"}
                    title={"Location:"}
                    width={250}
                    minResizableWidth={100}
                    locked={true}
                />
                <GridColumn
                    className={"k-table-td"}
                    field={"name"}
                    title={"Item:"}
                    width={300}
                    minResizableWidth={100}
                    locked={true}
                    cell={(props) => (
                        <LinkCell
                            uri={`/Inventory/Item/${props.dataItem.item.id}`}
                            {...props}
                            dataItem={props.dataItem.item}
                        />
                    )}
                />
                <GridColumn
                    className={"k-table-td"}
                    field={"type"}
                    title={"Status:"}
                    width={150}
                    minResizableWidth={50}
                    cell={(props) => (
                        <EnumCell enumerable={InventoryType} {...props} />
                    )}
                />
                <GridColumn
                    className={"k-table-td"}
                    field={"name"}
                    title={"Vendor:"}
                    width={250}
                    minResizableWidth={100}
                    cell={(props) => (
                        <LinkCell
                            uri={`/Inventory/Vendor/${props.dataItem.vendor.id}`}
                            {...props}
                            dataItem={props.dataItem.vendor}
                        />
                    )}
                />
                <GridColumn
                    field="cost.amount"
                    title="Avg Cost:"
                    width={125}
                    minResizableWidth={50}
                    format={"{0:c}"}
                />
                <GridColumn
                    field="measurements"
                    title="Measurements:"
                    width={250}
                    minResizableWidth={50}
                    cell={(props) => (
                        <BaseCell {...props}>
                            {InventoryModalHelper.displayMeasurement(
                                props.dataItem
                            )}
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="quantity"
                    title="QTY:"
                    width={75}
                    minResizableWidth={50}
                />
                <GridColumn
                    field="identifier"
                    title="Identifier:"
                    width={100}
                    minResizableWidth={50}
                />
                <GridColumn
                    width={150}
                    resizable={false}
                    cell={(props) => (
                        <ActionCell
                            additionalActions={generateActionButtons(props)}
                        />
                    )}
                />
            </DataGrid>
        </div>
    );
};

export default InventoryDashboardPage;
