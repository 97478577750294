import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import dayjs from "dayjs";

import { ComboBox } from "@progress/kendo-react-dropdowns";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { createAppointmentTime } from "../../../../services/Deprecated/customer/eventServices";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const AppointmentTimeCreate = () => {
    const { bool: createVisible, toggle: toggleCreateVisible } = useToggle();
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const eventContext = useContext(EventContext);
    const reloadDataContext = useContext(ReloadDataContext);
    const [min, setMin] = useState(dayjs());
    const [max, setMax] = useState(dayjs());
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!eventContext.event?.eventDate) return;
        const eventDate = dayjs.utc(eventContext.event.eventDate).format();

        setMin(dayjs.utc(eventDate).startOf("day"));
        setMax(dayjs.utc(eventDate).endOf("day"));
    }, [eventContext.event?.eventDate]);

    const generateTimeOptions = () => {
        const times = [];
        const start = dayjs().startOf("day");
        for (let i = 0; i < 48; i++) {
            times.push(start.add(30 * i, "minute").format("h:mm A"));
        }
        return times;
    };

    const timeOptionsRef = useRef(generateTimeOptions());

    const onStartDateChange = (e, formRenderProps) => {
        const value = e.value;

        const eventDate = dayjs.utc(eventContext.event.eventDate);
        const eventDateString = eventDate.format("YYYY-MM-DD");
        const parsedValue = dayjs(
            eventDateString + " " + value,
            "YYYY-MM-DD h:mm A"
        ).utc();

        setMin(parsedValue);
        validateTimeRange(parsedValue, max);

        formRenderProps.onChange("startTime", "enableButton");
    };

    const onEndDateChange = (e, formRenderProps) => {
        const value = e.value;
        const eventDate = dayjs.utc(eventContext.event.eventDate);
        const eventDateString = eventDate.format("YYYY-MM-DD");
        const parsedValue = dayjs(
            eventDateString + " " + value,
            "YYYY-MM-DD h:mm A"
        ).utc();

        setMax(parsedValue);
        validateTimeRange(min, parsedValue);

        formRenderProps.onChange("endTime", "enableButton");
    };

    const validateTimeRange = (startTime, endTime) => {
        if (startTime.isAfter(endTime)) {
            setError("Start time cannot be after end time.");
        } else {
            setError(null);
        }
    };
    const submitAppointmentTime = (dataItem) => {
        if (error) {
            return;
        }

        toggleLoaderVisible();

        dataItem.calendarEventId = eventContext.event.id;
        dataItem.employeeId = dataItem.employee?.id;
        dataItem.startTime = min.toISOString();
        dataItem.endTime = max.toISOString();

        createAppointmentTime(dataItem)
            .then(() => {
                reloadDataContext.triggerReload(Math.random());
                toggleCreateVisible();
            })
            .catch((error) => {
                console.error("Error Message: ", error.message);
                if (error.response) {
                    console.error("Response Data: ", error.response.data);
                    console.error("Response Status: ", error.response.status);
                    console.error("Response Headers: ", error.response.headers);
                }
            })
            .finally(() => {
                toggleLoaderVisible();
            });
    };

    return (
        <div>
            <ActionButton
                icon={"k-icon k-i-plus"}
                onClick={toggleCreateVisible}
                requiredPermissions={[PermissionsEnum.UpdateCalendarEvent]}
            >
                Create
            </ActionButton>
            {createVisible && (
                <Form
                    onSubmit={submitAppointmentTime}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Create Appointment Time"}
                            onClose={toggleCreateVisible}
                            size={"small"}
                        >
                            <FormElement>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <fieldset
                                        className={"k-form-fieldset"}
                                        style={{
                                            border: "none",
                                            textAlign: "left",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            gap: "10px",
                                            maxWidth: "220px",
                                        }}
                                    >
                                        <label>
                                            <span>Start Time</span>
                                            <ComboBox
                                                name={"startTime"}
                                                data={timeOptionsRef.current}
                                                filterable={true}
                                                required={true}
                                                onChange={(e) =>
                                                    onStartDateChange(
                                                        e,
                                                        formRenderProps
                                                    )
                                                }
                                            />
                                        </label>
                                        <ComboBox
                                            name={"endTime"}
                                            label={"End Time"}
                                            data={timeOptionsRef.current}
                                            filterable={true}
                                            required={true}
                                            onChange={(e) =>
                                                onEndDateChange(
                                                    e,
                                                    formRenderProps
                                                )
                                            }
                                        />
                                        {error && (
                                            <span style={{ color: "red" }}>
                                                {error}
                                            </span>
                                        )}
                                    </fieldset>
                                </div>
                                <FormButtons
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={toggleCreateVisible}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    isCreate={true}
                                />
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default AppointmentTimeCreate;
