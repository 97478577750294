import { GridColumn } from "@progress/kendo-react-grid";
import {
    CustomDateCell,
    DisplayDocumentNumberCell,
} from "../../../../components/Deprecated/CustomGridCells";
import { HintHeaderCustomCell } from "../../../../components/Deprecated/CustomHeaderCells";
import React, { useContext } from "react";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { VendorContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import { useModal } from "../../../../hooks/useModal.js";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell.jsx";
import { VendorDocumentModalWrapper } from "../../VendorDocuments/Components/VendorDocumentModal/VendorDocumentModal.jsx";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";

const VendorPOsGrid = () => {
    const { vendor } = useContext(VendorContext);
    const documentModal = useModal();

    const initialPOSort = [
        {
            field: "createdAt",
            dir: "dsc",
        },
    ];
    const initialPOFilter = {
        logic: "and",
        filters: [
            {
                field: "vendorId",
                operator: "eq",
                value: vendor.id,
            },
            {
                field: "type",
                operator: "eq",
                value: 0,
            },
        ],
    };

    return (
        <span
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 10,
            }}
        >
            <ActionButton
                text={"Create PO"}
                onClick={() =>
                    documentModal.open(undefined, {
                        vendor: vendor,
                    })
                }
                permissions={[PermissionsEnum.CreateVendorDocument]}
                icon={"k-icon k-i-plus"}
            />
            <VendorDocumentModalWrapper {...documentModal} />
            <DataGrid
                uri={`/Inventory/Vendor/Document/GetAll?`}
                sortable={true}
                sort={initialPOSort}
                searchFields={["documentNumber"]}
                initialFilter={initialPOFilter}
            >
                <GridColumn
                    field="documentNumber"
                    title="PO Number:"
                    locked={true}
                    width={150}
                    cell={DisplayDocumentNumberCell}
                />
                <GridColumn
                    field="created"
                    title="Ordered:"
                    locked={true}
                    width={150}
                    cell={(props) => {
                        const dateObj = {
                            data: props.dataItem.created,
                        };
                        return CustomDateCell(dateObj);
                    }}
                />
                <GridColumn
                    field="expectedDeliveryDate"
                    title="Expected Delivery:"
                    locked={true}
                    width={150}
                    cell={(props) => {
                        const dateObj = {
                            data: props.dataItem.expectedDeliveryDate,
                        };
                        return CustomDateCell(dateObj);
                    }}
                />
                <GridColumn
                    field="fullyReceivedDate"
                    title="Date Received:"
                    width={200}
                    headerCell={(props) => {
                        const hint = "Date the PO was fully received.";
                        const position = "top";
                        return (
                            <HintHeaderCustomCell
                                props={props}
                                hint={hint}
                                position={position}
                            />
                        );
                    }}
                    cell={(props) => {
                        const dateObj = {
                            data: props.dataItem.fullyReceivedDate,
                        };
                        return CustomDateCell(dateObj);
                    }}
                />
                <GridColumn
                    field="total.amount"
                    filter={"numeric"}
                    format={"{0:c}"}
                    title="Total:"
                    width={150}
                />
                <GridColumn
                    width={80}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            additionalActions={[
                                {
                                    text: props.dataItem.hasChildren
                                        ? "View"
                                        : "Edit",
                                    onClick: () =>
                                        documentModal.open(props.dataItem.id),
                                    permissions: props.dataItem.hasChildren
                                        ? [PermissionsEnum.ViewVendorDocument]
                                        : [
                                              PermissionsEnum.UpdateVendorDocument,
                                          ],
                                },
                            ]}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};

export default VendorPOsGrid;
