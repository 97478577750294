import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginApiRequest } from "../services/authconfig";
import React from "react";
import { ActionButton } from "../components/Buttons/ActionButton.jsx";

export const SignInButton = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    React.useEffect(() => {
        if (!isAuthenticated) handleLogin();
    }, [isAuthenticated]);

    const handleLogin = () => {
        instance.loginRedirect(loginApiRequest).catch((_) => {});
    };

    const handleLogout = () => {
        instance.logoutRedirect().catch(console.error);
    };

    return (
        <ActionButton
            themeColor={"light"}
            onClick={handleLogout}
            style={{ width: "100px", margin: "10px" }}
            icon={"k-icon k-i-logout"}
        >
            Sign Out
        </ActionButton>
    );
};

export default SignInButton;
