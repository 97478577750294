import React from "react";
import { LayoutOrientation } from "../../resources/Enums/LayoutOrientation.js";

/**
 * @function ActionButton
 * @param {number} props.orientation (Enum: LayoutOrientation) (optional, defaults to horizontal)
 * @param {React.CSSProperties} props.style (optional, overrides other styles)
 * @param {Element} props.children
 * @param {Object} props.others
 * @return {Element}
 * @constructor
 */
export const ActionButtonContainer = (props) => {
    let orientation = props.orientation ?? LayoutOrientation.Enum.Horizontal;

    const isVertical = orientation === LayoutOrientation.Enum.Vertical;

    /** @type {React.CSSProperties} */
    let style = {
        display: "flex",
    };

    if (isVertical) {
        style.flexDirection = "column";
        style.alignItems = "center";
    } else {
        style.justifyContent = "center";
    }

    if (props.style) {
        style = { ...style, ...props.style };
    }

    return <div style={style}>{props.children}</div>;
};
