import * as React from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { DataGrid } from "../../../../../../../../../components/Grids/DataGrid.jsx";
import { InventoryType } from "../../../../../../../../../resources/Enums/InventoryType.js";
import { BaseCell } from "../../../../../../../../../components/GridColumnComponents/BaseCell.jsx";
import { InventoryModalHelper } from "../../../../../../../../Inventory/Inventory/Helpers/InventoryModalHelper.js";
import { ContentHeader } from "../../../../../../../../../components/ContentHeader.jsx";
import { ActionCell } from "../../../../../../../../../components/GridColumnComponents/ActionCell.jsx";
import { useModal } from "../../../../../../../../../hooks/useModal.js";
import { SqftDeAllocationModal } from "./SqftDeAllocationModal.jsx";

export const SqftDeAllocation = (props) => {
    const { record } = props;
    const sqftDeAllocationModal = useModal();

    return (
        <span>
            <SqftDeAllocationModal {...sqftDeAllocationModal} />
            <ContentHeader
                title={"Select Inventory to Return"}
                style={{
                    fontSize: "18px",
                    textAlign: "left",
                }}
            />
            <DataGrid
                style={{
                    maxWidth: "99.99%",
                }}
                useInlineEdit={false}
                uri={"/Inventory/Inventory/GetAll?"}
                resizable={true}
                initialSort={[
                    {
                        field: "identifier",
                        dir: "asc",
                    },
                ]}
                showColumnsMultiSelect={false}
                initialFilter={{
                    logic: "and",
                    filters: [
                        {
                            field: "item.id",
                            operator: "eq",
                            value: record.itemId,
                        },
                        {
                            field: "type",
                            operator: "eq",
                            value: InventoryType.Enum.Allocated,
                        },
                        {
                            field: "job.id",
                            operator: "eq",
                            value: record.jobId,
                        },
                    ],
                }}
            >
                <GridColumn
                    field="measurements"
                    title="Measurements:"
                    width={"auto"}
                    minResizableWidth={50}
                    cell={(props) => (
                        <BaseCell {...props}>
                            {InventoryModalHelper.displayMeasurement(
                                props.dataItem
                            )}
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="identifier"
                    title="Identifier:"
                    width={100}
                    minResizableWidth={50}
                />
                <GridColumn
                    className={"k-table-td"}
                    field={"storageLocation.name"}
                    title={"Location:"}
                    width={250}
                    minResizableWidth={100}
                    locked={true}
                />
                <GridColumn
                    width={85}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            additionalActions={[
                                {
                                    text: "Return",
                                    onClick: () => {
                                        sqftDeAllocationModal.open(undefined, {
                                            ...props.dataItem,
                                            jobId: record.jobId,
                                        });
                                    },
                                },
                            ]}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
