import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import {
    FormDropDown,
    FormNumericInput,
} from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators.js";
import { AccountDropdownItemRenderer } from "../../../../components/DropDownRenders/AccountDropDownRender.jsx";
import { useAccounts } from "../../../../hooks/Deprecated/useAccounts.js";
import InventoryService from "../../../../services/InventoryService.js";

/**
 * @function InventoryDeletionModal
 * @param props.close {function} - Function to close the modal.
 * @param props.visible {boolean} - Whether the modal is visible.
 * @return {unknown}
 * @constructor
 */
export const InventoryDeletionModal = (props) => {
    const { record, close, visible } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const { expenseAccounts, isLoading } = useAccounts();

    useEffect(() => {
        if (!visible || !record) return;

        setInitialFormData(record);
    }, [visible]);

    const onClose = () => {
        setInitialFormData(undefined);
        setLoaderVisible(false);
        close();
    };

    const onSubmit = async (dataItem) => {
        setLoaderVisible(true);

        const service = new InventoryService();
        for (let i = 0; i < dataItem.quantity; i++) {
            await service.delete({
                id: record.inventoryIds[i],
                expenseAccountId: dataItem.expenseAccount.id,
            });
        }

        triggerReload();
        onClose();
    };

    return (
        visible &&
        initialFormData &&
        !isLoading && (
            <ResponsiveDialog
                title={"Remove Inventory"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement
                            className={
                                "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                            }
                        >
                            <div
                                style={{
                                    width: "100%",
                                }}
                            >
                                <Field
                                    name={"expenseAccount"}
                                    component={FormDropDown}
                                    dataItemKey={"id"}
                                    textField={"name"}
                                    data={expenseAccounts}
                                    label={"Select a Expense Account..."}
                                    validator={requiredValidator}
                                    itemRender={AccountDropdownItemRenderer}
                                />
                                {!record.hideQuantity && (
                                    <Field
                                        name={"quantity"}
                                        component={FormNumericInput}
                                        label={"Quantity:"}
                                        min={1}
                                        max={initialFormData.quantity}
                                    />
                                )}
                            </div>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                                theme={"error"}
                                text={"Remove"}
                                isCreate={false}
                                icon={"k-icon k-i-trash"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
