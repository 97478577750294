import React, { useContext } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Field } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { VendorContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import {
    FormDropDown,
    FormInput,
} from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import { useAccounts } from "../../../../hooks/Deprecated/useAccounts.js";
import { AccountDropdownItemRenderer } from "../../../../components/DropDownRenders/AccountDropDownRender.jsx";

const BillingInformationForm = () => {
    const vendorContext = useContext(VendorContext);
    const { expenseAccounts, incomeAccounts } = useAccounts();

    return (
        <GridLayout
            rows={[{ height: "50px" }, { height: "auto" }]}
            cols={[{ width: "48%" }, { width: "48%" }]}
            gap={{ rows: 15, cols: 15 }}
        >
            <GridLayoutItem col={1} row={1} colSpan={2}>
                <div style={{ textAlign: "center" }}>
                    <h2
                        style={{
                            margin: "0",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                        }}
                    >
                        Billing Information:
                    </h2>
                </div>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={2} colSpan={1}>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5 }}
                >
                    <Field
                        name={"federalTaxId"}
                        component={FormInput}
                        label={"Federal Tax Identification:"}
                    />
                    <Field
                        name={"accountNumber"}
                        component={FormInput}
                        label={"Account Number:"}
                    />
                    <Field
                        name={"expenseAccount"}
                        component={FormDropDown}
                        data={expenseAccounts}
                        dataItemKey="id"
                        textField="name"
                        validator={requiredValidator}
                        label={"Expense Account:"}
                        itemRender={AccountDropdownItemRenderer}
                    />
                    <Field
                        name={"incomeAccount"}
                        component={FormDropDown}
                        data={incomeAccounts}
                        dataItemKey="id"
                        textField="name"
                        validator={requiredValidator}
                        label={"Income Account:"}
                        itemRender={AccountDropdownItemRenderer}
                    />
                </fieldset>
            </GridLayoutItem>
            <GridLayoutItem col={2} row={2} colSpan={1}>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5 }}
                >
                    <Field
                        name={"billingTerm"}
                        component={DropDownList}
                        data={vendorContext.relationships.billingTerms.data}
                        dataItemKey="id"
                        textField="name"
                        label={"Billing Term:"}
                    />
                    <Field
                        name={"paymentType"}
                        component={DropDownList}
                        data={vendorContext.relationships.paymentTypes.data}
                        dataItemKey="id"
                        textField="name"
                        label={"Payment Type:"}
                    />
                </fieldset>
            </GridLayoutItem>
        </GridLayout>
    );
};

export default BillingInformationForm;
