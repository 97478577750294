import { axiosInstance as axios } from "../../../../services/axiosInstance";
import React, { useContext, useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { RoleModal } from "./Components/RoleModal";
import { useModal } from "../../../../hooks/useModal";
import { PermissionContext } from "../../../../providers/Deprecated/Authentication/Permission/PermissionListBoxProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import DefaultRoleModal from "./Components/DefaultRoleModal";
import PermissionListBox from "../../../../components/Deprecated/PermissionListBox";
import {
    getAllPermissions,
    updatePermissionsRole,
} from "../../../../services/Deprecated/authentication/permissionService";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import RoleService from "../../../../services/RoleService.js";
import { ToastContext } from "../../../../providers/ToastProvider.jsx";

const RoleDashboardPage = () => {
    const [roleData, setRoleData] = useState([]);
    const reloadDataContext = useContext(ReloadDataContext);
    const permissionContext = useContext(PermissionContext);
    const { showToast } = useContext(ToastContext);
    const roleModal = useModal();
    const { selectedRole, setSelectedRole } = permissionContext;
    const { setAllPermissions } = permissionContext;

    const [notificationState, setNotificationState] = useState({
        visible: false,
        message: "",
        type: "",
    });

    const displayNotification = (message, type) => {
        showToast(message, type);
        setTimeout(
            () =>
                setNotificationState({ visible: false, message: "", type: "" }),
            3000
        );
    };

    const { permissionGroups, getAssignedPermissionsCountForGroup } =
        permissionContext;

    const handleRoleChange = (event) => {
        if (!event.target.value) {
            setSelectedRole(undefined);
            return;
        }

        setSelectedRole(event.target.value);
    };

    useEffect(() => {
        axios
            .get("/Authentication/Role/GetAll")
            .then((response) => {
                setRoleData(response.data.data);
                if (selectedRole?.id) {
                    const updatedRole = response.data.data.find(
                        (x) => x.id === selectedRole.id
                    );
                    setSelectedRole(updatedRole);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [reloadDataContext.reloadData]);

    const deleteRole = () => {
        axios
            .delete(`/Authentication/Role/Delete?id=${selectedRole.id}`)
            .then((res) => {
                if (res.status === 204) {
                    reloadDataContext.triggerReload();
                    setSelectedRole(null);
                    showToast("Role deleted successfully!", "success");
                }
            });
    };

    const savePermissions = async () => {
        try {
            let assigned = [];
            // Loop through each groupName in permissionListBoxState
            for (const groupName in permissionContext.permissionListBoxState) {
                const group =
                    permissionContext.permissionListBoxState[groupName]; // Get the group object for the current groupName
                // Concatenate the 'assigned' array with the 'assigned' array of the current group
                assigned = assigned.concat(group.assigned);
            }

            const permissionIds = assigned.map((item) => item.id);
            const roleId = selectedRole.id;
            // Use updatePermissionsRole function to save the changes
            const serviceResponse = await updatePermissionsRole(
                roleId,
                permissionIds
            );

            // Handle the service response as needed
            if (serviceResponse.success) {
                roleData.rolePermissions = assigned;
            } else {
                console.error(
                    "Error saving permissions:",
                    serviceResponse.error
                );
            }
            // Handle the API response as needed (e.g., show a success message)
            roleData.rolePermissions = assigned;
            reloadDataContext.triggerReload();
            displayNotification("Permissions saved successfully!", "success");
        } catch (error) {
            console.error("Error saving permissions:", error);
            displayNotification(
                error.message || "Failed to save permissions.",
                "error"
            );
        }
    };
    useEffect(() => {
        getAllPermissions()
            .then((serviceResponse) => {
                setAllPermissions(serviceResponse.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <RoleModal {...roleModal} />
            <span
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                }}
            >
                <span
                    style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                        gap: "5px",
                    }}
                >
                    <ActionButton
                        requiredPermissions={[PermissionsEnum.CreateRole]}
                        icon={"k-icon k-i-plus"}
                        onClick={() => roleModal.open()}
                    >
                        Create
                    </ActionButton>
                    {/* DEFAULT ROLE MODAL */}
                    <DefaultRoleModal />
                    {selectedRole && (
                        <>
                            <ActionButton
                                text={"Edit"}
                                theme={"primary"}
                                onClick={() => roleModal.open(selectedRole.id)}
                                requiredPermissions={[
                                    PermissionsEnum.UpdateRole,
                                ]}
                                icon={"k-icon k-i-pencil"}
                            />
                            <ActionButton
                                text={"Duplicate"}
                                theme={"primary"}
                                onClick={() => {
                                    new RoleService()
                                        .duplicate(selectedRole.id)
                                        .then((res) => {
                                            reloadDataContext.triggerReload();
                                            setSelectedRole(res.data);
                                        });
                                }}
                                requiredPermissions={[
                                    PermissionsEnum.CreateRole,
                                ]}
                                icon={"k-icon k-i-copy"}
                                confirmationState={{
                                    needsConfirmation: true,
                                    confirmationText: `Are you sure you want to duplicate ${selectedRole?.name}?`,
                                }}
                            />
                        </>
                    )}
                </span>
                <div className="role-dropdown">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            marginLeft: "5px",
                        }}
                    >
                        <DropDownList
                            dataItemKey="id"
                            textField="name"
                            data={roleData}
                            onChange={handleRoleChange}
                            value={selectedRole ?? " "}
                            label={"Select a Role:"}
                            style={{
                                width: "180px",
                                marginTop: "5px",
                            }}
                        />
                        {selectedRole && (
                            // Conditionally render the buttons when selectedRole is not null
                            <div
                                style={{
                                    paddingTop: "25px",
                                    paddingLeft: "10px",
                                }}
                            >
                                <ActionButton
                                    text={"Delete"}
                                    themeColor={"error"}
                                    onClick={() => deleteRole()}
                                    requiredPermissions={[
                                        PermissionsEnum.DeleteRole,
                                    ]}
                                    icon={"k-icon k-i-trash"}
                                    confirmationState={{
                                        needsConfirmation: true,
                                        confirmationText: `Are you sure you want to delete ${selectedRole?.name}?`,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div></div>
            </span>
            {selectedRole && (
                <div className="panelbar-wrapper">
                    <div className="save-button-container">
                        <ActionButton
                            requiredPermissions={[
                                PermissionsEnum.UpdatePermission,
                            ]}
                            onClick={savePermissions}
                            style={{ margin: "10px" }}
                        >
                            Save Permissions
                        </ActionButton>
                    </div>
                    <PanelBar expandMode={"single"}>
                        {permissionGroups.map((pg, i) => {
                            const assignedPermissionsCount =
                                getAssignedPermissionsCountForGroup(pg.name);

                            const panelBarItemTitle = `${pg.title} (${assignedPermissionsCount} assigned)`;
                            return (
                                <PanelBarItem key={i} title={panelBarItemTitle}>
                                    <PermissionListBox
                                        assignedPermissionsForRole={
                                            selectedRole
                                        }
                                        desiredPermissionGroupIds={
                                            pg.permissionGroupIds
                                        }
                                        permissionGroupHeader={pg.name}
                                    />
                                </PanelBarItem>
                            );
                        })}
                    </PanelBar>
                </div>
            )}
            {selectedRole && (
                <div className="second-save-button-container">
                    <ActionButton
                        requiredPermissions={[PermissionsEnum.UpdatePermission]}
                        onClick={savePermissions}
                        style={{ margin: "10px" }}
                    >
                        Save Permissions
                    </ActionButton>
                </div>
            )}
            {notificationState.visible && (
                <NotificationGroup
                    style={{
                        right: 5,
                        bottom: 5,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }}
                >
                    <Notification
                        type={{ style: "success", icon: true }}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "1rem",
                            width: "auto",
                            height: "40px",
                            fontSize: "1rem",
                        }}
                        closable={true}
                        onClose={() =>
                            setNotificationState({
                                visible: false,
                                message: "",
                                type: "",
                            })
                        }
                    >
                        <span>{notificationState.message}</span>
                    </Notification>
                </NotificationGroup>
            )}
        </div>
    );
};

export default RoleDashboardPage;
