import React, { useContext } from "react";
import { TabStripTab } from "@progress/kendo-react-layout";
import JobDetails from "../../Jobs/Components/JobDetails/JobDetails.jsx";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider.jsx";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider.jsx";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";

export const EventTabs = () => {
    const { customer } = useContext(CustomerContext);
    const { job } = useContext(JobContext);

    return (
        <HighlightedTabStrip className="tabs" style={{ height: "350px" }}>
            <TabStripTab title="Details">
                <JobDetails
                    customer={customer}
                    job={job}
                    includeJobContactInformation={true}
                />
            </TabStripTab>
        </HighlightedTabStrip>
    );
};
