import React from "react";
import { Card, TabStripTab } from "@progress/kendo-react-layout";
import { ContentHeader } from "../../../../components/ContentHeader";
import PayrollIntervalDashboardPage from "./Payroll/PayrollIntervalDashboardPage";
import MaritalStatusDashboardPage from "./MaritalStatus/MaritalStatusDashboardPage";
import LocationDashboardPage from "./Location/LocationDashboardPage";
import LaborTypeDashboardPage from "./LaborType/LaborTypeDashboardPage";
import IdentificationTypeDashboardPage from "./IdentificationType/IdentificationTypeDashboardPage";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";
import PermissionsHelper from "../../../../resources/PermissionsHelper.js";
import { useUserPermissions } from "../../../../hooks/useUserPermissions.js";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";

const EmployeeTabPage = () => {
    const userPermissions = useUserPermissions();
    const hasPermissionToView = (requiredPermissions, optionalPermissions) => {
        return PermissionsHelper.hasPermission(
            userPermissions,
            requiredPermissions,
            optionalPermissions
        );
    };

    return (
        <div>
            <Card style={{ border: "none" }}>
                <ContentHeader
                    title="Employee Module Configuration"
                    className={"AlignTextLeft"}
                />
                <br />
                <HighlightedTabStrip className="tabs">
                    {hasPermissionToView([PermissionsEnum.GeneratePayroll]) && (
                        <TabStripTab title="Payroll">
                            <PayrollIntervalDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([
                        PermissionsEnum.ViewIdentificationType,
                    ]) && (
                        <TabStripTab title="Identification Types">
                            <IdentificationTypeDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([PermissionsEnum.ViewLaborType]) && (
                        <TabStripTab title="Labor Types">
                            <LaborTypeDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([PermissionsEnum.ViewLocation]) && (
                        <TabStripTab title="Locations">
                            <LocationDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([
                        PermissionsEnum.ViewMaritalStatus,
                    ]) && (
                        <TabStripTab title="Marital Statuses">
                            <MaritalStatusDashboardPage />
                        </TabStripTab>
                    )}
                </HighlightedTabStrip>
            </Card>
        </div>
    );
};

export default EmployeeTabPage;
