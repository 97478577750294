import React, { useEffect, useState } from "react";
import Header from "./Deprecated/Header";
import {
    Drawer,
    DrawerContent,
    DrawerItem,
} from "@progress/kendo-react-layout";
import { withRouter } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import PermissionsHelper from "../resources/PermissionsHelper.js";
import { useUserPermissions } from "../hooks/useUserPermissions.js";

//* Update navigation items here! Follow the nested structure & try not
//* to worry about what's goin on to make it work.
const navigationItems = [
    {
        text: "Home",
        icon: "k-i-grid",
        route: "/",
    },
    {
        separator: true,
    },
    {
        text: "Admin",
        icon: "k-i-lock",
        optionalPermissions: [
            ...PermissionsHelper.NAV_USER_REQUIRED_PERMISSIONS,
            ...PermissionsHelper.NAV_ROLE_REQUIRED_PERMISSIONS,
            ...PermissionsHelper.NAV_CUSTOMER_CONFIG_OPTIONAL_PERMISSIONS,
            ...PermissionsHelper.NAV_EMPLOYEE_CONFIG_OPTIONAL_PERMISSIONS,
            ...PermissionsHelper.NAV_INVENTORY_CONFIG_OPTIONAL_PERMISSIONS,
            ...PermissionsHelper.NAV_GENERAL_CONFIG_OPTIONAL_PERMISSIONS,
        ],
        subItems: [
            {
                text: "Security",
                icon: "k-i-folder",
                optionalPermissions: [
                    ...PermissionsHelper.NAV_USER_REQUIRED_PERMISSIONS,
                    ...PermissionsHelper.NAV_ROLE_REQUIRED_PERMISSIONS,
                ],
                subItems: [
                    {
                        text: "Users",
                        icon: "k-i-minus",
                        route: "/Admin/Security/Users",
                        requiredPermissions:
                            PermissionsHelper.NAV_USER_REQUIRED_PERMISSIONS,
                    },
                    {
                        text: "Roles",
                        icon: "k-i-minus",
                        route: "/Admin/Security/Roles",
                        requiredPermissions:
                            PermissionsHelper.NAV_ROLE_REQUIRED_PERMISSIONS,
                    },
                ],
            },
            { separator: true },
            {
                text: "Configuration",
                icon: "k-i-folder",
                optionalPermissions: [
                    ...PermissionsHelper.NAV_CUSTOMER_CONFIG_OPTIONAL_PERMISSIONS,
                    ...PermissionsHelper.NAV_EMPLOYEE_CONFIG_OPTIONAL_PERMISSIONS,
                    ...PermissionsHelper.NAV_INVENTORY_CONFIG_OPTIONAL_PERMISSIONS,
                    ...PermissionsHelper.NAV_GENERAL_CONFIG_OPTIONAL_PERMISSIONS,
                ],
                subItems: [
                    {
                        text: "Customer Module",
                        icon: "k-i-folder",
                        route: "/Admin/Configuration/Customer",
                        optionalPermissions:
                            PermissionsHelper.NAV_CUSTOMER_CONFIG_OPTIONAL_PERMISSIONS,
                    },
                    {
                        separator: true,
                    },
                    {
                        text: "Employee Module",
                        icon: "k-i-folder",
                        route: "/Admin/Configuration/Employee",
                        optionalPermissions:
                            PermissionsHelper.NAV_EMPLOYEE_CONFIG_OPTIONAL_PERMISSIONS,
                    },
                    {
                        separator: true,
                    },
                    {
                        text: "Inventory Module",
                        icon: "k-i-folder",
                        route: "/Admin/Configuration/Inventory",
                        optionalPermissions:
                            PermissionsHelper.NAV_INVENTORY_CONFIG_OPTIONAL_PERMISSIONS,
                    },
                    {
                        separator: true,
                    },
                    {
                        text: "General",
                        icon: "k-i-folder",
                        route: "/Admin/Configuration/General",
                        optionalPermissions:
                            PermissionsHelper.NAV_GENERAL_CONFIG_OPTIONAL_PERMISSIONS,
                    },
                ],
            },
        ],
    },
    {
        separator: true,
    },
    {
        text: "Accounting",
        icon: "k-i-track-changes",
        optionalPermissions: [
            ...PermissionsHelper.NAV_COA_REQUIRED_PERMISSIONS,
            ...PermissionsHelper.NAV_JOURNAL_REQUIRED_PERMISSIONS,
            ...PermissionsHelper.NAV_BALANCE_SHEET_REQUIRED_PERMISSIONS,
            ...PermissionsHelper.NAV_INCOME_STATEMENT_REQUIRED_PERMISSIONS,
        ],
        subItems: [
            {
                text: "Chart of Accounts",
                icon: "k-i-minus",
                route: "/Accounting/CoA",
                requiredPermissions:
                    PermissionsHelper.NAV_COA_REQUIRED_PERMISSIONS,
            },
            {
                text: "Journal",
                icon: "k-i-minus",
                route: "/Accounting/Journal",
                requiredPermissions:
                    PermissionsHelper.NAV_JOURNAL_REQUIRED_PERMISSIONS,
            },
            {
                text: "Balance Sheet",
                icon: "k-i-minus",
                route: "/Accounting/BalanceSheet",
                requiredPermissions:
                    PermissionsHelper.NAV_BALANCE_SHEET_REQUIRED_PERMISSIONS,
            },
            {
                text: "Income Statement",
                icon: "k-i-minus",
                route: "/Accounting/IncomeStatement",
                requiredPermissions:
                    PermissionsHelper.NAV_INCOME_STATEMENT_REQUIRED_PERMISSIONS,
            },
        ],
    },
    {
        separator: true,
    },
    {
        text: "Employee",
        icon: "k-i-user",
        optionalPermissions: [
            ...PermissionsHelper.NAV_EMPLOYEE_DASHBOARD_REQUIRED_PERMISSIONS,
        ],
        subItems: [
            {
                text: "Employee Dashboard",
                icon: "k-i-minus",
                route: "/HumanResources/Employee",
                requiredPermissions:
                    PermissionsHelper.NAV_EMPLOYEE_DASHBOARD_REQUIRED_PERMISSIONS,
            },
        ],
    },
    {
        separator: true,
    },
    {
        text: "Items",
        icon: "k-i-shopping-cart",
        optionalPermissions: [
            ...PermissionsHelper.NAV_ITEM_CATEGORIES_REQUIRED_PERMISSIONS,
            ...PermissionsHelper.NAV_ALL_ITEMS_REQUIRED_PERMISSIONS,
            ...PermissionsHelper.NAV_INVENTORY_REQUIRED_PERMISSIONS,
        ],
        subItems: [
            {
                text: "Item Categories",
                icon: "k-i-minus",
                route: "/Inventory/Category",
                requiredPermissions:
                    PermissionsHelper.NAV_ITEM_CATEGORIES_REQUIRED_PERMISSIONS,
            },
            {
                text: "All Items",
                icon: "k-i-minus",
                route: "/Inventory/Item",
                requiredPermissions:
                    PermissionsHelper.NAV_ALL_ITEMS_REQUIRED_PERMISSIONS,
            },
            {
                text: "Inventory",
                icon: "k-i-minus",
                route: "/Inventory/Dashboard",
                requiredPermissions:
                    PermissionsHelper.NAV_INVENTORY_REQUIRED_PERMISSIONS,
            },
        ],
    },
    {
        separator: true,
    },
    {
        text: "Customers",
        icon: "k-i-tell-a-friend",
        optionalPermissions: [
            ...PermissionsHelper.NAV_CUSTOMER_DASHBOARD_REQUIRED_PERMISSIONS,
            ...PermissionsHelper.NAV_LEADS_DASHBOARD_REQUIRED_PERMISSIONS,
        ],
        subItems: [
            {
                text: "Customer Dashboard",
                icon: "k-i-minus",
                route: "/customer",
                requiredPermissions:
                    PermissionsHelper.NAV_CUSTOMER_DASHBOARD_REQUIRED_PERMISSIONS,
            },
            {
                text: "Leads Dashboard",
                icon: "k-i-minus",
                route: "/Lead",
                requiredPermissions:
                    PermissionsHelper.NAV_LEADS_DASHBOARD_REQUIRED_PERMISSIONS,
            },
        ],
    },
    {
        separator: true,
    },
    {
        text: "Reporting",
        icon: "k-i-clipboard-text",
        optionalPermissions: [
            ...PermissionsHelper.NAV_PAYROLL_REQUIRED_PERMISSIONS,
        ],
        subItems: [
            {
                text: "ADP Payroll",
                icon: "k-i-minus",
                route: "/Payroll/ADP",
                requiredPermissions:
                    PermissionsHelper.NAV_PAYROLL_REQUIRED_PERMISSIONS,
            },
        ],
    },
    {
        separator: true,
    },
    {
        text: "Vendors",
        icon: "k-i-globe-outline",
        route: "/inventory/vendor",
        requiredPermissions: PermissionsHelper.NAV_VENDORS_REQUIRED_PERMISSIONS,
    },
    {
        separator: true,
    },
    {
        text: "Scheduler",
        icon: "k-i-calendar",
        route: "/scheduler",
        requiredPermissions:
            PermissionsHelper.NAV_SCHEDULER_REQUIRED_PERMISSIONS,
    },
    {
        separator: true,
    },
    {
        text: "My Work",
        icon: "k-i-accessibility",
        route: "/MyWork",
        requiredPermissions: PermissionsHelper.NAV_MY_WORK_REQUIRED_PERMISSIONS,
    },
    { separator: true },
    {
        text: "Time Clock",
        icon: "k-i-clock",
        route: "/HumanResources/TimeClock",
        requiredPermissions:
            PermissionsHelper.NAV_TIME_CLOCK_REQUIRED_PERMISSIONS,
    },
];

// recursive function to add things like IDs to navigation items
// that we don't want to worry about being unique at author time
function enrichNavigationItems(array, parentId = null, nestingLevel = 0) {
    for (let i = 0; i < array.length; i++) {
        // set random UUID for item ID
        array[i].id = array[i].id ?? self.crypto.randomUUID();

        array[i].data = {
            parentId,
            nestingLevel,
            isVisible: nestingLevel === 0,
            isFolder: !!array[i].subItems?.length,
            requiredPermissions: array[i].requiredPermissions,
            optionalPermissions: array[i].optionalPermissions,
            subItems: array[i].subItems,
        };

        delete array[i].subItems;
        delete array[i].requiredPermissions;
        delete array[i].optionalPermissions;

        if (array[i].data.subItems)
            enrichNavigationItems(
                array[i].data.subItems,
                array[i].id,
                nestingLevel + 1
            );
    }
}

enrichNavigationItems(navigationItems);

const flattenDeep = (list) => {
    return list.reduce((accumulator, currentValue) => {
        const flattenedSubArray = Array.isArray(currentValue.data.subItems)
            ? flattenDeep(currentValue.data.subItems)
            : [];
        return accumulator.concat(currentValue, flattenedSubArray);
    }, []);
};

const flattenedNavigationItems = flattenDeep(navigationItems);

const NavigationSideBarItem = (props) => {
    const indentStyle = {
        paddingLeft: `${3 + props.data.nestingLevel * 9}px`, // Adjust the padding based on nesting level
    };

    return (
        <AnimatePresence initial={false}>
            {props.data.isVisible && (
                <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                >
                    <DrawerItem
                        key={props.id}
                        style={{ display: "flex", ...indentStyle }}
                        {...props}
                    >
                        <div className={`k-icon ${props.icon}`} />
                        <div className="k-item-text">{props.text}</div>
                        {props.data.isFolder && (
                            <div style={{ width: "10px" }}>
                                <span
                                    className={`k-icon k-i-arrow-chevron-${
                                        props.data.isExpanded ? "down" : "right"
                                    }`}
                                    style={{
                                        position: "absolute",
                                        right: -5,
                                    }}
                                />
                            </div>
                        )}
                    </DrawerItem>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

const NavigationSideBar = withRouter((props) => {
    const [navItems, setNavItems] = useState(flattenedNavigationItems);
    const [drawerExpanded, setDrawerExpanded] = useState(true);
    const [isMobileView, setIsMobileView] = useState(false);
    const userPermissions = useUserPermissions();

    // handle permission checks for navItems
    useEffect(() => {
        let updatedNavItems = [];
        let firstNestLevelItems = [];
        for (let i = 0; i < navItems.length; i++) {
            const item = navItems[i];
            const hasPermission = PermissionsHelper.hasPermission(
                userPermissions,
                item.data.requiredPermissions,
                item.data.optionalPermissions
            );
            const formattedItem = {
                ...item,
                data: {
                    ...item.data,
                    isVisible: item.data.isVisible && hasPermission,
                },
            };

            if (formattedItem.data.nestingLevel === 0) {
                if (!formattedItem.separator) {
                    firstNestLevelItems.push(formattedItem);
                } else {
                    const lastItem =
                        firstNestLevelItems[firstNestLevelItems.length - 1];

                    if (!lastItem.data.isVisible && formattedItem.separator) {
                        formattedItem.data.isVisible = false;
                    }
                }
            }

            updatedNavItems.push(formattedItem);
        }

        setNavItems(updatedNavItems);
    }, [userPermissions]);

    // expands or retracts the sidebar drawer
    const handleHeaderMenuClick = () => {
        setDrawerExpanded(!drawerExpanded);
    };

    // initial handler for item select, splits between folder or link actions
    const onDrawerItemSelect = (e) => {
        const isFolderClick = !!e.itemTarget.props.data.subItems?.length;

        if (isFolderClick) {
            onDrawerItemFolderSelect(e);
        } else {
            onDrawerItemNavigationSelect(e);
            if (isMobileView) setDrawerExpanded(!drawerExpanded);
        }
    };

    // handles a link sidebar selection, navigates the user to a new page
    const onDrawerItemNavigationSelect = (e) => {
        const item = e.itemTarget.props;
        props.history.push(item.route);
        // following code can set selected item, but doesn't update when
        // the user navigates around the site using related links.
        // Leaving commented out for now, til a better solution can be determined.
        // navItems.forEach((x) => {
        //     x.selected = x.id === item.id;
        // });
        // setNavItems(navItems);
    };

    // handles a folder sidebar selection, hides or shows additional items
    const onDrawerItemFolderSelect = (e) => {
        const clickedDrawerItem = { ...e.itemTarget.props };
        const actionIsOpeningDrawer = !clickedDrawerItem.data.isExpanded;
        const updatedManagedNavigationItems = [...navItems];

        if (actionIsOpeningDrawer) {
            // set the immediate children to visible, set the clicked item to expanded
            updatedManagedNavigationItems
                .filter((x) => x.data.parentId === clickedDrawerItem.id)
                .forEach((x) => {
                    x.data.isVisible = PermissionsHelper.hasPermission(
                        userPermissions,
                        x.data.requiredPermissions,
                        x.data.optionalPermissions
                    );
                });
            updatedManagedNavigationItems
                .filter((x) => x.id === clickedDrawerItem.id)
                .forEach((x) => {
                    x.data.isExpanded = true;
                });
        } else {
            // Set all children & children's children to not visible
            // doesn't need to be recursive because of the implicit hierarchy of the list
            let filterParentIdList = [clickedDrawerItem.id];
            const filterNavigationItemsForAllChildren = (element) => {
                const elementIsChildOfParentIdList = filterParentIdList.some(
                    (x) => x === element.data.parentId
                );
                if (elementIsChildOfParentIdList && element.id) {
                    filterParentIdList.push(element.id);
                }

                return elementIsChildOfParentIdList;
            };

            updatedManagedNavigationItems
                .filter(filterNavigationItemsForAllChildren)
                .forEach((x) => {
                    x.data.isVisible = false;
                    x.data.isExpanded =
                        x.data.isFolder && x.data.isExpanded
                            ? false
                            : undefined;
                });
            updatedManagedNavigationItems
                .filter((x) => x.id === clickedDrawerItem.id)
                .forEach((x) => {
                    x.data.isExpanded = false;
                });
        }
        setNavItems(updatedManagedNavigationItems);
    };

    // Use useEffect to check screen width and update the drawerExpanded state
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 992) {
                setDrawerExpanded(true); // Expand on larger screens
                setIsMobileView(false);
            } else {
                setDrawerExpanded(false); // Collapse on smaller screens
                setIsMobileView(true);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div>
            <Header onMenuClick={handleHeaderMenuClick} />
            <Drawer
                style={{ height: "100%", minHeight: "100vh" }}
                expanded={drawerExpanded}
                mode={isMobileView ? "overlay" : "push"}
                width={235}
                items={navItems}
                item={NavigationSideBarItem}
                onSelect={onDrawerItemSelect}
            >
                <DrawerContent>{props.children}</DrawerContent>
            </Drawer>
        </div>
    );
});

export default withRouter(NavigationSideBar);
