import React, { useContext } from "react";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { PaymentContext } from "../../../../providers/Deprecated/Customer/PaymentProvider";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";

const DisbursementBtn = ({ dataItem }) => {
    const paymentProvider = useContext(PaymentContext);

    return (
        <div>
            <ActionButton
                requiredPermissions={[PermissionsEnum.DisburseCustomerPayment]}
                onClick={() =>
                    paymentProvider.setDisbursingPaymentId(dataItem.id)
                }
            >
                Apply
            </ActionButton>
        </div>
    );
};

export default DisbursementBtn;
