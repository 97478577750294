import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../providers/Deprecated/Authentication/User/AuthProvider";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { getLastTimeClockEntry } from "../../../../services/Deprecated/humanResources/timeClockServices";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import PermissionsHelper from "../../../../resources/PermissionsHelper.js";
import { useUserPermissions } from "../../../../hooks/useUserPermissions.js";

/**
 * @function canUserSearchEmployees
 * @description Func to check if the user has the required permissions to act as a stationary time clock or time clock admin
 * @return {*|boolean}
 */
const canUserSearchEmployees = () => {
    const stationaryPermission = PermissionsHelper.hasPermission(
        useUserPermissions(),
        [PermissionsEnum.CanActAsStationaryTimeClock]
    );

    if (stationaryPermission) {
        return true;
    }

    return PermissionsHelper.hasPermission(useUserPermissions(), [
        PermissionsEnum.TimeClockAdmin,
    ]);
};

/**
 * @function useTimeClockState
 * @description Custom hook to manage the state of the TimeClockPage component
 * @return {Object} employee, setEmployee, isStationary
 */
export function useTimeClockState() {
    const { user } = useContext(AuthContext);
    const { reloadData } = useContext(ReloadDataContext);
    const [employee, setEmployee] = useState(undefined);
    const [isClockedIn, setIsClockedIn] = useState(true);
    const [lastTimeClock, setLastTimeClock] = useState(undefined);
    const canSearchRef = useRef(canUserSearchEmployees());
    const [refreshToken, setRefreshToken] = useState(false);

    // Set Employee to Users current if this is NOT stationary
    useEffect(() => {
        if (!canSearchRef.current) {
            setEmployee(user.activeEmployee);
        }
    }, [canSearchRef.current]);

    // Get last time clock entry if an Employee exists
    useEffect(() => {
        if (employee) {
            getLastTimeClockEntry(employee.id).then((res) => {
                if (res) {
                    setLastTimeClock(res);
                    setIsClockedIn(!res.endTime);
                } else {
                    setLastTimeClock(undefined);
                    setIsClockedIn(false);
                }
            });
        }
    }, [employee, refreshToken, reloadData]);

    const refreshLastTc = () => {
        setRefreshToken(!refreshToken);
    };

    return {
        isClockedIn,
        employee,
        setEmployee,
        canSearchRef,
        refreshLastTc,
        lastTimeClock,
    };
}
