import * as React from "react";
import { useContext, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { searchVendor } from "../../../../services/Deprecated/inventory/vendorService";
import { FormInput } from "../../../../components/Deprecated/FormComponents";
import { BreadcrumbContext } from "../../../../providers/BreadCrumbProvider";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";

const PreCreateVendorSearchModal = (props) => {
    const { close, visible, setFormState, toggleCreateVisible } = props;
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [modalState, setModalState] = useState({});
    const [existingVendors, setExistingVendors] = useState([]);
    const { handleRouteChange } = useContext(BreadcrumbContext);

    const onSubmit = (props) => {
        if (!props.name) {
            throw new ReferenceError("Name is not defined.");
        }
        setLoaderVisible(true);
        setModalState(props);

        searchVendor(`${props.name}`).then((res) => {
            if (res.data.length > 0) {
                setLoaderVisible(false);
                setExistingVendors(res.data);
                return;
            }

            // Set the Vendor create forms name to the searched props for ease of use, and open the form
            setFormState({
                name: props.name,
            });
            toggleCreateVisible();
            setLoaderVisible(false);
            close();
        });
    };

    const onContinue = () => {
        setFormState(modalState);
        close();
        toggleCreateVisible();
    };

    return (
        visible && (
            <ResponsiveDialog
                title={"Search for existing Vendor"}
                onClose={() => {
                    // Reset state, close the modal
                    setModalState({});
                    setExistingVendors([]);
                    close();
                }}
                size={"small"}
            >
                <Form
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <Field
                                name={"name"}
                                component={FormInput}
                                label={"Vendor Name:"}
                            />
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={() => close()}
                                allowSubmit={formRenderProps.allowSubmit}
                                text={"Search"}
                                theme={"primary"}
                                icon={"k-i-icon k-i-search"}
                            />
                            {existingVendors.length > 0 && (
                                <>
                                    <hr className={"Separator"} />
                                    <span
                                        className={
                                            "JustifyCenterAlignLeft FlexColumn"
                                        }
                                    >
                                        <p>
                                            You can select a vendor below or
                                            continue to{" "}
                                            <a
                                                onClick={() => onContinue()}
                                                className={"link"}
                                            >
                                                enter a new vendor
                                            </a>
                                        </p>
                                        {existingVendors.map((vendor, key) => (
                                            <li key={key}>
                                                <a
                                                    onClick={() =>
                                                        handleRouteChange({
                                                            uri: `/inventory/vendor/${vendor.id}/`,
                                                            breadcrumbInfo: [
                                                                {
                                                                    id: vendor.id,
                                                                    text: vendor.name,
                                                                },
                                                            ],
                                                        })
                                                    }
                                                    className={"link"}
                                                >
                                                    {vendor.name}
                                                </a>
                                            </li>
                                        ))}
                                    </span>
                                </>
                            )}
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};

export default PreCreateVendorSearchModal;
