import React from "react";
import {
    customDateFormatter,
    utcToLocalFormatter,
} from "../../resources/Deprecated/stringFormatter";
import { addressFormatter } from "../../resources/Deprecated/helpers";

export const CustomCell = (props) => {
    return (
        <td {...props.tdProps} style={props.style} colSpan={props.colSpan ?? 1}>
            {props.children}
        </td>
    );
};

export const DisplayDocumentNumberCell = (props) => {
    const { dataItem } = props;
    return (
        <CustomCell style={{ fontWeight: "bold" }}>
            #{dataItem.documentNumber}
        </CustomCell>
    );
};

export const CombinedAddressCell = (props) => {
    const { dataItem } = props;
    return <CustomCell>{addressFormatter(dataItem)}</CustomCell>;
};

export const CustomDateCell = (props) => {
    return (
        <CustomCell {...props}>{customDateFormatter(props.data)}</CustomCell>
    );
};

export const CustomAppointmentTimeCell = (props) => {
    const dataItem = props.dataItem;
    const appointmentTime = dataItem.appointmentTime
        ? `${utcToLocalFormatter(
              dataItem.appointmentTime.startTime,
              "h:mm A"
          )} - ${utcToLocalFormatter(
              dataItem.appointmentTime.endTime,
              "h:mm A"
          )}`
        : "";
    return <CustomCell {...props}>{appointmentTime}</CustomCell>;
};

export const YesNoBooleanCell = (props) => (
    <CustomBooleanCell {...props} trueText={"Yes"} falseText={"No"} />
);

export const CustomBooleanCell = ({ trueText, falseText, ...props }) => {
    const boolValue = props.dataItem[props.field];
    return (
        <CustomCell {...props}>{boolValue ? trueText : falseText}</CustomCell>
    );
};
