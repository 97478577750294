import React, { useState } from "react";
import styles from "../Scheduler.module.scss";
import { ButtonGroup } from "@progress/kendo-react-buttons";
import dayjs from "dayjs";
import { SchedulerWindowEnum } from "../../../../resources/Enums/SchedulerWindowEnum.js";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { ActionButtonContainer } from "../../../../components/Buttons/ActionButtonContainer.jsx";

const SchedulerWindowButton = ({
    numOfDays,
    schedulerWindow,
    label,
    onSchedulerWindowChange,
}) => {
    return (
        <ActionButton
            themeColor={numOfDays === schedulerWindow ? "primary" : "base"}
            disabled={numOfDays === schedulerWindow}
            onClick={() => onSchedulerWindowChange(schedulerWindow)}
        >
            {label}
        </ActionButton>
    );
};

export const SchedulerToolbar = ({
    startDate,
    onDateChange,
    onSchedulerWindowChange,
    numOfDays = 7,
}) => {
    const startDateJs = dayjs(startDate);
    const endDateJs = dayjs(startDate).add(numOfDays - 1, "days");
    const [choosingDate, setChoosingDate] = useState(false);

    const onTodayClick = () => {
        onDateChange(dayjs().startOf("week").add(1, "day").toDate());
    };
    const onPrevWeekClick = () => {
        onDateChange(startDateJs.subtract(7, "days").toDate());
    };
    const onNextWeekClick = () => {
        onDateChange(startDateJs.add(7, "days").toDate());
    };

    const onCustomDateChange = (e) => {
        const value = e.value;
        onDateChange(value);
        setChoosingDate(false);
    };

    return (
        <div className={styles.toolbarContainer}>
            <div className={styles.toolbarSubContainer}>
                <ActionButton onClick={onTodayClick}>Today</ActionButton>
                <ActionButtonContainer>
                    <ActionButton onClick={onPrevWeekClick}>◀</ActionButton>
                    <ActionButton onClick={onNextWeekClick}>▶</ActionButton>
                </ActionButtonContainer>
                {choosingDate ? (
                    <span>
                        <DatePicker
                            onChange={onCustomDateChange}
                            show={true}
                            defaultValue={startDateJs.toDate()}
                        />
                    </span>
                ) : (
                    <span
                        style={{ fontSize: 16, color: "#666666" }}
                        className={`text-overflow Link`}
                        onClick={() => setChoosingDate(true)}
                    >
                        <i
                            className={"k-icon k-i-pencil"}
                            style={{ marginBottom: 4 }}
                        />{" "}
                        {startDateJs.format("ddd MM/DD")}
                        {" - "}
                        {endDateJs.format("ddd MM/DD")}
                    </span>
                )}
            </div>
            <div className={styles.toolbarSubContainer}>
                <ButtonGroup>
                    <SchedulerWindowButton
                        numOfDays={numOfDays}
                        schedulerWindow={SchedulerWindowEnum.Enum.WorkWeek}
                        onSchedulerWindowChange={onSchedulerWindowChange}
                        label={"Work Week"}
                    />
                    <SchedulerWindowButton
                        numOfDays={numOfDays}
                        schedulerWindow={SchedulerWindowEnum.Enum.SixDayWeek}
                        onSchedulerWindowChange={onSchedulerWindowChange}
                        label={"Six Day Week"}
                    />
                    <SchedulerWindowButton
                        numOfDays={numOfDays}
                        schedulerWindow={SchedulerWindowEnum.Enum.FullWeek}
                        onSchedulerWindowChange={onSchedulerWindowChange}
                        label={"Full Week"}
                    />
                    <SchedulerWindowButton
                        numOfDays={numOfDays}
                        schedulerWindow={SchedulerWindowEnum.Enum.TwoWeek}
                        onSchedulerWindowChange={onSchedulerWindowChange}
                        label={"Two Weeks"}
                    />
                </ButtonGroup>
            </div>
        </div>
    );
};
