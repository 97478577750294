import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider";
import {
    FormDropDown,
    FormInput,
    FormNumericInput,
} from "../../../../../../components/Deprecated/FormComponents";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper";
import {
    createEventType,
    getEventType,
    updateEventType,
} from "../../../../../../services/Deprecated/calendar/eventTypeServices";
import { Checkbox, ColorPicker } from "@progress/kendo-react-inputs";
import clone from "just-clone";
import { useAccounts } from "../../../../../../hooks/Deprecated/useAccounts.js";
import { AccountDropdownItemRenderer } from "../../../../../../components/DropDownRenders/AccountDropDownRender.jsx";

export const PaletteSettings = {
    palette: [
        "#ff3001",
        "#ff680f",
        "#fff200",
        "#76ff22",
        "#2ae5d6",
        "#2943fa",
        "#cd43f3",
        "#f827a3",
        "#8f8787",
        "rgba(93,64,4,0.73)",
    ],
    columns: 5,
    tileSize: 30,
};

export const EventTypeModal = (props) => {
    const { id, isUpdate, close, visible } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [isAssociatedToCoA, setIsAssociatedToCoA] = useState(false);
    const [colorValue, setColorValue] = useState(PaletteSettings.palette[0]);
    const { expenseAccounts, incomeAccounts } = useAccounts();

    useEffect(() => {
        if (!visible) return;

        if (id) {
            getEventType(id).then((res) => {
                setInitialFormData(res.data);
                setIsAssociatedToCoA(res.data.isAssociatedToCoA);
                setColorValue(res.data.calendarColor);
            });
        } else {
            setInitialFormData({});
        }
    }, [visible, id]);

    const onClose = () => {
        setInitialFormData(undefined);
        setIsAssociatedToCoA(false);
        setColorValue(PaletteSettings.palette[0]);
        close();
    };

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        const data = clone(dataItem);

        data.expenseAccountId = dataItem.expenseAccount?.id;
        data.incomeAccountId = dataItem.incomeAccount?.id;
        data.calendarColor = colorValue;

        delete data.expenseAccount;
        delete data.incomeAccount;

        if (isUpdate) {
            updateEventType(data).then(() => {
                triggerReload();
                setLoaderVisible(false);
                onClose();
            });
        } else {
            createEventType(data).then(() => {
                triggerReload();
                setLoaderVisible(false);
                onClose();
            });
        }
    };

    const handleCoAChange = (formRenderProps, e) => {
        setIsAssociatedToCoA(e.value);
        formRenderProps.onChange("expenseAccount", { value: undefined });
        formRenderProps.onChange("incomeAccount", { value: undefined });
    };

    const handleColorChange = (event) => {
        setColorValue(event.value);
    };

    const coaRequiredValidator = (value) => {
        if (isAssociatedToCoA && !value) {
            return "Account is required.";
        }

        return "";
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={isUpdate ? "Edit Event Type" : "Add Event Type"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div style={{ width: "100%" }}>
                                <Field
                                    name="index"
                                    component={FormNumericInput}
                                    label="Position On Scheduler"
                                    validator={requiredValidator}
                                    min={1}
                                />
                                <Field
                                    name="name"
                                    component={FormInput}
                                    label="Name"
                                    validator={requiredValidator}
                                />
                                <br />
                                <span className={"JustifyLeftAlignCenter"}>
                                    <Field
                                        name={"isAssociatedToCoA"}
                                        component={Checkbox}
                                        label={"Is Associated To CoA"}
                                        rounded={"large"}
                                        size={"medium"}
                                        onChange={(e) =>
                                            handleCoAChange(formRenderProps, e)
                                        }
                                    />
                                </span>
                                <Field
                                    name={"expenseAccount"}
                                    component={FormDropDown}
                                    data={expenseAccounts}
                                    dataItemKey="id"
                                    textField="name"
                                    validator={coaRequiredValidator}
                                    label={"Expense Account:"}
                                    disabled={!isAssociatedToCoA}
                                    itemRender={AccountDropdownItemRenderer}
                                />
                                <Field
                                    name={"incomeAccount"}
                                    component={FormDropDown}
                                    data={incomeAccounts}
                                    dataItemKey="id"
                                    textField="name"
                                    validator={coaRequiredValidator}
                                    label={"Income Account:"}
                                    disabled={!isAssociatedToCoA}
                                    itemRender={AccountDropdownItemRenderer}
                                />
                                <br />
                                <div
                                    className={
                                        "JustifyLeftAlignCenter LargeGap"
                                    }
                                >
                                    <div>Scheduler Display Color:</div>
                                    <Field
                                        name={"calendarColor"}
                                        component={ColorPicker}
                                        value={colorValue}
                                        onChange={handleColorChange}
                                        palette={PaletteSettings.palette}
                                        columns={PaletteSettings.columns}
                                        tileSize={PaletteSettings.tileSize}
                                        validator={requiredValidator}
                                    />
                                </div>
                                <FormButtons
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={onClose}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    isCreate={!isUpdate}
                                />
                            </div>
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
