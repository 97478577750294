import React, { useContext, useEffect, useState } from "react";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { Card } from "@progress/kendo-react-layout";
import { InventoryModal } from "../../../Inventory/Components/InventoryModal";
import { useModal } from "../../../../../hooks/useModal";
import { InventoryItemVendorSelectModal } from "../InventoryItemVendorSelectModal";
import { BreadcrumbContext } from "../../../../../providers/BreadCrumbProvider";
import ItemService from "../../../../../services/ItemService";
import { formatUSDCurrency } from "../../../../../resources/Deprecated/currencyHelper";
import "./ItemInventoryInformation.module.scss";

/**
 * @function ItemInventoryInformation
 * @param props.item {Object} - The item object.
 * @return {Element}
 * @constructor
 */
export const ItemInventoryInformation = (props) => {
    const { item } = props;
    const { handleRouteChange } = useContext(BreadcrumbContext);
    const invModal = useModal();
    const preInvModal = useModal();
    const [invInfo, setInvInfo] = useState(undefined);

    useEffect(() => {
        if (!item.id) return;

        // Fetch inventory information
        const itemService = new ItemService("Inventory/Item");
        itemService.getInventoryInformation(item.id).then((res) => {
            setInvInfo({
                currentStock: res.data.currentStock.toFixed(2) ?? 0,
                totalCost: res.data.totalCost ?? { amount: 0 },
                averageCost: res.data.averageCost ?? { amount: 0 },
                expectedMarginCost: res.data.expectedMarginCost ?? 0,
                expectedMarginPercentage:
                    res.data.expectedMarginPercentage.toFixed(2) ?? 0,
            });
        });
    }, [item]);

    return (
        invInfo && (
            <Card
                id={"ItemInventoryInformation"}
                style={{
                    width: 450,
                }}
            >
                <span
                    className={"JustifyLeftAlignLeft SmallGap"}
                    style={{
                        padding: 10,
                    }}
                >
                    <InventoryItemVendorSelectModal
                        {...preInvModal}
                        invModal={invModal}
                    />
                    <InventoryModal {...invModal} />
                    <ActionButton
                        text={"View on Inventory Page"}
                        onClick={() =>
                            handleRouteChange({
                                uri: `/Inventory/Dashboard/Item/${item.id}`,
                                breadcrumbInfo: [
                                    {
                                        id: item.id,
                                        text: item.name,
                                    },
                                ],
                            })
                        }
                    />
                    <ActionButton
                        text={"Add Stock"}
                        icon={"k-icon k-i-plus"}
                        onClick={() =>
                            preInvModal.open(undefined, {
                                // This record is passed to the invModal
                                hideItem: true,
                                hideVendor: true,
                                item: { item: item }, // Formatted to match the expected shape of the item object. (VendorItem)
                                itemType: item.itemType,
                            })
                        }
                    />
                </span>
                <hr className={"Separator"} />
                <table>
                    <tbody>
                        <tr>
                            <td
                                style={{ textAlign: "left" }}
                                className={"bold"}
                            >
                                Current Stock
                            </td>
                            <td style={{ textAlign: "left" }}>
                                {`${invInfo.currentStock} ${
                                    item?.itemType?.name === "Slab"
                                        ? "Sqft"
                                        : "Pcs"
                                }`}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{ textAlign: "left" }}
                                className={"bold"}
                            >
                                Total Cost
                            </td>
                            <td style={{ textAlign: "left" }}>
                                {formatUSDCurrency(invInfo.totalCost.amount)}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{ textAlign: "left" }}
                                className={"bold"}
                            >
                                Average Cost
                            </td>
                            <td style={{ textAlign: "left" }}>
                                {formatUSDCurrency(invInfo.averageCost.amount)}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{ textAlign: "left" }}
                                className={"bold"}
                            >
                                Expected Margin
                            </td>
                            <td
                                style={{ textAlign: "left" }}
                            >{`${formatUSDCurrency(
                                invInfo.expectedMarginCost
                            )} - (${invInfo.expectedMarginPercentage}%)`}</td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        )
    );
};
