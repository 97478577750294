export default class ItemProcessor {
    /**
     * @function formatQuantityForDisplay
     * @param uom {object} - The unit of measure object
     * @param quantity {number} - The quantity of the item
     * @param type {string} - Determines what display logic to use based on type, defaults to "Input"
     * @return {string}
     */
    static formatQuantityForDisplay(uom, quantity = 1, type = "Input") {
        if (!uom?.name) return "";

        switch (type) {
            case "Input":
                return `#### ${uom.name}`;
            case "Grid":
                return `${quantity} ${uom.name}`;
        }
    }
}
