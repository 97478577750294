import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class InventoryService {
    static async get(inventoryId) {
        const response = await axios.get(
            `/Inventory/Inventory/Get?id=${inventoryId}`
        );
        return formatServiceResponse(response);
    }

    static async create(inventoryData) {
        const response = await axios.post(
            "/Inventory/Inventory/Create",
            inventoryData
        );
        return formatServiceResponse(response);
    }

    static async update(inventoryData) {
        const response = await axios.put(
            "/Inventory/Inventory/Update",
            inventoryData
        );
        return formatServiceResponse(response);
    }

    static async delete(inventoryId) {
        const response = await axios.delete(
            `/Inventory/Inventory/Delete?id=${inventoryId}`
        );
        return formatServiceResponse(response);
    }

    static async allocate(data) {
        const response = await axios.put("/Inventory/Inventory/Allocate", data);
        return formatServiceResponse(response);
    }

    static async deallocate(data) {
        const response = await axios.put(
            "/Inventory/Inventory/UnAllocate",
            data
        );
        return formatServiceResponse(response);
    }

    static async partialDeAllocate(data) {
        const response = await axios.put(
            "/Inventory/Inventory/PartialUnAllocate",
            data
        );
        return formatServiceResponse(response);
    }
}
