import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { deleteIdentificationType } from "../../../../../services/Deprecated/humanResources/identificationTypeServices";
import { IdentificationTypeModal } from "./Components/IdentificationTypeModal";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const IdentificationTypeDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const modal = useModal();

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <IdentificationTypeModal {...modal} />
            <ActionButton
                onClick={() => modal.open()}
                text={"Add IdentificationType"}
                icon={"k-icon k-i-plus"}
                requiredPermissions={[PermissionsEnum.CreateIdentificationType]}
            />
            <DataGrid
                uri={"/HumanResources/IdentificationType/GetAll?"}
                style={{ maxWidth: "420px" }}
            >
                <GridColumn field="name" title="Name:" width={"250px"} />
                <GridColumn
                    width={150}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() =>
                                deleteIdentificationType(
                                    props.dataItem.id
                                ).then(() => reloadDataContext.triggerReload())
                            }
                            onEdit={() => modal.open(props.dataItem.id)}
                            permissions={{
                                edit: [
                                    PermissionsEnum.UpdateIdentificationType,
                                ],
                                delete: [
                                    PermissionsEnum.DeleteIdentificationType,
                                ],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
export default IdentificationTypeDashboardPage;
