import React from "react";
import { Card, TabStripTab } from "@progress/kendo-react-layout";
import PhoneTypeDashboardPage from "./PhoneTypes/PhoneTypeDashboardPage";
import { ContentHeader } from "../../../../components/ContentHeader";
import PaymentTypeDashboardPage from "./PaymentTypes/PaymentTypeDashboardPage";
import SocialMediaDashboardPage from "./SocialMedias/SocialMediaDashboardPage";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";
import { useUserPermissions } from "../../../../hooks/useUserPermissions.js";
import PermissionsHelper from "../../../../resources/PermissionsHelper.js";

const GeneralTabPage = () => {
    const userPermissions = useUserPermissions();
    const hasPermissionToView = (requiredPermissions, optionalPermissions) => {
        return PermissionsHelper.hasPermission(
            userPermissions,
            requiredPermissions,
            optionalPermissions
        );
    };

    return (
        <div>
            <Card style={{ border: "none" }}>
                <ContentHeader
                    title="General Configuration"
                    className={"AlignTextLeft"}
                />
                <br />
                <HighlightedTabStrip className="tabs">
                    {hasPermissionToView([PermissionsEnum.ViewPhoneType]) && (
                        <TabStripTab title="Phone Type">
                            <PhoneTypeDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([PermissionsEnum.ViewPaymentType]) && (
                        <TabStripTab title="Payment Types">
                            <PaymentTypeDashboardPage />
                        </TabStripTab>
                    )}
                    {hasPermissionToView([PermissionsEnum.ViewSocialMedia]) && (
                        <TabStripTab title="Social Medias">
                            <SocialMediaDashboardPage />
                        </TabStripTab>
                    )}
                </HighlightedTabStrip>
            </Card>
        </div>
    );
};

export default GeneralTabPage;
