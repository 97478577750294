export default class NumberProcessor {
    /**
     * @function formatQuantityDisplay
     * @param number {number}
     * @param decimalPlaces {number} - Default value is 2
     * @return {float}
     */
    static round(number, decimalPlaces = 2) {
        return parseFloat(parseFloat(number).toFixed(decimalPlaces));
    }
}
