import React, { useContext } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import { updateEventNote } from "../../../../services/Deprecated/customer/eventServices";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormTextArea } from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";

const EventNoteEdit = ({
    visible,
    onCancel,
    record,
    loaderVisible,
    toggleLoaderVisible,
    triggerReload,
}) => {
    const eventContext = useContext(EventContext);

    const submitEventNote = (dataItem) => {
        toggleLoaderVisible();

        dataItem.calendarEventId = eventContext.event.id;

        updateEventNote(dataItem)
            .then(() => {
                triggerReload(dataItem);
                onCancel();
            })
            .catch((error) => {
                console.error("Error Message: ", error.message);
                if (error.response) {
                    console.error("Response Data: ", error.response.data);
                    console.error("Response Status: ", error.response.status);
                    console.error("Response Headers: ", error.response.headers);
                }
            })
            .finally(() => {
                toggleLoaderVisible();
            });
    };

    return (
        <div>
            {visible && (
                <Form
                    initialValues={record}
                    onSubmit={submitEventNote}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Edit Event Note"}
                            onClose={onCancel}
                            size={"small"}
                        >
                            <FormElement>
                                <fieldset
                                    className={"k-form-fieldset"}
                                    style={{ textAlign: "left" }}
                                >
                                    <label
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Notes:
                                    </label>
                                    <Field
                                        name={"name"}
                                        component={FormTextArea}
                                        autoSize={true}
                                        rows={4}
                                        validator={requiredValidator}
                                    />
                                </fieldset>
                                <FormButtons
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={onCancel}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    isCreate={false}
                                />
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default EventNoteEdit;
