import * as React from "react";
import "../AccountingModule.module.scss";
import { TabStripTab } from "@progress/kendo-react-layout";
import BalanceSheetTreeList from "./Components/BalanceSheetTreeList";
import { HighlightedTabStrip } from "../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";

const BalanceSheetPage = () => {
    return (
        <HighlightedTabStrip id={"BalanceSheet"}>
            <TabStripTab title="Balance Sheet">
                <BalanceSheetTreeList />
            </TabStripTab>
        </HighlightedTabStrip>
    );
};

export default BalanceSheetPage;
