import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { useModal } from "../../../../hooks/useModal";
import { EventModal } from "../../CalendarEvents/Components/EventModal/EventModal";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";
import { DateCell } from "../../../../components/GridColumnComponents/DateCell";
import { CustomAppointmentTimeCell } from "../../../../components/Deprecated/CustomGridCells";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell";
import { deleteEvent } from "../../../../services/Deprecated/customer/eventServices";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { ActionButton } from "../../../../components/Buttons/ActionButton";

const InitialSort = [
    {
        field: "eventType.name",
        dir: "asc",
    },
    {
        field: "eventDate",
        dir: "asc",
    },
];
const NameStringListCell = (props) => {
    return (
        <td {...props.tdProps} colSpan={props.colSpan ?? 1}>
            {props.dataItem.laborers.map((l) => l.name).join(", ")}
        </td>
    );
};

const JobEvents = () => {
    const jobContext = useContext(JobContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const modal = useModal();

    return jobContext?.job?.id ? (
        <div className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <EventModal {...modal} />
            <ActionButton
                onClick={() =>
                    modal.open(undefined, {
                        eventDate: new Date(),
                        job: jobContext.job,
                        customer: jobContext.job.customer,
                    })
                }
                text={"Create Event"}
                icon={"k-icon k-i-plus"}
                requiredPermissions={[PermissionsEnum.CreateCalendarEvent]}
            />
            <DataGrid
                uri={`/Customer/Job/CalendarEvent/GetAll?jobId=${jobContext.job.id}&`}
                sortable={{
                    mode: "multiple",
                }}
                sort={InitialSort}
                searchFields={["eventDateTitle", "eventType.name"]}
            >
                <GridColumn
                    field="eventType.name"
                    title="Type:"
                    width={200}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            field={"eventType.name"}
                            uri={`/event/${props.dataItem.id}`}
                        />
                    )}
                />
                <GridColumn field="eventDateTitle" title="Title:" width={200} />
                <GridColumn
                    field="eventDate"
                    title="Date:"
                    width={150}
                    cell={(props) => (
                        <DateCell
                            {...props}
                            format={"MM/DD/YYYY"}
                            errText={"Date Not Set"}
                        />
                    )}
                />
                <GridColumn
                    field="appointmentTime"
                    title="Appointment Time:"
                    width={175}
                    cell={(props) => (
                        <CustomAppointmentTimeCell
                            {...props}
                            data={props.dataItem}
                        />
                    )}
                />
                <GridColumn
                    field="jobDocument.name"
                    title="Job Document:"
                    width={200}
                />
                <GridColumn
                    field={"laborers"}
                    title={"Laborers:"}
                    width={400}
                    cell={(props) => <NameStringListCell {...props} />}
                />
                <GridColumn
                    width={180}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            onEdit={() => modal.open(props.dataItem.id)}
                            onDelete={() =>
                                deleteEvent(props.dataItem.id).then(() => {
                                    triggerReload();
                                })
                            }
                            viewState={{
                                uri: `/event/${props.dataItem.id}`,
                                breadcrumbInfo: [
                                    {
                                        id: props.dataItem.id,
                                        text:
                                            props.dataItem.eventDateTitle ??
                                            "Current Event",
                                    },
                                ],
                            }}
                            permissions={{
                                edit: [PermissionsEnum.UpdateCalendarEvent],
                                delete: [PermissionsEnum.DeleteCalendarEvent],
                                view: [PermissionsEnum.ReadCalendarEvent],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default JobEvents;
