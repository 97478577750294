import React, { useContext, useEffect, useState } from "react";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { PaymentContext } from "../../../../../providers/Deprecated/Customer/PaymentProvider";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { getAllDocuments } from "../../../../../services/Deprecated/customer/jobServices";
import { multiJobDisburse } from "../../../../../services/Deprecated/customer/paymentServices";
import { Dialog } from "@progress/kendo-react-dialogs";
import { formatUSDCurrency } from "../../../../../resources/Deprecated/currencyHelper";
import { EnumCell } from "../../../../../components/GridColumnComponents/EnumCell";
import { NewJobDocumentType } from "../../../../../resources/Enums/JobDocumentTypeEnum";
import { BaseCell } from "../../../../../components/GridColumnComponents/BaseCell";
import { FormButtons } from "../../../../../components/Buttons/FormButtons";
import { EditableNumericCell } from "../../../../../components/GridColumnComponents/EditableNumericCell.jsx";

const JobApplyPaymentModal = (props) => {
    const { close, visible } = props;
    const { disbursingPayment } = useContext(PaymentContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [remainder, setRemainder] = useState(undefined);
    const [jobDocuments, setJobDocuments] = useState([]);

    /**
     * Set the remainder to the total amount of the payment
     */
    useEffect(() => {
        if (disbursingPayment) {
            setRemainder(disbursingPayment.remainder.amount);
        }
    }, [disbursingPayment]);

    /**
     * Handles the sorting of documents to display to the User.
     * We only want to display SalesOrders or Invoices, if a SO has
     * an Invoice then we do not want to show the SO.
     */
    useEffect(() => {
        // If the modal is not visible or there is no payment to disburse, return
        if (!visible || !disbursingPayment) return;

        getAllDocuments(
            `(type~eq~1~or~type~eq~2)~and~jobId~eq~'${disbursingPayment.job.id}'`
        ).then((res) => {
            const soPayments = [];
            const filteredDocs = res.data.filter((doc) => {
                if (doc.hasChildren && doc.totalPaid.amount > 0) {
                    for (let i = 0; i < doc.appliedPayments.length; i++) {
                        soPayments.push({
                            ...doc.appliedPayments[i],
                            documentNumber: doc.documentNumber,
                        });
                    }
                }

                if (!doc.hasChildren) {
                    return doc;
                }
            });

            setJobDocuments(
                filteredDocs.map((doc) => {
                    const isPaidFromSalesOrder =
                        doc.paidFromSalesOrder.amount > 0;
                    const isInvoice =
                        doc.type === NewJobDocumentType.Enum.Invoice;

                    if (isPaidFromSalesOrder && isInvoice) {
                        const payments = soPayments.filter((payment) => {
                            return (
                                payment.documentNumber === doc.documentNumber
                            );
                        });

                        doc.appliedPayments.push(...payments);
                    }

                    return {
                        ...doc,
                        appliedAmount: calculateAppliedAmount(doc),
                        newlyAppliedAmount: 0, // Used for submission purposes
                        amountToApply: 0,
                        IN_LINE_EDIT: true, // Set edit to true so that all Items are editable in the Grid
                    };
                })
            );
        });
    }, [visible, disbursingPayment]);

    const calculateAppliedAmount = (doc) => {
        const appliedPayments = doc.appliedPayments;
        const disbursingChildren = disbursingPayment.childrenPayments;

        return appliedPayments.reduce((acc, curr) => {
            if (disbursingChildren.some((doc) => doc.id === curr.id)) {
                acc += curr.total.amount;
            }

            return acc;
        }, 0);
    };

    /**
     * @function onSubmit
     * @description Handles the submission of the applied amounts to the documents
     * @returns {void}
     */
    const onSubmit = () => {
        setLoaderVisible(true);

        const formattedJobDocs = jobDocuments.map((doc) => {
            return {
                id: doc.id,
                appliedAmount: doc.newlyAppliedAmount,
            };
        });
        const formattedData = {
            documents: formattedJobDocs,
            paymentId: disbursingPayment.id,
        };

        multiJobDisburse(formattedData).then((_) => {
            triggerReload();
            onClose();
        });
    };

    /**
     * @function onClose
     * @description Closes the modal and resets the state of the modal
     * @returns {void}
     */
    const onClose = () => {
        setRemainder(0);
        setJobDocuments([]);
        setLoaderVisible(false);
        close();
    };

    /**
     * @function itemChange
     * @description Handles the change of the applied amount on the document
     * @param e - The event data
     * @returns {void}
     */
    const itemChange = (e) => {
        if (e.target.value <= 0) return;

        let newData = jobDocuments.map((item) => {
            if (item.id === e.dataItem.id) {
                item.appliedAmount += e.target.value;
                item.newlyAppliedAmount += e.target.value;
            }

            return item;
        });

        setRemainder(remainder - e.target.value);
        setJobDocuments(newData);
    };

    const generateMaxValueForPrice = (dataItem) => {
        if (dataItem.totalDue < remainder) {
            return dataItem.totalDue;
        }

        return remainder;
    };

    return (
        visible &&
        remainder !== undefined &&
        disbursingPayment !== undefined && (
            <Dialog
                title={"Enter amount(s) to apply to document(s)"}
                onClose={onClose}
                width={750}
                height={475}
            >
                <p className={"AlignTextRight"}>
                    Total Amount:{" "}
                    <strong>
                        {formatUSDCurrency(disbursingPayment.total.amount)}
                    </strong>
                </p>
                <Grid
                    style={{ minHeight: "200px" }}
                    data={jobDocuments}
                    editField={"inEdit"}
                >
                    <GridNoRecords>No Documents Present on Job</GridNoRecords>
                    <GridColumn
                        field="documentNumber"
                        title="Document"
                        width={85}
                        editable={false}
                        cell={(props) => (
                            <BaseCell {...props}>
                                #{props.dataItem.documentNumber}
                            </BaseCell>
                        )}
                    />
                    <GridColumn
                        field="type"
                        title="Type"
                        width={100}
                        editable={false}
                        cell={(props) => (
                            <EnumCell
                                {...props}
                                enumerable={NewJobDocumentType}
                            />
                        )}
                    />
                    <GridColumn
                        field="total.amount"
                        title="Total"
                        width={125}
                        format={"{0:c}"}
                        editable={false}
                    />
                    <GridColumn
                        field="totalDue"
                        title="Amt. Remaining"
                        format={"{0:c}"}
                        width={125}
                        editable={false}
                    />
                    <GridColumn
                        field="appliedAmount"
                        title="Applied Amount"
                        editor="numeric"
                        format={"{0:c}"}
                        editable={true}
                    />
                    <GridColumn
                        field="amountToApply"
                        title="Amt. To Apply"
                        cell={(props) => (
                            <EditableNumericCell
                                {...props}
                                editField={"IN_LINE_EDIT"}
                                format={"c2"}
                                onBlur={(e) => itemChange(e)}
                                min={0}
                                max={generateMaxValueForPrice(props.dataItem)}
                            />
                        )}
                    />
                </Grid>
                <p className={"AlignTextRight"}>
                    Remainder:{" "}
                    <strong style={{ color: remainder < 0 ? "red" : "black" }}>
                        {formatUSDCurrency(remainder)}
                    </strong>
                </p>
                <FormButtons
                    theme={"primary"}
                    allowSubmit={remainder >= 0}
                    text={"Apply"}
                    actionOnCancel={onClose}
                    loaderVisible={loaderVisible}
                    onSubmit={onSubmit}
                />
            </Dialog>
        )
    );
};

export default JobApplyPaymentModal;
