import React, { useContext, useState } from "react";
import { DocumentLineItemContext } from "../../../../../../providers/DocumentLineItemProvider.jsx";
import { FormDropDown } from "../../../../../../components/Deprecated/FormComponents.jsx";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper.jsx";
import { NewItemTypeEnum } from "../../../../../../resources/Enums/ItemTypeEnum.js";
import { FormElement } from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";

/**
 * @function JobDocumentAddDiscountModal
 * @description Modal to add a discount item to the document
 * @param props.close {function} - close function
 * @param props.visible {boolean} - visible state
 * @param props.record {object} - record object
 * @return {React.JSX.Element}
 * @constructor
 */
export const JobDocumentAddDiscountModal = (props) => {
    const { close, visible, record } = props;
    const { addLineItem, runningTotalRef, updateLineItem } = useContext(
        DocumentLineItemContext
    );
    const [discount, setDiscount] = useState(undefined);
    const formatNumericProps = () => {
        if (discount?.type?.id === NewItemTypeEnum.Enum.CashDiscount) {
            return {
                max: record?.parent
                    ? record.parent.total.amount
                    : runningTotalRef.current,
                format: "c2",
            };
        }

        return {
            max: 1,
            format: "p",
        };
    };

    const onClose = () => {
        setDiscount(undefined);
        close();
    };

    const onSubmit = () => {
        let quantity, total, price, type;

        if (discount.type.id === NewItemTypeEnum.Enum.CashDiscount) {
            quantity = 1;
            total = discount.amount;
            price = total;
            type = NewItemTypeEnum.Enum.CashDiscount;
        } else {
            if (record?.parent) {
                total = record.parent.total.amount * discount.amount;
            } else {
                total = runningTotalRef.current * discount.amount;
            }
            quantity = discount.amount * 100;
            price = total / quantity;
            type = NewItemTypeEnum.Enum.PercentageDiscount;
        }

        const lineItem = {
            id: Math.random(), // Ensures unique key on the client side
            item: {
                name: "---DISCOUNT---",
                isDescriptionEditAllowed: true,
                isActive: true,
                retailUom: { name: "Each" },
            },
            isActive: true,
            type: type,
            price: { amount: price, currency: "USD" },
            total: { amount: total, currency: "USD" },
            quantity: quantity,
            parentId: record?.parent?.id,
            IN_LINE_EDIT: true,
        };

        if (record?.parent) {
            updateLineItem(record.parent.index, {
                ...record.parent,
                EXPANDED: true,
                children: [...(record.parent.children ?? []), lineItem],
            });
        } else {
            addLineItem(lineItem);
        }

        onClose();
    };

    return (
        visible && (
            <ResponsiveDialog
                title={"Add Discount"}
                onClose={onClose}
                size={"small"}
            >
                <FormElement>
                    <FormDropDown
                        data={[
                            {
                                id: NewItemTypeEnum.Enum.CashDiscount,
                                name: "Flat",
                            },
                            {
                                id: NewItemTypeEnum.Enum.PercentageDiscount,
                                name: "Percentage",
                            },
                        ]}
                        label={"Type:"}
                        onChange={(e) => {
                            setDiscount({
                                ...discount,
                                type: e.value,
                                amount:
                                    e.value.id ===
                                    NewItemTypeEnum.Enum.CashDiscount
                                        ? 1
                                        : 0.01,
                            });
                        }}
                    />
                    <NumericTextBox
                        {...formatNumericProps()}
                        min={0.01}
                        value={discount?.amount}
                        disabled={!discount?.type}
                        label={"Amount:"}
                        onChange={(e) => {
                            setDiscount({
                                ...discount,
                                amount: e.value,
                            });
                        }}
                    />
                </FormElement>
                <FormButtons
                    loaderVisible={false}
                    actionOnCancel={onClose}
                    allowSubmit={discount?.type && discount?.amount}
                    isCreate={true}
                    text={"Add"}
                    onSubmit={onSubmit}
                />
            </ResponsiveDialog>
        )
    );
};
