import React, { useState } from "react";
import { TabStrip } from "@progress/kendo-react-layout";
import styles from "./Styles/HighlightedTabStrip.module.scss";

export const HighlightedTabStrip = ({
    children,
    style,
    className,
    tabContentStyle,
    id,
    initialSelected = 0,
}) => {
    const [selected, setSelected] = useState(initialSelected);

    const onSelection = (e) => {
        setSelected(e.selected);
    };

    return (
        <span className={styles.HighlightedTabStripContainer}>
            <TabStrip
                id={id}
                selected={selected}
                onSelect={onSelection}
                style={style}
                className={className}
                tabContentStyle={{
                    ...tabContentStyle,
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
                }}
            >
                {children}
            </TabStrip>
        </span>
    );
};
