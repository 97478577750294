import React, { useContext, useEffect, useState } from "react";
import { Card, TabStripTab } from "@progress/kendo-react-layout";
import { CustomerContext } from "../../../providers/Deprecated/Customer/CustomerProvider";
import { useModal } from "../../../hooks/useModal";
import { getJobEvents } from "../../../services/Deprecated/customer/eventServices";
import { deleteCustomer } from "../../../services/Deprecated/customer/customerServices";
import { CustomerModal } from "./Components/CustomerModal";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import CustomerTabs from "./Components/CustomerTabs";
import ActiveJobsList from "../Jobs/Components/ActiveJobsList";
import DeadJobsList from "../Jobs/Components/DeadJobsList";
import ArchivedJobsList from "../Jobs/Components/ArchivedJobsList";
import { ActionButton } from "../../../components/Buttons/ActionButton";
import { RemoteDropDown } from "../../../components/FormInputs/RemoteDropDown.jsx";
import { ProspectStatus } from "../../../resources/Enums/ProspectStatus.js";
import { BreadcrumbContext } from "../../../providers/BreadCrumbProvider.jsx";
import { useLocalStorage } from "../../../hooks/useLocalStorage.js";
import { HighlightedTabStrip } from "../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";

const CustomerDetailsPage = (props) => {
    const { handleRouteChange } = useContext(BreadcrumbContext);
    const editModal = useModal();
    const customerContext = useContext(CustomerContext);
    const customerId = props?.match?.params?.id;
    const [events, setEvents] = useState([]);
    const localStorage = useLocalStorage();

    useEffect(() => {
        customerContext.setCustomerId(customerId);
    }, [customerId]);

    useEffect(() => {
        if (!customerContext.customer) return;

        if (customerContext.customer.redirectHome) {
            gotoCustomers();
            localStorage.remove("lastViewedCustomer");
            return;
        }

        localStorage.add("lastViewedCustomer", {
            id: customerContext.customer.id,
            text: customerContext.customer.combinedName,
        });
    }, [customerContext.customer]);

    useEffect(() => {
        getJobEvents().then((res) => {
            setEvents(res.data);
        });
    }, []);

    const isDeletable = () => {
        return !events.some((event) => event?.customer?.id === customerId);
    };

    const deleteCustomerViewPage = async () => {
        try {
            if (!isDeletable()) {
                alert("Tip: Cannot delete customer with events.");
                return;
            }
            await deleteCustomer(customerId);
            customerContext.setCustomerId(undefined);
            gotoCustomers();
        } catch (error) {
            console.error("Error deleting customer:", error);
        }
    };

    const gotoCustomers = () => {
        localStorage.remove("lastViewedCustomer");
        handleRouteChange({
            uri: "/Customer",
            breadcrumbInfo: [],
        });
    };

    const navigateToCustomer = (event) => {
        if (event.value) {
            const info = [
                {
                    id: event.value.id,
                    text: event.value.combinedName,
                },
            ];
            handleRouteChange({
                uri: `/Customer/${event.value.id}`,
                breadcrumbInfo: info,
            });
            localStorage.add("lastViewedCustomer", {
                key: "lastViewedCustomer",
                value: info[0],
            });
        } else {
            gotoCustomers();
        }
    };

    return (
        <div>
            <CustomerModal {...editModal} />
            <header className="vendor-name-header">
                {customerContext.customer.id ? (
                    customerContext.customer.combinedName
                ) : (
                    <CenterLoader />
                )}
            </header>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 20,
                }}
            >
                <span
                    style={{
                        display: "flex",
                        justifyContent: "left",
                        marginLeft: 20,
                    }}
                >
                    <RemoteDropDown
                        url={"/Customer/Customer/GetAll?"}
                        label={"Search For Customer:"}
                        useExactMatch={true}
                        textField={"combinedName"}
                        searchFields={["name1", "name2"]}
                        initialSort={[{ field: "name2", dir: "asc" }]}
                        defaultValue={customerContext.customer}
                        hint={"Clear selection to view all customers."}
                        initialFilters={[
                            {
                                field: "ProspectStatus",
                                operator: "eq",
                                value: ProspectStatus.Enum.Customer,
                            },
                        ]}
                        onChange={navigateToCustomer}
                        style={{ width: 400 }}
                    />
                </span>
                <span className={"JustifyRightAlignCenter"}>
                    <ActionButton
                        text={"Go To Customers"}
                        icon={"k-icon k-i-undo"}
                        onClick={() => gotoCustomers()}
                        requiredPermissions={[PermissionsEnum.ViewCustomer]}
                    />
                    <ActionButton
                        text={"Delete"}
                        onClick={() => deleteCustomerViewPage()}
                        confirmationState={{
                            needsConfirmation: true,
                            confirmationText:
                                "Are you sure you want to delete this customer?",
                        }}
                        disabled={!isDeletable()}
                        disabledToolTipMessage="Tip: Cannot delete Customer with Events."
                        requiredPermissions={[PermissionsEnum.DeleteCustomer]}
                    />
                    <ActionButton
                        text={"Edit"}
                        onClick={() => editModal.open(customerId)}
                        requiredPermissions={[PermissionsEnum.UpdateCustomer]}
                    />
                </span>
            </div>
            <Card className="card-container" style={{ border: "none" }}>
                <CustomerTabs />
            </Card>
            <div className="k-flex-100 flex-container">
                <div className="content-wrapper">
                    <Card className="card-container" style={{ border: "none" }}>
                        <HighlightedTabStrip>
                            <TabStripTab
                                title="Active Jobs"
                                contentClassName={"force-content-flex"}
                            >
                                <ActiveJobsList />
                            </TabStripTab>
                            <TabStripTab
                                title="Dead Jobs"
                                contentClassName={"force-content-flex"}
                            >
                                <DeadJobsList />
                            </TabStripTab>
                            <TabStripTab
                                title="Archived Jobs"
                                contentClassName={"force-content-flex"}
                            >
                                <ArchivedJobsList />
                            </TabStripTab>
                        </HighlightedTabStrip>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default CustomerDetailsPage;
