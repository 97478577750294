import { GridColumn } from "@progress/kendo-react-grid";
import React, { useContext } from "react";
import { VendorContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import {
    deleteVendorItem,
    vendorItemBulkPriceChange,
} from "../../../../services/Deprecated/inventory/vendorService";
import "../../../../styles/CommonPositionalStyles.scss";
import { useModal } from "../../../../hooks/useModal";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import {
    PriceChangeModal,
    PriceChangeTypes,
} from "../../Components/PriceChangeModal";
import { FilterOperator } from "../../../../resources/Enums/FilterOperator";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import VendorItemModal from "./VendorItemModal";
import VendorCategorySelectModal from "./VendorCategorySelectModal";
import { EditableNumericCell } from "../../../../components/GridColumnComponents/EditableNumericCell.jsx";
import PermissionsHelper from "../../../../resources/PermissionsHelper.js";
import { AuthContext } from "../../../../providers/Deprecated/Authentication/User/AuthProvider.jsx";
import BaseService from "../../../../services/BaseService.js";

const VendorItemsGrid = (props) => {
    const { vendorId } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const { vendor } = useContext(VendorContext);
    const { user } = useContext(AuthContext);
    const addExistingModal = useModal();
    const categorySelectModal = useModal();
    const priceChangeModal = useModal();
    const hasUpdatePermission = PermissionsHelper.hasPermission(
        user.role.permissions,
        [PermissionsEnum.UpdateItem]
    );

    const onPriceChangeClick = () => {
        const element = document.getElementById("inLineGridSearch");

        priceChangeModal.open(undefined, {
            params: element?.value,
        });
    };

    const onDelete = (itemId) => {
        deleteVendorItem(vendorId, itemId).then(() => triggerReload());
    };

    const onPriceChange = async (data) => {
        const element = document.getElementById("inLineGridSearch");
        const gridSearchOperator = document.getElementById(
            "gridStateSearchToggle"
        );

        const enumerable =
            gridSearchOperator.value === "true"
                ? FilterOperator.Enum.StartsWith
                : FilterOperator.Enum.Contains;

        await vendorItemBulkPriceChange({
            vendorId: vendorId,
            query: element?.value,
            operator: enumerable,
            priceChangeInformation: data,
        });
    };

    const onSaveChanges = async (data) => {
        const service = new BaseService("Inventory/Vendor/Item");
        for (const item of data) {
            await service.update({
                id: item.id,
                vendorId: item.vendor.id,
                itemId: item.item.id,
                vendorCost: item.vendorCost,
                freightCharge: item.freightCharge,
            });
        }

        triggerReload();
    };

    return (
        <div>
            <span
                className={"DisplayFlex JustifyLeft AlignItemsCenter SmallGap"}
                style={{ marginBottom: 15 }}
            >
                <ActionButton
                    text={"Apply a Price Change"}
                    onClick={onPriceChangeClick}
                    requiredPermissions={[PermissionsEnum.UpdateItem]}
                    icon={"k-icon k-i-dollar"}
                />
                <ActionButton
                    requiredPermissions={[PermissionsEnum.UpdateVendor]}
                    onClick={() =>
                        addExistingModal.open(undefined, {
                            vendor: vendor,
                        })
                    }
                    icon={"k-icon k-i-gear"}
                >
                    Add Existing Item
                </ActionButton>
                <ActionButton
                    requiredPermissions={[PermissionsEnum.CreateItem]}
                    onClick={() => categorySelectModal.open()}
                    icon={"k-icon k-i-plus"}
                >
                    Create Vendor Item
                </ActionButton>
                <PriceChangeModal
                    {...priceChangeModal}
                    submitCallback={onPriceChange}
                    fields={[
                        {
                            id: 1,
                            name: "Vendor Cost",
                        },
                        {
                            id: 2,
                            name: "Freight Charge",
                        },
                    ]}
                    types={[PriceChangeTypes.Dollar, PriceChangeTypes.Percent]}
                />
                <VendorItemModal {...addExistingModal} />
                <VendorCategorySelectModal {...categorySelectModal} />
            </span>
            <DataGrid
                uri={`/Inventory/Vendor/Item/GetAll?vendorId=${vendorId}&`}
                searchFields={["item.name", "item.baseDescription"]}
                resizable={true}
                onSaveChanges={onSaveChanges}
                useInlineEdit={true}
                style={{
                    maxHeight: "1200px",
                    margin: "0",
                }}
            >
                <GridColumn
                    field="item.name"
                    title="Item:"
                    width={300}
                    minResizableWidth={250}
                    editable={false}
                />
                <GridColumn
                    field="item.baseDescription"
                    title="Description:"
                    width={300}
                    minResizableWidth={100}
                    editable={false}
                />
                <GridColumn
                    field="item.retailPrice.amount"
                    title="Retail Price:"
                    width={150}
                    minResizableWidth={50}
                    format={"{0:c2}"}
                    editable={false}
                />
                <GridColumn
                    field="vendorCost.amount"
                    title="Vendor Cost:"
                    width={150}
                    minResizableWidth={50}
                    format={"{0:c2}"}
                    cell={(props) => (
                        <EditableNumericCell
                            {...props}
                            editField={"IN_LINE_EDIT"}
                            format={"c2"}
                            disabled={!hasUpdatePermission}
                        />
                    )}
                />
                <GridColumn
                    field="freightCharge.amount"
                    title="Freight Charge:"
                    width={150}
                    minResizableWidth={50}
                    format={"{0:c2}"}
                    cell={(props) => (
                        <EditableNumericCell
                            {...props}
                            editField={"IN_LINE_EDIT"}
                            format={"c2"}
                            disabled={!hasUpdatePermission}
                        />
                    )}
                />
                <GridColumn
                    field="markUp"
                    title="Mark Up:"
                    format={"{0:c2}"}
                    width={150}
                    minResizableWidth={50}
                    filterable={false}
                    sortable={false}
                    editable={false}
                />
                <GridColumn
                    resizable={false}
                    filterable={false}
                    sortable={false}
                    width={180}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() => onDelete(props.dataItem.item.id)}
                            onEdit={() =>
                                addExistingModal.open(
                                    props.dataItem.id,
                                    props.dataItem
                                )
                            }
                            viewState={{
                                uri: `/Inventory/Item/${props.dataItem.item.id}`,
                                breadcrumbInfo: [
                                    {
                                        id: props.dataItem.item.id,
                                        text: props.dataItem.item.name,
                                    },
                                ],
                            }}
                            permissions={{
                                view: [PermissionsEnum.ViewItem],
                                edit: [PermissionsEnum.UpdateItem],
                                delete: [PermissionsEnum.DeleteItem],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </div>
    );
};

export default VendorItemsGrid;
