import React from "react";
import ReactDOM from "react-dom/client";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./services/authconfig";
import { AxiosInterceptor } from "./services/axiosInstance";
import App from "./app";
import ToastProvider from "./providers/ToastProvider";
import AxiosErrorProvider from "./providers/AxiosErrorProvider";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

// Default to using the first account if no account is active on page load
if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
root.render(
    <React.Fragment>
        <MsalProvider instance={msalInstance}>
            <ToastProvider>
                <AxiosErrorProvider>
                    <AxiosInterceptor>
                        <App />
                    </AxiosInterceptor>
                </AxiosErrorProvider>
            </ToastProvider>
        </MsalProvider>
    </React.Fragment>
);
