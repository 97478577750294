import React from "react";
import { FileManager } from "../../../../components/FileManager/FileManager.jsx";

const fileRestrictions = {
    allowedExtensions: [".jpeg", ".pdf", ".jpg", ".png"],
};

export const VendorFiles = ({ vendorId }) => {
    return (
        <FileManager
            disabled={false}
            allowedExtensions={fileRestrictions}
            saveUri={"/General/File/UploadFile"}
            removeUri={"/General/File/DeleteFile"}
            readUri={"/General/File/GetDirectoryContents"}
            location={`VendorFiles-${vendorId}`}
        />
    );
};
