import * as React from "react";
import { useContext } from "react";
import styles from "./MyWorkDetails.module.scss";
import { MyWorkDateDisplay } from "../DateDisplay";
import { MyWorkContext } from "../../MyWorkContext";
import { useAvailableTasks } from "../../Hooks/useAvailableTasks";
import { MyWorkTask } from "../Task";
import FormField from "../../../../../components/Deprecated/StylingField";
import "../../../../../styles/CommonPositionalStyles.scss";
import dayjs from "dayjs";
import { CenterLoader } from "../../../../../components/Deprecated/CenterLoader";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";

export const MyWorkDetails = () => {
    const {
        switchTimeClockFocus,
        timeClockHandler,
        job,
        calendarEvent,
        currentEmployee,
        setIsAdminPanelVisible,
        isAdminPanelVisible,
        toggleClockIn,
    } = useContext(MyWorkContext);
    const { availableTasks, isLoadingTasks } = useAvailableTasks({
        employeeId: timeClockHandler.lastTimeClock.employee.id,
        jobId: timeClockHandler.lastTimeClock.job?.id,
        calendarEventId: timeClockHandler.lastTimeClock.calendarEvent?.id,
    });
    const eventDate = dayjs(calendarEvent.eventDate).format("MM/DD/YYYY");

    return (
        <span className={styles.MyWorkDetails}>
            <span>
                <ActionButton
                    text={"Open Admin Panel"}
                    requiredPermissions={[PermissionsEnum.TimeClockAdmin]}
                    onClick={() => setIsAdminPanelVisible(!isAdminPanelVisible)}
                    style={{
                        width: "100%",
                        borderRadius: 10,
                        fontSize: "1.2rem",
                    }}
                />
                <ActionButton
                    text={"Clock Out"}
                    requiredPermissions={[PermissionsEnum.CreateTimeClock]}
                    onClick={() => toggleClockIn()}
                    style={{
                        backgroundColor: "#fb3b1e",
                        width: "100%",
                        borderRadius: 10,
                        fontSize: "1.2rem",
                        marginTop: 10,
                    }}
                />
            </span>
            <span className={"JustifyBetweenAlignCenter"}>
                <ActionButton
                    icon={"k-icon k-i-arrow-left"}
                    onClick={() => switchTimeClockFocus()}
                    style={{
                        fontSize: "1.2rem",
                    }}
                >
                    Choose new Work
                </ActionButton>
                <MyWorkDateDisplay />
            </span>
            {eventDate !== dayjs(new Date()).format("MM/DD/YYYY") && (
                <span className={styles.EventPastDueWarning}>
                    Event is past due on, {eventDate}
                </span>
            )}
            <span className={"JustifyCenterAlignLeft SmallGap FlexColumn"}>
                <span>
                    <FormField
                        label="Employee:"
                        value={currentEmployee.fullName}
                    />
                </span>
                <span>
                    <FormField
                        label="Job:"
                        value={job?.name ?? "Unassigned to Job"}
                    />
                </span>
                <span>
                    <FormField
                        label="Calendar Event:"
                        value={calendarEvent?.name}
                    />
                </span>
            </span>
            <span
                className={styles.Tasks + " JustifyCenterAlignLeft FlexColumn"}
            >
                <hr className={"Separator"} />
                <span
                    className={"JustifyCenterAlignLeft FlexColumn LargeGap"}
                    style={{ width: "100%" }}
                >
                    {isLoadingTasks ? (
                        <>
                            <h4>Loading Employee Task Information...</h4>
                            <CenterLoader />
                        </>
                    ) : (
                        availableTasks.map((task, i) => (
                            <MyWorkTask
                                title={task.name}
                                clockTimeRecord={{
                                    job: job,
                                    task: task,
                                    calendarEvent: calendarEvent,
                                }}
                                key={i}
                            />
                        ))
                    )}
                </span>
            </span>
        </span>
    );
};
