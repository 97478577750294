import * as React from "react";
import { useContext, useEffect, useState } from "react";
import styles from "./MyWorkDashboard.module.scss";
import { MyWorkDateDisplay } from "../DateDisplay";
import { MyWorkTask } from "../Task";
import { MyWorkContext } from "../../MyWorkContext";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";

export const MyWorkDashboard = () => {
    const {
        availableTasks,
        currentEmployee,
        isAdminPanelVisible,
        setIsAdminPanelVisible,
        toggleClockIn,
    } = useContext(MyWorkContext);
    const [filteredTasks, setFilteredTasks] = useState(availableTasks);

    useEffect(() => {
        const uniqueArr = availableTasks.filter(
            (item, index, self) =>
                self.findIndex(
                    (t) => t.calendarEvent.id === item.calendarEvent.id
                ) === index
        );

        setFilteredTasks(uniqueArr);
    }, [availableTasks]);

    const buildSubTitle = (work) => {
        if (work.customer && !work.job) {
            return work.customer.name;
        }

        if (work.job) {
            return `${work.customer.name} - ${work.job.name}`;
        }

        return "Open / Chart of Accounts";
    };

    return (
        <span className={styles.MyWorkDashboard}>
            <ActionButton
                text={"Open Admin Panel"}
                requiredPermissions={[PermissionsEnum.TimeClockAdmin]}
                onClick={() => setIsAdminPanelVisible(!isAdminPanelVisible)}
                style={{
                    width: "100%",
                    borderRadius: 10,
                    fontSize: "1.2rem",
                }}
            />
            <ActionButton
                text={"Clock Out"}
                requiredPermissions={[PermissionsEnum.CreateTimeClock]}
                onClick={() => toggleClockIn()}
                style={{
                    backgroundColor: "#fb3b1e",
                    width: "100%",
                    borderRadius: 10,
                    fontSize: "1.2rem",
                    marginTop: 10,
                }}
            />
            <p className={styles.Header}>
                Welcome <strong>{currentEmployee.fullName}</strong> to your work
                dashboard!
            </p>
            <hr className={"Separator"} />
            <MyWorkDateDisplay />
            <p className={styles.SubHeader + " AlignTextLeft"}>
                What would you like to work on today?
            </p>
            <span
                className={"JustifyCenterAlignLeft FlexColumn LargeGap"}
                style={{ width: "100%" }}
            >
                {filteredTasks.length === 0 && (
                    <p className={"AlignTextCenter"}>
                        You have no tasks available at this time.
                    </p>
                )}
                {filteredTasks.map((work, i) => (
                    <MyWorkTask
                        title={work.calendarEvent?.name}
                        subTitle={buildSubTitle(work)}
                        clockTimeRecord={{
                            job: { id: work.job?.id },
                            task: null,
                            calendarEvent: {
                                id: work.calendarEvent?.id,
                            },
                        }}
                        key={i}
                    />
                ))}
            </span>
        </span>
    );
};
