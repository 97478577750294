import * as React from "react";
import { useEffect, useState } from "react";
import { Field } from "@progress/kendo-react-form";
import {
    FormDropDown,
    FormNumericInput,
} from "../../../../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../../../../resources/Deprecated/formValidators.js";
import StorageLocationService from "../../../../../../../../../services/StorageLocationService.js";
import { CenterLoader } from "../../../../../../../../../components/Deprecated/CenterLoader.jsx";

export const EachAllocation = (props) => {
    const { record, formRenderProps } = props;
    const [storageLocations, setStorageLocations] = useState(undefined);
    const [currentMaxQty, setCurrentMaxQty] = useState(0);

    useEffect(() => {
        if (!record) return;

        const service = new StorageLocationService("Inventory/StorageLocation");
        service
            .getAllContainingItem(record.itemId, record.jobId)
            .then((res) => {
                let locations = res.data.filter((l) => {
                    if (record.isDeallocate) {
                        return l.allocatedInventories.length > 0;
                    }

                    return l.availableInventories.length > 0;
                });

                setStorageLocations(locations);
                if (locations.length !== 0) {
                    const storageLocation = locations[0];
                    const maxQuantity = calculateMaxQty(
                        storageLocation,
                        record.isDeallocate
                    );
                    setCurrentMaxQty(maxQuantity);
                    formRenderProps.onChange("items[0].quantity", {
                        value: maxQuantity,
                    });
                    formRenderProps.onChange("items[0].storageLocation", {
                        value: storageLocation,
                    });
                }
            });
    }, [record]);

    const calculateMaxQty = (storageLocation, isDeallocate) => {
        const qtyOfItems = isDeallocate
            ? storageLocation.allocatedInventories.length
            : storageLocation.availableInventories.length;

        if (qtyOfItems < record.remainingNeeded) {
            return qtyOfItems;
        }

        return record.remainingNeeded;
    };

    return storageLocations ? (
        <span style={{ width: "100%" }}>
            <Field
                name={"items[0].storageLocation"}
                data={storageLocations}
                component={FormDropDown}
                label={"Select a Storage Location..."}
                validator={requiredValidator}
                style={{ marginBottom: "15px" }}
                onChange={(e) => {
                    const storageLocation = e.value;
                    const maxQuantity = calculateMaxQty(
                        storageLocation,
                        record.isDeallocate
                    );
                    setCurrentMaxQty(maxQuantity);
                    formRenderProps.onChange("items[0].quantity", {
                        value: maxQuantity,
                    });
                }}
            />
            <Field
                name={"items[0].quantity"}
                component={FormNumericInput}
                label={`Amount to ${
                    record.isDeallocate ? "Deallocate" : "Allocate"
                } from Stock:`}
                validator={requiredValidator}
                min={1}
                max={currentMaxQty}
            />
        </span>
    ) : (
        <span>
            <CenterLoader />
        </span>
    );
};
