import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Field } from "@progress/kendo-react-form";
import { ComboBoxInput } from "./ComboBoxInput";
import { InputLayouts } from "./InputLayouts";
import { requiredValidator } from "../../resources/Deprecated/formValidators.js";
import { FormInput, FormMaskedTextBox } from "../Deprecated/FormComponents.jsx";
import { States } from "../../resources/Deprecated/states.js";

/**
 * @function AddressInput
 * @description A form input component that represents an address
 * @param name {string} - The name of the field
 * @param isRequired {boolean} - Whether the field is required
 * @param isDisabled {boolean} - Whether the field is disabled
 * @param layout {InputLayouts} - The layout of the address fields
 * @param style {object} - The style of the component
 * @param onCustomChange {function} - A custom change handler
 * @param formRenderProps {object} - The form render props
 * @param others {object} - Additional props
 * @return {Element} - The address input component
 * @constructor
 */
export const AddressInput = ({
    name,
    isRequired,
    isDisabled,
    layout = InputLayouts.Horizontal,
    style,
    onCustomChange, // Must be done due to odd kendo form reset issues
    formRenderProps,
}) => {
    const [internalStyle, setInternalStyle] = useState(style ?? {});
    const [zipCodeMask, setZipCodeMask] = useState("00000-0000");

    // Determines the layout of the address fields
    useEffect(() => {
        switch (layout) {
            case InputLayouts.Horizontal:
                setInternalStyle({
                    ...style,
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                });
                break;
            case InputLayouts.Vertical:
                setInternalStyle(style);
                break;
        }
    }, [layout]);

    const addressValidator = useCallback(
        (value) => (isRequired ? requiredValidator(value) : ""),
        [isRequired]
    );

    const onInternalChange = () => {
        if (!onCustomChange) return;

        const address1Ele = document.getElementsByName(`${name}.address1`)[0];
        const address2Ele = document.getElementsByName(`${name}.address2`)[0];
        const cityEle = document.getElementsByName(`${name}.city`)[0];
        const stateEle = document.getElementById(`stateCombobox.${name}`);
        const zipCodeEle = document.getElementsByName(`${name}.zipCode`)[0];

        if (
            zipCodeEle?.value &&
            zipCodeEle.value.substring(5, zipCodeEle.value.length) === "-____"
        ) {
            zipCodeEle.value = zipCodeEle.value.substring(0, 5);
        }

        onCustomChange({
            address1: address1Ele?.value,
            address2: address2Ele?.value,
            city: cityEle?.value,
            state: stateEle?.value,
            zipCode: zipCodeEle?.value,
        });
    };

    return (
        <div style={internalStyle}>
            <Field
                name={`${name}.address1`}
                component={FormInput}
                validator={addressValidator}
                label={"Address1:"}
                disabled={isDisabled}
                keepValue={true}
                style={{ minWidth: 200 }}
                onChange={onInternalChange}
            />
            <Field
                name={`${name}.address2`}
                component={FormInput}
                label={"Address2:"}
                disabled={isDisabled}
                keepValue={true}
                style={{ minWidth: 100 }}
                onChange={onInternalChange}
            />
            <Field
                name={`${name}.city`}
                component={FormInput}
                validator={addressValidator}
                label={"City:"}
                disabled={isDisabled}
                keepValue={true}
                style={{ minWidth: 100 }}
                onChange={onInternalChange}
            />
            <Field
                id={`stateCombobox.${name}`}
                name={`${name}.state`}
                component={ComboBoxInput}
                validator={addressValidator}
                label={"State:"}
                data={States}
                style={{ minWidth: 150 }}
                onChange={onInternalChange}
            />
            <span
                onBlur={() => {
                    const zipCodeEl = document.getElementsByName(
                        `${name}.zipCode`
                    )[0];
                    const zipCode = zipCodeEl?.value;

                    if (
                        zipCode &&
                        zipCode.substring(5, zipCode.length) === "-____"
                    ) {
                        setZipCodeMask("00000");
                        formRenderProps.onChange(`${name}.zipCode`, {
                            value: zipCode.substring(0, 5),
                        });
                    }
                }}
                onFocus={() => setZipCodeMask("00000-0000")}
            >
                <Field
                    name={`${name}.zipCode`}
                    component={FormMaskedTextBox}
                    validator={addressValidator}
                    mask={zipCodeMask}
                    label={"Zip:"}
                    disabled={isDisabled}
                    keepValue={true}
                    style={{ minWidth: 75 }}
                    onChange={onInternalChange}
                />
            </span>
        </div>
    );
};
