import React, { useContext } from "react";
import { BreadcrumbContext } from "../../../../providers/BreadCrumbProvider";
import { UserContext } from "../../../../providers/Deprecated/Authentication/User/UserProvider";
import ChangeUserRoleModal from "./ChangeUserRoleModal";
import UserDetailsCard from "../../../Admin/Security/User/Components/UserDetailsCard";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";

function EmployeeUserDetails() {
    const { handleRouteChange } = useContext(BreadcrumbContext);
    const userContext = useContext(UserContext);
    /** @type {User} */
    const user = userContext.user;

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    gap: "5px",
                }}
            >
                <ChangeUserRoleModal />
                <ActionButton
                    requiredPermissions={[PermissionsEnum.ViewUser]}
                    style={{
                        justifySelf: "right",
                    }}
                    onClick={() => {
                        handleRouteChange({
                            uri: `/admin/users/${user.id}`,
                            breadcrumbInfo: [
                                {
                                    id: user.id,
                                    text: user.name,
                                },
                            ],
                        });
                    }}
                >
                    View User Page
                </ActionButton>
            </div>
            <UserDetailsCard />
        </div>
    );
}

export default EmployeeUserDetails;
