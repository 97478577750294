import * as React from "react";
import { useRef, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import { RadioButton } from "@progress/kendo-react-inputs";
import { CustomerTypeEnum } from "../../../../resources/Enums/CustomerStatusEnum";
import { FormRemoteDropDown } from "../../../../components/Deprecated/FormComponents";
import "../../../../styles/CommonTextStyles.scss";
import { useModal } from "../../../../hooks/useModal";
import { SourceModal } from "../../../Admin/Configuration/Customer/Sources/Components/SourceModal";
import { ProspectStatus } from "../../../../resources/Enums/ProspectStatus";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";

/**
 * @function PreCreateLeadModal
 * @description Modal for creating a lead before the actual lead modal
 * @param props.close {Function} - Function to close the modal
 * @param props.visible {Boolean} - Whether the modal is visible
 * @param props.leadModal {Object} - The lead modal object
 * @return {Element}
 * @constructor
 */
export const PreCreateLeadModal = (props) => {
    const { close, visible, leadModal } = props;
    const [type, setType] = useState(CustomerTypeEnum.Enum.Individual);
    const [refreshSourceKey, setRefreshSourceKey] = useState(0);
    const [refreshReferrerKey, setRefreshReferrerKey] = useState(0);
    const [refState, setRefState] = useState(undefined);
    const sourceModal = useModal();

    const formRef = useRef(null);

    // Updating form values from component outside of Form.
    // Uses formRef to set the value & manually update the
    // internal _modified state for the updated field. (sorry)
    const updateFormValues = (field, value) => {
        if (formRef.current) {
            formRef.current.valueSetter(field, value);
            formRef.current._modified = {
                ...formRef.current._modified,
                [field]: true,
            };
        }
    };

    // Wrapper for the SourceModal close so we can refresh the source dropdown
    const onSourceClose = (createdSource) => {
        sourceModal.close();
        updateFormValues("source", createdSource);
        setRefreshSourceKey(refreshSourceKey + Math.random());
    };

    const onClose = () => {
        setRefState(undefined);
        setType(CustomerTypeEnum.Enum.Individual);
        close();
    };

    const onSubmit = (dataItem) => {
        const data = {
            referrer: dataItem.referrer,
            source: dataItem.source,
            type: type,
        };

        leadModal.open(undefined, data);
    };

    const onSourceChange = (formRenderProps, e) => {
        formRenderProps.onChange("referrer", { value: "" });
        setRefreshReferrerKey(refreshReferrerKey + Math.random());

        switch (e.value?.name) {
            case "Customer Referral":
                setRefState({
                    textField: "combinedName",
                    uri: "/Customer/Customer/GetAll?",
                    searchFields: ["name1", "name2"],
                    initialFilter: {
                        logic: "and",
                        filters: [
                            {
                                field: "prospectStatus",
                                operator: "eq",
                                value: ProspectStatus.Enum.Customer,
                            },
                        ],
                    },
                    initialSort: [
                        {
                            field: "name1",
                            dir: "asc",
                        },
                    ],
                });
                break;
            case "Employee Referral":
                setRefState({
                    textField: "fullName",
                    searchFields: ["firstName", "lastName"],
                    uri: "/HumanResources/Employee/GetAll?",
                    initialSort: [
                        {
                            field: "firstName",
                            dir: "asc",
                        },
                    ],
                });
                break;
            case "Vendor Referral":
                setRefState({
                    uri: "/Inventory/Vendor/GetAll?",
                });
                break;
            default:
                setRefState(undefined);
                break;
        }
    };

    return (
        visible && (
            <ResponsiveDialog
                title={"Create Lead"}
                onClose={onClose}
                size={"small"}
            >
                <SourceModal {...sourceModal} close={onSourceClose} />
                <Form
                    ref={formRef}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement
                            className={
                                "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                            }
                        >
                            <div style={{ width: "100%" }}>
                                <h4 className={"AlignTextLeft"}>Lead Type:</h4>
                                <div className={"JustifyLeftAlignCenter"}>
                                    <RadioButton
                                        name="Individual"
                                        value={CustomerTypeEnum.Enum.Individual}
                                        checked={
                                            type ===
                                            CustomerTypeEnum.Enum.Individual
                                        }
                                        label="Individual"
                                        onChange={(e) => setType(e.value)}
                                    />
                                    <span style={{ margin: 10 }} />
                                    <RadioButton
                                        name="Business"
                                        value={CustomerTypeEnum.Enum.Business}
                                        checked={
                                            type ===
                                            CustomerTypeEnum.Enum.Business
                                        }
                                        label="Business"
                                        onChange={(e) => setType(e.value)}
                                    />
                                </div>
                                <Field
                                    key={refreshSourceKey}
                                    name={"source"}
                                    component={FormRemoteDropDown}
                                    url={"/Customer/Source/GetAll?"}
                                    label={"Select a Source..."}
                                    onChange={(e) =>
                                        onSourceChange(formRenderProps, e)
                                    }
                                    validator={requiredValidator}
                                />
                                <a
                                    className={"Link JustifyLeftAlignLeft"}
                                    style={{
                                        marginLeft: 5,
                                        marginTop: 2,
                                    }}
                                    onClick={() => sourceModal.open()}
                                >
                                    Add new Source
                                </a>
                                {refState && (
                                    <Field
                                        key={refreshReferrerKey}
                                        name={"referrer"}
                                        component={FormRemoteDropDown}
                                        initialSort={refState.initialSort}
                                        url={refState.uri}
                                        textField={refState.textField}
                                        searchFields={refState.searchFields}
                                        label={"Select a Reference..."}
                                        initialFilter={refState.initialFilter}
                                        validator={requiredValidator}
                                    />
                                )}
                            </div>
                            <FormButtons
                                loaderVisible={false}
                                theme={"primary"}
                                text={"Next"}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={false}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
