import React from "react";
import { TabStripTab } from "@progress/kendo-react-layout";
import ItemDetails from "../DetailItems/Components/ItemDetails";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";

const ItemTabs = () => {
    return (
        <HighlightedTabStrip className="tabs">
            <TabStripTab title="Item">
                <ItemDetails />
            </TabStripTab>
        </HighlightedTabStrip>
    );
};

export default ItemTabs;
