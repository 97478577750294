import React, { useContext } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import { GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { EventContext } from "../../../../../providers/Deprecated/Customer/EventProvider";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { useModal } from "../../../../../hooks/useModal";
import { deleteEvent } from "../../../../../services/Deprecated/customer/eventServices";
import { utcToLocalFormatter } from "../../../../../resources/Deprecated/stringFormatter";
import { EventModal } from "../EventModal/EventModal";
import CalendarEventCopyModal from "../CalendarEventCopyModal";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { LinkCell } from "../../../../../components/GridColumnComponents/LinkCell";
import { CustomCell } from "../../../../../components/Deprecated/CustomGridCells";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { CenterLoader } from "../../../../../components/Deprecated/CenterLoader";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { DataGrid } from "../../../../../components/Grids/DataGrid.jsx";
import { EventTypeDatesDetailRow } from "./EventTypeDatesDetailRow.jsx";

dayjs.extend(utc);

const EventTypeDatesPortlet = () => {
    const eventContext = useContext(EventContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const { job, eventType, id } = eventContext.event;
    const copyModal = useModal();
    const eventModal = useModal();

    // This is a custom formatter for the appointment time
    // It takes the start and end time and formats it to a string
    // Example: 8:00 AM - 10:00 AM
    const displayAppointmentTime = (dataItem) => {
        if (!dataItem.appointmentTime) {
            return "";
        }

        const appointmentTime = dataItem.appointmentTime;

        const startTime = utcToLocalFormatter(
            appointmentTime.startTime,
            "h:mm A"
        );
        const endTime = utcToLocalFormatter(appointmentTime.endTime, "h:mm A");

        return `${startTime} - ${endTime}`;
    };

    /**
     * @function onCopy
     * @description Opens the copy modal
     * @param id - The id of the event to copy
     */
    const onCopy = (id) => {
        copyModal.open(id);
    };

    return (
        <Card
            style={{
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                maxHeight: "316px",
            }}
        >
            <EventModal {...eventModal} />
            <CalendarEventCopyModal {...copyModal} />
            <CardHeader
                className="k-hbox"
                style={{
                    background: "transparent",
                    width: "100%",
                    backgroundColor: "#67809F",
                    color: "#fff",
                    height: 60,
                }}
            >
                <CardTitle
                    style={{
                        marginBottom: "4px",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 10,
                        alignItems: "center",
                        width: "100%",
                        height: "40px",
                    }}
                >
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                        }}
                    >
                        <i className={"k-icon k-i-calendar"} />
                        <strong className={"text-overflow"}>
                            &quot;{eventContext.event?.eventType?.name}&quot;
                            Events
                        </strong>
                    </span>
                    <ActionButton
                        text={"Create"}
                        icon={"k-icon k-i-plus"}
                        style={{
                            border: "none",
                        }}
                        onClick={() =>
                            eventModal.open(undefined, {
                                eventDateTitle:
                                    eventContext.event?.eventType?.name,
                                eventType: eventContext.event?.eventType,
                                eventDate: new Date(),
                                job: job,
                                customer: eventContext.event?.customer,
                            })
                        }
                        requiredPermissions={[
                            PermissionsEnum.CreateCalendarEvent,
                        ]}
                    />
                </CardTitle>
            </CardHeader>
            <CardBody
                style={{
                    padding: "5px",
                    overflowY: "auto",
                }}
            >
                {id ? (
                    <DataGrid
                        detail={EventTypeDatesDetailRow}
                        dataFormatter={(data) =>
                            data.map((i) => {
                                if (i.specification) {
                                    i.EXPANDED = true;
                                }

                                return i;
                            })
                        }
                        showColumnsMultiSelect={false}
                        disabledPagination={true}
                        uri={`/Customer/Job/CalendarEvent/GetAll?jobId=${job?.id}&`}
                        initialFilter={{
                            logic: "and",
                            filters: [
                                {
                                    field: "eventType.id",
                                    operator: "eq",
                                    value: eventType?.id,
                                },
                            ],
                        }}
                        initialSort={[
                            { field: "eventDate", dir: "asc" },
                            { field: "appointmentTime.startTime", dir: "asc" },
                        ]}
                        style={{
                            height: "230px",
                        }}
                    >
                        <GridNoRecords>
                            No Events with same type on this Job
                        </GridNoRecords>
                        <GridColumn
                            field="eventDate"
                            title="Date:"
                            width={110}
                            headerClassName={"hide-element"}
                            cell={(props) => (
                                <LinkCell
                                    {...props}
                                    style={{
                                        fontWeight: "bold",
                                        backgroundColor:
                                            props.dataItem.id === id
                                                ? "rgba(51, 122, 183, 0.2)"
                                                : "inherit",
                                    }}
                                    uri={`/Event/${props.dataItem.id}`}
                                />
                            )}
                        />
                        <GridColumn
                            field="appointmentTime"
                            title="Apppointment Time:"
                            headerClassName={"hide-element"}
                            cell={(props) => (
                                <CustomCell
                                    {...props}
                                    style={{
                                        backgroundColor:
                                            props.dataItem.id === id
                                                ? "rgba(51, 122, 183, 0.2)"
                                                : "inherit",
                                    }}
                                >
                                    <strong>
                                        {displayAppointmentTime(props.dataItem)}
                                    </strong>
                                </CustomCell>
                            )}
                        />
                        <GridColumn
                            headerClassName={"hide-element"}
                            width={188}
                            cell={(props) => (
                                <EventTypeDatesPortletActionCell
                                    {...props}
                                    onEdit={() =>
                                        eventModal.open(props.dataItem.id)
                                    }
                                    onDelete={() =>
                                        deleteEvent(props.dataItem.id).then(
                                            () => {
                                                triggerReload();
                                            }
                                        )
                                    }
                                    onCopy={onCopy}
                                    calEventId={id}
                                />
                            )}
                        />
                    </DataGrid>
                ) : (
                    <CenterLoader />
                )}
            </CardBody>
        </Card>
    );
};

export default EventTypeDatesPortlet;

const EventTypeDatesPortletActionCell = (props) => {
    const { onDelete, onEdit, onCopy, calEventId, dataItem } = props;

    return dataItem.id !== calEventId ? (
        <ActionCell
            onDelete={() => onDelete(dataItem.id)}
            onEdit={() => onEdit(dataItem.id)}
            permissions={{
                delete: [PermissionsEnum.DeleteCalendarEvent],
                edit: [PermissionsEnum.UpdateCalendarEvent],
            }}
            additionalActions={[
                {
                    onClick: () => onCopy(dataItem.id),
                    text: "Copy",
                    requiredPermissions: [PermissionsEnum.CreateCalendarEvent],
                },
            ]}
        />
    ) : (
        <ActionCell
            additionalActions={[
                {
                    onClick: () => onCopy(dataItem.id),
                    text: "Copy",
                    requiredPermissions: [PermissionsEnum.CreateCalendarEvent],
                },
            ]}
        />
    );
};
