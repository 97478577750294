import React, { useContext, useEffect, useState } from "react";
import styles from "../Styles/VendorDocumentModal.module.scss";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper.jsx";
import { Field, FormElement } from "@progress/kendo-react-form";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";
import { useModal } from "../../../../../../hooks/useModal.js";
import { AddressInput } from "../../../../../../components/FormInputs/AddressInput.jsx";
import { InputLayouts } from "../../../../../../components/FormInputs/InputLayouts.js";
import StringHelper from "../../../../../../resources/StringHelper.js";
import { VendorDocumentModalContext } from "../Providers/VendorDocumentModalProvider.jsx";
import { addressFormatter } from "../../../../../../resources/Deprecated/helpers.js";

/**
 * @function ShippingInformation
 * @description Displays information for a Document about its shipping information
 * @return {React.JSX.Element}
 * @constructor
 */
export const ShippingInformation = () => {
    const { formData } = useContext(VendorDocumentModalContext);
    const addressModal = useModal();

    return (
        formData.address && (
            <span
                className={"JustifyLeftAlignLeft"}
                style={{
                    fontSize: 16,
                    color: "#666666",
                }}
            >
                <AddressModal {...addressModal} />
                <span>
                    Shipping To:{" "}
                    <DisplayAddress formData={formData} modal={addressModal} />
                </span>
            </span>
        )
    );
};

/**
 * @function DisplayAddress
 * @param props.formData {object} - formData object
 * @param props.modal {object} - modal object
 * @return {Element}
 * @constructor
 */
const DisplayAddress = (props) => {
    const { formData, modal } = props;
    const { canEdit } = useContext(VendorDocumentModalContext);
    return canEdit ? (
        <span
            className={`text-overflow ${styles.LinkDisplay}`}
            onClick={() => modal.open()}
        >
            <i className={"k-icon k-i-pencil"} style={{ marginBottom: 4 }} />{" "}
            {StringHelper.isNullOrEmpty(formData.address.fullAddress)
                ? "No Address Provided"
                : formData.address.fullAddress}
        </span>
    ) : (
        <span className={`text-overflow`}>
            {StringHelper.isNullOrEmpty(formData.address.fullAddress)
                ? "No Address Provided"
                : formData.address.fullAddress}
        </span>
    );
};

/**
 * @function AddressModal
 * @description Modal for editing an address
 * @param props.close {function} - close function
 * @param props.visible {boolean} - visible state
 * @param props.record {object} - record object
 * @return {React.JSX.Element}
 * @constructor
 */
const AddressModal = (props) => {
    const { close, visible } = props;
    const [data, setData] = useState(undefined);
    const { reloadDocumentInformation, setFormData, formData } = useContext(
        VendorDocumentModalContext
    );

    useEffect(() => {
        setData({
            address: formData.address,
        });
    }, [visible]);

    const onClose = () => {
        setData(undefined);
        close();
    };

    const onSubmit = () => {
        setFormData({
            ...formData,
            address: {
                ...data.address,
                fullAddress: addressFormatter(data.address),
            },
        });
        reloadDocumentInformation();
    };

    const validate = (data) => {
        return (
            data.address1 !== undefined &&
            data.city !== undefined &&
            data.state !== undefined &&
            data.zipCode !== undefined
        );
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={"Edit Address"}
                onClose={onClose}
                size={"small"}
            >
                <FormElement>
                    <Field
                        name={"address"}
                        component={AddressInput}
                        label={"Address"}
                        isRequired={true}
                        layout={InputLayouts.Vertical}
                        onCustomChange={(value) => {
                            setData({
                                ...data,
                                address: value,
                                isValid: validate(value),
                            });
                        }}
                    />
                </FormElement>
                <FormButtons
                    loaderVisible={false}
                    actionOnCancel={onClose}
                    allowSubmit={data.isValid}
                    isCreate={false}
                    text={"Apply Changes"}
                    onSubmit={onSubmit}
                />
            </ResponsiveDialog>
        )
    );
};
