import React from "react";
import { Route } from "react-router-dom";
import NoPermissionComponent from "../components/Deprecated/NoPermissionComponent";
import PermissionsHelper from "../resources/PermissionsHelper.js";
import { useUserPermissions } from "../hooks/useUserPermissions.js";

const PermissionRoute = ({
    component: Component,
    requiredPermissions,
    optionalPermissions,
    ...rest
}) => {
    const hasPermission = PermissionsHelper.hasPermission(
        useUserPermissions(),
        requiredPermissions,
        optionalPermissions
    );

    return (
        <Route
            {...rest}
            render={(props) =>
                hasPermission ? (
                    <Component {...props} />
                ) : (
                    <NoPermissionComponent />
                )
            }
        />
    );
};

export default PermissionRoute;
