import styles from "../UserDetails.module.scss";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../../../providers/Deprecated/Authentication/User/UserProvider";
import EmployeeDetails from "../../../../HumanResources/Employee/Components/EmployeeDetails";
import { ActionButton } from "../../../../../components/Buttons/ActionButton.jsx";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum.js";

function UserEmployeeDetails() {
    const userContext = useContext(UserContext);
    /** @type {User} */
    const user = userContext.user;

    const history = useHistory();

    return (
        <div className={styles.UserEmployeeDetailsCardFullContainer}>
            <div
                style={{
                    alignSelf: "flex-end",
                }}
            >
                <ActionButton
                    requiredPermissions={[PermissionsEnum.ViewEmployee]}
                    style={{
                        justifySelf: "right",
                    }}
                    onClick={() =>
                        history.push(
                            `/HumanResources/Employee/${user?.activeEmployeeId}`
                        )
                    }
                >
                    View Employee Page
                </ActionButton>
            </div>
            <EmployeeDetails />
        </div>
    );
}

export default UserEmployeeDetails;
