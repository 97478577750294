import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Field } from "@progress/kendo-react-form";
import { Checkbox, TextArea } from "@progress/kendo-react-inputs";
import {
    FormInput,
    FormPhoneInput,
} from "../../../../components/Deprecated/FormComponents";
import {
    emailFormatValidator,
    nameValidator,
} from "../../../../resources/Deprecated/formValidators";
import { AddressInput } from "../../../../components/FormInputs/AddressInput";
import { InputLayouts } from "../../../../components/FormInputs/InputLayouts";

const CompanyInformationForm = ({ formRenderProps }) => {
    return (
        <GridLayout
            rows={[{ height: "50px" }, { height: "auto" }, { height: "auto" }]}
            cols={[{ width: "48%" }, { width: "48%" }]}
            gap={{
                rows: "15px", // Increased gap for more space between fields
                cols: "20px",
            }}
        >
            {/* Title */}
            <GridLayoutItem col={1} row={1} colSpan={2}>
                <div style={{ textAlign: "center" }}>
                    <h2
                        style={{
                            margin: "0",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                        }}
                    >
                        Company Information:
                    </h2>
                </div>
            </GridLayoutItem>

            {/* Left Column: Vendor Name and Address */}
            <GridLayoutItem col={1} row={2} colSpan={1}>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: "5px" }}
                >
                    <Field
                        name={"name"}
                        component={FormInput}
                        label={"Vendor Name:"}
                        validator={nameValidator}
                    />
                    <Field
                        name={"address"}
                        component={AddressInput}
                        layout={InputLayouts.Vertical}
                        isRequired={true}
                        formRenderProps={formRenderProps}
                    />
                </fieldset>
            </GridLayoutItem>

            {/* Right Column: Phone, Email, Website, and Checkbox */}
            <GridLayoutItem col={2} row={2} colSpan={1}>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: "5px" }}
                >
                    {/* Phone Type and Phone Number */}
                    <Field
                        name={"businessPhone"}
                        component={FormPhoneInput}
                        formRenderProps={formRenderProps}
                        isRequired={true}
                    />

                    {/* Business Email */}
                    <Field
                        name={"businessEmailAddress"}
                        component={FormInput}
                        label={"Business Email:"}
                        validator={emailFormatValidator}
                        hint={"Hint: Primary company email, must be valid."}
                    />

                    {/* Business Website */}
                    <Field
                        name={"businessWebsite"}
                        component={FormInput}
                        label={"Business Website:"}
                        style={{ marginBottom: "10px" }}
                    />

                    {/* Checkbox */}
                    <Field
                        name={"usableForFieldVendorDocument"}
                        component={Checkbox}
                        label={"Can be used for Field PO"}
                        rounded={"large"}
                        size={"medium"}
                        className={"JustifyLeftAlignCenter"}
                    />
                </fieldset>
            </GridLayoutItem>

            {/* Notes Section */}
            <GridLayoutItem col={1} row={3} colSpan={2}>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: "10px" }}
                >
                    <div style={{ textAlign: "left" }}>
                        <h3
                            style={{
                                margin: "0",
                                marginTop: "5px",
                                marginBottom: "8px",
                                borderBottom: "1px solid #ccc",
                                paddingBottom: "3px",
                                width: "100%",
                            }}
                        >
                            Notes:
                        </h3>
                    </div>
                    <Field
                        name={"notes"}
                        component={TextArea}
                        autoSize={true}
                        rows={4}
                        label={"Notes:"}
                    />
                </fieldset>
            </GridLayoutItem>
        </GridLayout>
    );
};

export default CompanyInformationForm;
