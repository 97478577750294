import React, { createContext, useState } from "react";

export const AxiosErrorContext = createContext(null);

const AxiosErrorProvider = ({ children }) => {
    const [axiosErrors, setAxiosErrors] = useState([]);

    const addAxiosError = (error) => {
        setAxiosErrors([...axiosErrors, error]);
    };

    const removeAxiosError = (error) => {
        setAxiosErrors(axiosErrors.filter((e) => e !== error));
    };

    const value = {
        addAxiosError,
        removeAxiosError,
        axiosErrors,
    };

    return (
        <AxiosErrorContext.Provider value={value}>
            {children}
        </AxiosErrorContext.Provider>
    );
};

export default AxiosErrorProvider;
