import React, { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import CompanyInformationForm from "./CompanyInformationForm";
import BillingInformationForm from "./BillingInformationForm";
import ContactInformationForm from "./ContactInformationForm";
import VendorSocialMediaForm from "./VendorSocialMediaForm";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import {
    getVendor,
    updateVendor,
} from "../../../../services/Deprecated/inventory/vendorService";
import { ContactType } from "../../../../resources/Enums/ContactType";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";
import { ActionButtonContainer } from "../../../../components/Buttons/ActionButtonContainer.jsx";
import { VendorModalHelper } from "../Helpers/VendorModalHelper.js";

const VendorEditComponent = ({
    visible,
    onCancel,
    record,
    loaderVisible,
    toggleLoaderVisible,
}) => {
    const [currentContext, setCurrentContext] = React.useState("Company");
    const { triggerReload } = useContext(ReloadDataContext);
    const [vendor, setVendor] = useState(undefined);

    useEffect(() => {
        if (!visible || !record || !record.id) return;

        getVendor(record.id).then((res) => {
            setVendor(res.data);
        });
    }, [visible, record]);

    const onContextSwitch = (context) => {
        setCurrentContext(context);
    };

    /**
     * Function that will be called when the user submits the vendor form.
     * @Method
     * @param {{
     * paymentType: Object,
     * billingTerm: Object,
     * paymentTypeId: string,
     * vendorId: string,
     * billingTermId: string,
     * vendorSocialMedias: Array<{ socialMediaId: string, socialMedia: socialMedia, previousSocialMediaId: string }>
     * }} dataItem - Dropdown Data from API.
     * @typedef {Object} socialMedia
     * @property {socialMediaId: string}
     */
    const submitVendor = async (dataItem) => {
        toggleLoaderVisible(true);

        const data = VendorModalHelper.formatDataForNetwork(dataItem);

        await updateVendor(data).then(() => {
            triggerReload();
            toggleLoaderVisible(false);
            setVendor(undefined);
            onCancel();
        });
    };

    return (
        <div>
            {visible && vendor && (
                <Form
                    initialValues={vendor}
                    onSubmit={submitVendor}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Edit Vendor: " + vendor.name}
                            onClose={onCancel}
                            size={"large"}
                        >
                            <ActionButtonContainer>
                                <ActionButton
                                    themeColor={
                                        currentContext === "Company"
                                            ? "info"
                                            : "primary"
                                    }
                                    onClick={() => onContextSwitch("Company")}
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Company
                                </ActionButton>
                                <ActionButton
                                    themeColor={
                                        currentContext === "Billing"
                                            ? "info"
                                            : "primary"
                                    }
                                    onClick={() => onContextSwitch("Billing")}
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Billing
                                </ActionButton>
                                <ActionButton
                                    themeColor={
                                        currentContext === "Contact"
                                            ? "info"
                                            : "primary"
                                    }
                                    onClick={() => onContextSwitch("Contact")}
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Contact
                                </ActionButton>
                            </ActionButtonContainer>
                            <FormElement>
                                {currentContext === "Company" && (
                                    <CompanyInformationForm
                                        formRenderProps={formRenderProps}
                                    />
                                )}
                                {currentContext === "Billing" && (
                                    <BillingInformationForm />
                                )}
                                {currentContext === "Contact" && (
                                    <span>
                                        <ContactInformationForm
                                            formRenderProps={formRenderProps}
                                        />
                                        <VendorSocialMediaForm
                                            formRenderProps={formRenderProps}
                                        />
                                    </span>
                                )}
                                <FormButtons
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={onCancel}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    isCreate={false}
                                />
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default VendorEditComponent;
