import React, { useContext } from "react";
import { TabStripTab } from "@progress/kendo-react-layout";
import JobDetails from "./JobDetails/JobDetails.jsx";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider.jsx";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider.jsx";
import { CustomerTypeEnum } from "../../../../resources/Enums/CustomerStatusEnum.js";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";

const JobTabs = () => {
    const { job } = useContext(JobContext);
    const { customer } = useContext(CustomerContext);

    return (
        <HighlightedTabStrip
            className="tabs"
            style={{
                height: "430px",
                maxWidth: "1180px",
                width: "100%",
            }}
        >
            <TabStripTab title="Details">
                <JobDetails
                    job={job}
                    customer={customer}
                    includeJobContactInformation={
                        customer.type === CustomerTypeEnum.Enum.Business
                    }
                />
            </TabStripTab>
        </HighlightedTabStrip>
    );
};

export default JobTabs;
