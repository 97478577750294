import React, { useEffect, useState } from "react";
import {
    ListBox,
    ListBoxToolbar,
    processListBoxData,
} from "@progress/kendo-react-listbox";
import { Dialog } from "@progress/kendo-react-dialogs";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons"; // Assuming the path is correct
import {
    getAllEmployees,
    getAllEmployeesForTasks,
    setEmployeesForTask,
} from "../../../../../../services/Deprecated/humanResources/employeeServices";

const SELECTED_FIELD = "selected";

const EmployeeSkillModal = (props) => {
    const { id, close, visible } = props;
    const [listBoxState, setListBoxState] = useState({
        hasSkillset: [],
        noSkillset: [],
        draggedItem: {},
    });
    const [loading, setLoading] = useState(false); // To control the loader visibility

    useEffect(() => {
        if (visible && id) {
            const fetchData = async () => {
                try {
                    const employeesForTaskResponse =
                        await getAllEmployeesForTasks([id]);
                    const employeesResponse = await getAllEmployees();

                    // Get employees who are not on the task
                    const employeesNotOnTask = employeesResponse.data.filter(
                        (employee) =>
                            !employeesForTaskResponse.data.some(
                                (employeeForTask) =>
                                    employeeForTask.id === employee.id
                            )
                    );

                    setListBoxState({
                        hasSkillset: employeesForTaskResponse.data,
                        noSkillset: employeesNotOnTask,
                        draggedItem: {},
                    });
                } catch (error) {
                    console.error("Error fetching employees:", error);
                }
            };
            fetchData();
        }
    }, [visible, id]);

    const handleItemClick = (event, data, connectedData) => {
        setListBoxState((prevState) => ({
            ...prevState,
            [data]: prevState[data].map((item) => {
                if (item.id === event.dataItem.id) {
                    // Match by ID or unique field
                    return {
                        ...item,
                        [SELECTED_FIELD]: !item[SELECTED_FIELD],
                    };
                } else if (!event.nativeEvent.ctrlKey) {
                    return {
                        ...item,
                        [SELECTED_FIELD]: false,
                    };
                }
                return item;
            }),
            [connectedData]: prevState[connectedData].map((item) => ({
                ...item,
                [SELECTED_FIELD]: false,
            })),
        }));
    };

    const handleToolBarClick = (e) => {
        let toolName = e.toolName || "";
        let result = processListBoxData(
            listBoxState.hasSkillset,
            listBoxState.noSkillset,
            toolName,
            SELECTED_FIELD
        );
        setListBoxState({
            ...listBoxState,
            hasSkillset: result.listBoxOneData,
            noSkillset: result.listBoxTwoData,
        });
    };

    const handleSave = async () => {
        setLoading(true);
        const allowedEmployeeIds = listBoxState.hasSkillset.map(
            (emp) => emp.id
        );
        setEmployeesForTask(id, allowedEmployeeIds).then(() => {
            setLoading(false);
            close();
        });
    };

    return (
        visible && (
            <Dialog
                title="Manage Employee Skillsets"
                onClose={close}
                width={600}
                open={open}
            >
                <div className="listbox-container">
                    <div className="col k-pr-2">
                        <h5>Has Skillset</h5>
                        <ListBox
                            style={{ height: 400, width: "100%" }}
                            data={listBoxState.hasSkillset}
                            textField="fullName"
                            selectedField={SELECTED_FIELD}
                            onItemClick={(e) =>
                                handleItemClick(e, "hasSkillset", "noSkillset")
                            }
                        />
                    </div>
                    <div className="col k-pr-2">
                        <h5>Does Not Have Skillset</h5>
                        <ListBox
                            style={{ height: 400, width: "100%" }}
                            toolbar={() => (
                                <ListBoxToolbar
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    tools={[
                                        "transferTo",
                                        "transferFrom",
                                        "transferAllTo",
                                        "transferAllFrom",
                                    ]}
                                    data={listBoxState.hasSkillset}
                                    dataConnected={listBoxState.noSkillset}
                                    onToolClick={handleToolBarClick}
                                />
                            )}
                            data={listBoxState.noSkillset}
                            textField="fullName"
                            selectedField={SELECTED_FIELD}
                            onItemClick={(e) =>
                                handleItemClick(e, "noSkillset", "hasSkillset")
                            }
                        />
                    </div>
                </div>
                <div
                    className="button-container"
                    style={{ textAlign: "right" }}
                >
                    <FormButtons
                        isCreate={false} // Set to true if this is a creation form
                        allowSubmit={true} // Adjust based on form validation
                        loaderVisible={loading}
                        actionOnCancel={close}
                        onSubmit={handleSave}
                        text={"Save"} // Custom button text, optional
                    />
                </div>
            </Dialog>
        )
    );
};

export default EmployeeSkillModal;
