import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../../../../../../../providers/ReloadDataProvider.jsx";
import ResponsiveDialog from "../../../../../../../../../components/Deprecated/DialogWrapper.jsx";
import { FormButtons } from "../../../../../../../../../components/Buttons/FormButtons.jsx";
import { ContentHeader } from "../../../../../../../../../components/ContentHeader.jsx";
import { RadioGroup } from "@progress/kendo-react-inputs";
import {
    FormInput,
    FormNumericInput,
} from "../../../../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../../../../resources/Deprecated/formValidators.js";
import InventoryService from "../../../../../../../../../services/Deprecated/inventory/InventoryService.js";

export const SqftDeAllocationModal = (props) => {
    const { close, visible, record } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [radioValue, setRadioValue] = useState(0);

    useEffect(() => {
        if (!record) return;

        setInitialFormData({
            jobId: record.jobId,
            storageLocationId: record?.storageLocation?.id,
            item: record.item,
            partial: {
                identifier: `${crypto.randomUUID()}`.slice(0, 8),
            },
            full: {
                identifier: record.identifier,
                width: record.width,
                length: record.length,
            },
        });
    }, [record]);

    const onClose = () => {
        setRadioValue(0);
        setLoaderVisible(false);
        setInitialFormData(undefined);
        close();
    };

    const onSubmit = (e) => {
        const values = e.values;

        setLoaderVisible(true);
        if (radioValue === 1) {
            if (!e.isValid) {
                setLoaderVisible(false);
                return;
            }
            const data = {
                partialWidth: values.partialWidth,
                partialLength: values.partialLength,
                partialIdentifier: values.partial.identifier,
                identifier: values.full.identifier,
                jobId: values.jobId,
                storageLocationId: values.storageLocationId,
                itemId: values.item.id,
            };

            InventoryService.partialDeAllocate(data).then(() => {
                triggerReload();
                onClose();
            });
            return;
        }

        InventoryService.deallocate({
            storageLocationId: initialFormData.storageLocationId,
            jobId: initialFormData.jobId,
            itemId: initialFormData.item.id,
            quantity: 1,
            identifier: initialFormData.full.identifier,
        }).then(() => {
            triggerReload();
            onClose();
        });
    };

    const onRadioChange = (e) => setRadioValue(e.value);

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={"Return To Stock"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmitClick={onSubmit}
                    render={() => (
                        <FormElement
                            className={
                                "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                            }
                        >
                            <span
                                style={{
                                    width: "100%",
                                }}
                            >
                                <ContentHeader
                                    title={`Returning ${initialFormData.item.name} - ${initialFormData.full.identifier}`}
                                    style={{
                                        fontSize: "18px",
                                        textAlign: "left",
                                    }}
                                />
                                <RadioGroup
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                    }}
                                    data={[
                                        {
                                            label: "Return Full Item",
                                            value: 0,
                                        },
                                        {
                                            label: "Return Partial Item",
                                            value: 1,
                                        },
                                    ]}
                                    defaultValue={0}
                                    onChange={onRadioChange}
                                />
                                {radioValue === 1 && (
                                    <>
                                        <hr
                                            className={"Separator"}
                                            style={{
                                                marginTop: "15px",
                                                marginBottom: "15px",
                                            }}
                                        />
                                        <Field
                                            name={"partial.identifier"}
                                            component={FormInput}
                                            label={"Partial Identifier:"}
                                            validator={requiredValidator}
                                            maxLength={8}
                                            minLength={0}
                                        />
                                        <Field
                                            name={"partialWidth"}
                                            component={FormNumericInput}
                                            label={"Partial Length (Inch):"}
                                            min={0}
                                            max={
                                                initialFormData.full.length - 1
                                            }
                                            format={"0"}
                                            validator={requiredValidator}
                                        />
                                        <Field
                                            name={"partialLength"}
                                            component={FormNumericInput}
                                            label={"Partial Width (Inch):"}
                                            min={0}
                                            max={initialFormData.full.width - 1}
                                            format={"0"}
                                            validator={requiredValidator}
                                        />
                                    </>
                                )}
                            </span>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={true}
                                text={"Return To Inventory"}
                                isCreate={false}
                                theme={"primary"}
                                icon={"k-icon k-i-check"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
