import React, { createContext, useContext, useEffect, useState } from "react";
import { ReloadDataContext } from "../../ReloadDataProvider";
import { getAllPhoneTypes } from "../../../services/Deprecated/general/phoneTypeServices.js";
import BusinessInformationService from "../../../services/Deprecated/customer/businessInformationService.js";
import ChartOfAccountsService from "../../../services/Deprecated/accounting/ChartOfAccountsService.js";
import { getCustomer } from "../../../services/Deprecated/customer/customerServices.js";

export const CustomerContext = createContext(null);

const CustomerProvider = ({ children }) => {
    const reloadDataContext = useContext(ReloadDataContext);
    const [relationships, setRelationships] = useState({});
    const [relationshipsLoading, setRelationshipsLoading] = useState(true);
    const [businessInfoReload, setBusinessInfoReload] = useState(1);
    const [customer, setCustomer] = useState({});
    const [customerId, setCustomerId] = useState();

    const reloadBusinessInfo = () => {
        setBusinessInfoReload(Math.random());
    };

    useEffect(() => {
        const fetchData = async () => {
            setRelationshipsLoading(true);
            const phoneTypes = await getAllPhoneTypes();
            const businessInfos = (
                await BusinessInformationService.getAllBusinessInformations()
            ).data;
            const incomeAccountServiceResponse =
                await ChartOfAccountsService.getAllIncomeAccounts();
            const expenseAccounts =
                await ChartOfAccountsService.getAllExpenseAccounts();
            setRelationships({
                phoneTypes,
                businessInfos,
                incomeAccounts: incomeAccountServiceResponse.data,
                expenseAccounts,
            });
            setRelationshipsLoading(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchBusinessInfo = async () => {
            setRelationshipsLoading(true);
            const businessInfos = (
                await BusinessInformationService.getAllBusinessInformations()
            ).data;
            setRelationships({
                ...relationships,
                businessInfos,
            });
            setRelationshipsLoading(false);
        };
        fetchBusinessInfo();
    }, [businessInfoReload]);

    useEffect(() => {
        if (!customerId) {
            setCustomer({ redirectHome: true });
            return;
        }

        getCustomer(customerId).then((res) => {
            if (!res.success) {
                setCustomer({});
                return;
            }

            setCustomer(res.data);
        });
    }, [customerId, reloadDataContext.reloadData]);

    const value = {
        relationships,
        relationshipsLoading,
        customer,
        setCustomer,
        setCustomerId,
        reloadBusinessInfo,
    };

    return (
        <CustomerContext.Provider value={value}>
            {children}
        </CustomerContext.Provider>
    );
};

export default CustomerProvider;
