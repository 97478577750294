import { BaseEnum } from "./BaseEnum.js";

export const ItemTypeEnum = {
    Item: 0,
    Shipping: 1,
    Tax: 2,
    Discount: 3,
    Note: 4,
    Adjustment: 5,
    PercentageDiscount: 6,
    VendorBillLineItem: 7,
};

export class NewItemTypeEnum extends BaseEnum {
    static Enum = {
        Item: 0,
        Shipping: 1,
        Tax: 2,
        CashDiscount: 3,
        Note: 4,
        Adjustment: 5,
        PercentageDiscount: 6,
        VendorBillLineItem: 7,
    };
}
