import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider";
import { FormDropDown } from "../../../../../../components/Deprecated/FormComponents";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper";
import { getAllTasks } from "../../../../../../services/Deprecated/customer/eventServices";
import { addTaskToTaskCategory } from "../../../../../../services/Deprecated/calendar/eventTaskCategory";

export const AddTaskModal = (props) => {
    const { id, close, visible, record } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [data, setData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        if (!visible) return;

        getAllTasks().then((res) => {
            const data = res?.data ?? [];
            const filteredData = data.filter((t) => {
                return !record.taskCategory.tasks.some((tc) => tc.id === t.id);
            });

            setData(filteredData);
        });
    }, [visible, id]);

    const onClose = () => {
        setLoaderVisible(false);
        setData(undefined);
        close();
    };

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        const data = {
            ...dataItem,
            taskCategoryId: record.taskCategory.id,
            taskId: dataItem.task.id,
        };

        delete data.category;
        delete data.task;

        addTaskToTaskCategory(data.taskCategoryId, data.taskId).then(() => {
            triggerReload();
            setLoaderVisible(false);
            onClose();
        });
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={"Add Task"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div style={{ width: "100%" }}>
                                <Field
                                    name={"task"}
                                    component={FormDropDown}
                                    data={data}
                                    textField={"name"}
                                    valueField={"id"}
                                    label={"Task"}
                                    validator={requiredValidator}
                                />
                            </div>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={true}
                                text={"Add"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
