import React, { useContext } from "react";
import { BreadcrumbContext } from "../providers/BreadCrumbProvider";
import "../styles/CommonTextStyles.scss";
import PermissionsHelper from "../resources/PermissionsHelper.js";
import { useUserPermissions } from "../hooks/useUserPermissions.js";

/**
 * @function LinkTag
 * @description A component that renders a link tag
 * @param [props.breadCrumbInfo] {Object} - The breadcrumb information for changing the route
 * @param props.text {String} - The text to display
 * @param [props.onClick] {Function} - The function to call when the link is clicked
 * @param [props.requiredPermissions] {Array} - The permissions required to display the link
 * @param [props.optionalPermissions] {Array} - The permissions that can display the link
 * @return {Element}
 * @constructor
 */
export default function LinkTag(props) {
    const {
        breadCrumbInfo,
        text,
        onClick,
        requiredPermissions = [],
        optionalPermissions = undefined,
    } = props;
    const { handleRouteChange } = useContext(BreadcrumbContext);

    const handleClick = () => {
        if (breadCrumbInfo) {
            handleRouteChange(breadCrumbInfo);
        }

        if (onClick) {
            onClick();
        }
    };

    if (requiredPermissions.length > 0 || optionalPermissions) {
        let hasRequiredPermissions = PermissionsHelper.hasPermission(
            useUserPermissions(),
            requiredPermissions,
            optionalPermissions
        );

        if (!hasRequiredPermissions) {
            return <span>{text}</span>;
        }
    }

    return (
        <a onClick={handleClick} className={"Link"}>
            {text}
        </a>
    );
}
