import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Field } from "@progress/kendo-react-form";
import {
    emailValidator,
    requiredValidator,
} from "../../resources/Deprecated/formValidators.js";
import { FormInput, FormPhoneInput } from "../Deprecated/FormComponents.jsx";

/**
 * @function ContactInput
 * @description A form input that handles the form logic for a Contact
 * @param props.style {object} - the style object for the component
 * @param props.className {string} - the class name for the component
 * @param props.formRenderProps {object} - the form render props object
 * @param props.isRequired {boolean} - whether the input is required
 * @param props.field {string} - the field to render
 * @param props.disabled {boolean} - whether the inputs are disabled
 * @param props.keepValue {boolean} - whether to keep the value of the input after it is disabled
 * @param props.mustHavePhoneAndEmail {boolean} - whether the contact must have a phone number and email
 * @return {Element}
 * @constructor
 */
export const ContactInput = (props) => {
    const {
        style,
        formRenderProps,
        isRequired,
        className,
        field,
        disabled,
        keepValue = true,
        mustHavePhoneAndEmail = false,
    } = props;
    const fields = useRef({
        name1: field ? `${field}.name1` : "name1",
        name2: field ? `${field}.name2` : "name2",
        email: field ? `${field}.email` : "email",
        phone: field ? `${field}.phone` : "phone",
    });
    const [validationState, setValidationState] = useState({
        name1: false,
        email: false,
        phone: false,
    });

    useEffect(() => {
        if (isRequired) {
            setValidationState({
                name1: !!formRenderProps.valueGetter(fields.current.name1),
                email: !!formRenderProps.valueGetter(fields.current.email),
                phone: !!formRenderProps.valueGetter(fields.current.phone),
            });
        }
    }, []);

    /**
     * Handles the change event for the input, helps with validation
     * @param field {string} - the field that was changed
     * @param e {object} - the event object
     */
    const handleChange = (field, e) => {
        let isPresent = !!e.value;
        if (field.includes("phone")) {
            isPresent = !!e.value?.phoneNumber;
        }

        const newValidationState = {
            ...validationState,
            [field]: isPresent,
        };
        setValidationState(newValidationState);
    };

    const semiOptionalEmailValidator = useMemo(() => {
        if (mustHavePhoneAndEmail && (isRequired || validationState.name1)) {
            return emailValidator;
        }

        if ((isRequired || validationState.name1) && !validationState.phone) {
            return emailValidator;
        } else {
            return undefined;
        }
    }, [validationState, isRequired]);

    const phoneIsRequired = useMemo(() => {
        if (mustHavePhoneAndEmail) {
            return isRequired || validationState.name1;
        }

        return (isRequired || validationState.name1) && !validationState.email;
    }, [validationState, isRequired]);

    return (
        <span style={style} className={className}>
            <span className={"JustifyLeftAlignCenter SmallGap"}>
                <span
                    style={{
                        width: "50%",
                    }}
                >
                    <Field
                        name={fields.current.name1}
                        component={FormInput}
                        label={"First"}
                        validator={isRequired && requiredValidator}
                        onChange={(e) => handleChange("name1", e)}
                        disabled={disabled}
                        keepValue={keepValue}
                    />
                </span>
                <span
                    style={{
                        width: "50%",
                    }}
                >
                    <Field
                        name={fields.current.name2}
                        component={FormInput}
                        label={"Last"}
                        disabled={disabled}
                        keepValue={keepValue}
                    />
                </span>
            </span>
            <Field
                name={fields.current.email}
                component={FormInput}
                label={"Email"}
                validator={semiOptionalEmailValidator}
                onChange={(e) => handleChange("email", e)}
                disabled={disabled}
                keepValue={keepValue}
            />
            <Field
                name={fields.current.phone}
                component={FormPhoneInput}
                label={"Phone"}
                isRequired={phoneIsRequired}
                formRenderProps={formRenderProps}
                disabled={disabled}
                keepValue={keepValue}
                onChange={(e) => handleChange("phone", e)}
            />
        </span>
    );
};
