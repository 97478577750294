import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Error, Hint } from "@progress/kendo-react-labels";
import {
    Input,
    MaskedTextBox,
    NumericTextBox,
    TextArea,
} from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Upload } from "@progress/kendo-react-upload";
import { Field } from "@progress/kendo-react-form";
import {
    phoneNumberWithTypeValidator,
    phoneTypeValidator,
    requiredValidator,
} from "../../resources/Deprecated/formValidators";
import { getAllPhoneTypes } from "../../services/Deprecated/general/phoneTypeServices";
import {
    filterBy,
    toDataSourceRequestString,
} from "@progress/kendo-data-query";
import clone from "just-clone";
import { axiosInstance as axios } from "../../services/axiosInstance";
import { stateList } from "../../resources/Deprecated/states";
import { TimePicker } from "@progress/kendo-react-dateinputs";

export const FormInput = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        type,
        value,
        keepValue,
        onFocus,
        ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const disabledBool = disabled === true;
    const newValue = disabledBool
        ? keepValue
            ? value ?? undefined
            : label
        : value ?? undefined;

    const handleFocus = (e) => {
        if (type === "number") {
            console.warn("use FormNumericInput for number inputs");
            e.nativeEvent.target.select(); // Highlight the entire text on focus
        }

        if (typeof onFocus === "function") {
            onFocus(e);
        }
    };

    return (
        <div
            style={{
                width: "auto",
            }}
        >
            <Input
                valid={valid}
                type={type}
                label={label}
                id={id}
                disabled={disabledBool}
                ariaDescribedBy={`${hintId} ${errorId}`}
                value={newValue}
                onFocus={handleFocus}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </div>
    );
};

export const FormMaskedTextBox = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        hint,
        value,
        ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    return (
        <div className={"k-form-field-wrap"} style={{ width: "100%" }}>
            <MaskedTextBox
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                label={label}
                value={value ?? undefined}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </div>
    );
};

export const FormDateInput = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        hint,
        value,
        ...others
    } = fieldRenderProps;
    const maskedValue = value != null ? value.toString() : "";

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    return (
        <div className={"k-form-field-wrap"}>
            <MaskedTextBox
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                label={label}
                value={maskedValue}
                mask={"00/00/0000"}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </div>
    );
};

/**
 * @function FormRemoteDropDown
 * @description A form component that fetches data from a remote source
 * @param {string} fieldRenderProps.validationMessage
 * @param {boolean} fieldRenderProps.touched
 * @param {string} fieldRenderProps.label
 * @param {string} fieldRenderProps.id
 * @param {boolean} fieldRenderProps.valid
 * @param {boolean} fieldRenderProps.disabled
 * @param {boolean} fieldRenderProps.showDisabledValue
 * @param {string} fieldRenderProps.hint
 * @param {string} fieldRenderProps.type
 * @param {string} fieldRenderProps.value
 * @param {string} fieldRenderProps.url
 * @param {function} fieldRenderProps.onFocus
 * @param {function} fieldRenderProps.onFilterChange
 * @param {function} fieldRenderProps.dataMutator
 * @param {string} fieldRenderProps.dataItemKey
 * @param {string} fieldRenderProps.textField
 * @param {string[]} fieldRenderProps.searchFields
 * @param {object[]} fieldRenderProps.initialFilters
 * @param {object[]} fieldRenderProps.initialSort
 * @param {object} fieldRenderProps.others
 * @return {Element}
 * @constructor
 */
export const FormRemoteDropDown = (fieldRenderProps) => {
    const defaultTextField = "name";
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        showDisabledValue,
        hint,
        type,
        value,
        url,
        onFocus,
        onFilterChange,
        dataMutator,
        dataItemKey = "id",
        textField = defaultTextField,
        searchFields = [defaultTextField],
        initialFilters = [],
        initialSort = [
            {
                field: fieldRenderProps.textField ?? defaultTextField,
                dir: "asc",
            },
        ],
        ...others
    } = fieldRenderProps;

    if (!url) {
        throw new ReferenceError("url is not defined.");
    }

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const shownValue = showDisabledValue ? value : undefined;

    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = React.useState([]);

    const initialSkip = 0;
    const initialTake = 400;
    const initialTotal = 100;

    const [dataState, setDataState] = useState({
        skip: initialSkip,
        take: initialTake,
        total: initialTotal,
        loading: false,
        filter: initialFilters,
        sort: initialSort,
    });

    // Fetch data when the component is mounted
    // Also fetch data when the dataState changes (filter, page change, ect), or manually reload the data
    useEffect(() => {
        // if disabled, don't fetch data
        if (disabled) {
            setDataState({
                ...dataState,
                loading: false,
            });
        }

        fetchData();
    }, [dataState.sort, dataState.filter, disabled]);

    const fetchData = useMemo(() => {
        return () => {
            // if not actively loading, stop request
            if (!dataState.loading) return;

            const requestDataState = clone(dataState);
            if (requestDataState.filter) {
                requestDataState.filter = {
                    logic: "or",
                    filters: Array.isArray(requestDataState.filter)
                        ? requestDataState.filter
                        : [requestDataState.filter],
                };
            }

            const requestUrl =
                url + `${toDataSourceRequestString(requestDataState)}`;

            axios
                .get(requestUrl)
                .then(({ data }) => {
                    if (dataMutator) {
                        data.data = dataMutator(data.data);
                    }

                    setData(data.data);
                    setDataState({
                        ...dataState,
                        total: data.total,
                    });
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setDataState({
                        ...dataState,
                        loading: false,
                    });
                });
        };
    }, [dataState, url]);

    const onDropdownFilterChange = (event) => {
        setSearchTerm(event.filter.value);

        if (typeof onFilterChange === "function") {
            onFilterChange(event);
        }
    };

    useEffect(() => {
        if (disabled) {
            return;
        }

        const delaySearch = setTimeout(() => {
            let filters = [];
            let filter = { filters, logic: "or" };

            (searchFields ?? [textField]).forEach((searchField) => {
                const splitSearchTerms = searchTerm.split(" ");
                filters.push({
                    logic: "and",
                    filters: [
                        initialFilters,
                        ...splitSearchTerms.map((splitSearchTerm) => ({
                            field: searchField,
                            operator: "contains",
                            value: splitSearchTerm,
                        })),
                    ].flat(),
                });
            });

            setDataState({
                ...dataState,
                filter: filter,
                skip: 0, //reset pagination on filter change
                take: initialTake,
                loading: true,
            });
        }, 500);

        return () => clearTimeout(delaySearch);
    }, [searchTerm, disabled]);

    const onOpen = (_event) => {
        setDataState({
            ...dataState,
            skip: 0,
            take: initialTake,
            loading: true,
        });
    };

    const onClose = (_event) => {
        setDataState({
            ...dataState,
            skip: 0,
            take: initialTake,
            loading: false,
        });
    };

    const ddlRef = React.useRef();
    const onDropdownFocus = (event) => {
        // sometimes forms override onFocus, so we need to make sure to do
        // the passed in version, and the ref call to open the DDL
        if (typeof onFocus == "function") {
            onFocus();
        }

        // wait a split second before opening to allow the onOpen to fire
        // if it isn't open, use toggleBtnClick to open the DDL on focus
        setTimeout(() => {
            if (!event.target.state.opened) {
                ddlRef?.current?.toggleBtnClick();
            }
        }, 100);
    };

    return (
        <div>
            <ComboBox
                ref={ddlRef}
                data={data}
                valid={valid}
                type={type}
                label={label}
                id={id}
                disabled={disabled}
                ariaDescribedBy={`${hintId} ${errorId}`}
                dataItemKey={dataItemKey}
                textField={textField}
                filterable={true}
                value={disabled ? shownValue : value ?? undefined}
                onOpen={onOpen}
                onClose={onClose}
                onFilterChange={onDropdownFilterChange}
                onFocus={onDropdownFocus}
                loading={dataState.loading}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </div>
    );
};

export const FormDropDown = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        showDisabledValue,
        hint,
        type,
        value,
        data,
        textField = "name",
        dataItemKey = "id",
        filter = true,
        onFocus,
        ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const shownValue = showDisabledValue ? value : undefined;

    const [filteredData, setFilteredData] = React.useState(data ?? []);

    useEffect(() => {
        // if passed data changes, reset filter to include everything
        setFilteredData(data);
    }, [data]);

    const filterData = (filter) => {
        return filterBy(data, filter);
    };
    const filterChange = (event) => {
        setFilteredData(filterData(event.filter));
    };

    const ddlRef = React.useRef();
    const onDropdownFocus = (event) => {
        // sometimes forms override onFocus, so we need to make sure to do
        // the passed in version, and the ref call to open the DDL
        if (typeof onFocus == "function") {
            onFocus();
        }

        // wait a split second before opening to allow the onOpen to fire
        // if it isn't open, use toggleBtnClick to open the DDL on focus
        setTimeout(() => {
            if (!event.target.state.opened) {
                ddlRef?.current?.toggleBtnClick();
            }
        }, 100);
    };

    return (
        <div>
            <ComboBox
                ref={ddlRef}
                valid={valid}
                type={type}
                label={label}
                id={id}
                data={filteredData}
                textField={textField}
                dataItemKey={dataItemKey}
                filterable={filter}
                onFilterChange={filterChange}
                onFocus={onDropdownFocus}
                disabled={disabled}
                ariaDescribedBy={`${hintId} ${errorId}`}
                value={disabled ? shownValue : value ?? undefined}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </div>
    );
};

export const FormNumericInput = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        type,
        value,
        onFocus,
        ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    const handleFocus = (e) => {
        e.nativeEvent.target.select(); // Highlight the entire text on focus

        if (typeof onFocus === "function") {
            onFocus(e);
        }
    };

    return (
        <div>
            <NumericTextBox
                valid={valid}
                type={type}
                label={label}
                id={id}
                disabled={disabled}
                ariaDescribedBy={`${hintId} ${errorId}`}
                value={value ?? undefined}
                onFocus={handleFocus}
                style={{ textAlign: "right" }} // Right align the text
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </div>
    );
};

export const FormTimePicker = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        type,
        value,
        ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    return (
        <div
            style={{
                width: "100%",
            }}
        >
            <TimePicker
                valid={valid}
                type={type}
                label={label}
                id={id}
                disabled={disabled}
                ariaDescribedBy={`${hintId} ${errorId}`}
                value={value ?? undefined}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </div>
    );
};

export const FormTextArea = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        type,
        value,
        ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    return (
        <div>
            <TextArea
                valid={valid}
                type={type}
                label={label}
                id={id}
                disabled={disabled}
                autoComplete={"off"}
                autoCorrect={"off"}
                ariaDescribedBy={`${hintId} ${errorId}`}
                value={value ?? undefined}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </div>
    );
};

export const FormUpload = ({ fieldRenderProps, multiple, restrictions }) => {
    const { validationMessage, touched, id } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const errorId = showValidationMessage ? `${id}_error` : "";

    const onChangeHandler = (event) => {
        fieldRenderProps.onChange({ value: event.newState });
    };
    const onRemoveHandler = (event) => {
        fieldRenderProps.onChange({ value: event.newState });
    };
    return (
        <div>
            <Upload
                autoUpload={false}
                showActionButtons={false}
                files={fieldRenderProps.value}
                onAdd={onChangeHandler}
                onRemove={onRemoveHandler}
                multiple={multiple}
                restrictions={restrictions}
            />
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </div>
    );
};

export const FormPhoneInput = ({
    name,
    formRenderProps,
    disabled,
    onChange,
    isRequired = false,
}) => {
    const [phoneTypes, setPhoneTypes] = useState([]);
    const [loading, setLoading] = useState(true);

    const phoneInputValue = formRenderProps.valueGetter(name);

    useEffect(() => {
        getAllPhoneTypes()
            .then((res) => {
                setPhoneTypes(res.data);
                setLoading(false);
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        if (phoneInputValue?.phoneNumber === "___-___-____") {
            formRenderProps.onChange(`${name}.phoneNumber`, {
                value: "",
            });
        }
    }, [phoneInputValue?.phoneNumber]);

    useEffect(() => {
        const selectedPhoneType = phoneInputValue?.phoneNumberType;
        const topLevelType = phoneInputValue?.typeId;

        if (!selectedPhoneType && phoneTypes.length > 0 && isRequired) {
            const mobileOrCell = phoneTypes.find(
                (pt) => pt.name === "Mobile" || pt.name === "Cell"
            );
            if (mobileOrCell) {
                formRenderProps.onChange(`${name}.phoneNumberType`, {
                    value: mobileOrCell,
                });
                formRenderProps.onChange(`${name}.typeId`, {
                    value: mobileOrCell.id,
                });
            }
        } else if (selectedPhoneType && !topLevelType) {
            formRenderProps.onChange(`${name}.typeId`, {
                value: selectedPhoneType.id,
            });
        }
        if (onChange && typeof onChange === "function") {
            onChange({
                value: phoneInputValue,
            });
        }
    }, [
        phoneTypes,
        phoneInputValue?.phoneNumber,
        phoneInputValue?.phoneNumberType?.id,
    ]);

    const memoPhoneTypeValidator = useCallback((value, valueGetter) => {
        return phoneTypeValidator(value, valueGetter, `${name}.phoneNumber`);
    }, []);
    const memoPhoneValidator = useCallback(
        (value, valueGetter) => {
            return phoneNumberWithTypeValidator(
                value,
                valueGetter,
                `${name}.phoneNumberType`,
                isRequired
            );
        },
        [isRequired]
    );

    return (
        <div
            style={{
                justifyContent: "start",
                display: "flex",
                gap: "10px",
            }}
        >
            <Field
                name={`${name}.phoneNumber`}
                component={FormMaskedTextBox}
                mask="000-000-0000"
                label={"Phone Number:"}
                style={{ flexGrow: 2 }}
                validator={memoPhoneValidator}
                disabled={disabled}
                keepValue={true}
            />
            <Field
                key={loading}
                name={`${name}.phoneNumberType`}
                component={FormDropDown}
                data={phoneTypes}
                dataItemKey="id"
                textField="name"
                label={"Phone Type:"}
                validator={memoPhoneTypeValidator}
                style={{ flexGrow: 1, minWidth: 125 }}
                disabled={loading || disabled}
                showDisabledValue={true}
            />
        </div>
    );
};

export const FormAddressInput = ({
    name,
    isRequired,
    isDisabled,
    layout = "horizontal",
}) => {
    const addressValidator = useCallback(
        (value) => (isRequired ? requiredValidator(value) : ""),
        [isRequired]
    );

    const isHorizontal = layout === "horizontal";

    return (
        <div
            style={{
                display: "flex",
                flexDirection: isHorizontal ? "row" : "column",
                gap: "10px",
                alignItems: isHorizontal ? "center" : "flex-start",
            }}
        >
            <div style={{ flex: isHorizontal ? "1 1 25%" : "1 1 auto" }}>
                <Field
                    name={`${name}.address1`}
                    component={FormInput}
                    validator={addressValidator}
                    label={"Address1:"}
                    disabled={isDisabled}
                    keepValue={true}
                />
            </div>

            <div style={{ flex: isHorizontal ? "1 1 25%" : "1 1 auto" }}>
                <Field
                    name={`${name}.address2`}
                    component={FormInput}
                    validator={addressValidator}
                    label={"Address2:"}
                    disabled={isDisabled}
                    keepValue={true}
                />
            </div>

            <div style={{ flex: isHorizontal ? "1 1 15%" : "1 1 auto" }}>
                <Field
                    name={`${name}.city`}
                    component={FormInput}
                    validator={addressValidator}
                    label={"City:"}
                    disabled={isDisabled}
                    keepValue={true}
                />
            </div>

            <div style={{ flex: isHorizontal ? "1 1 10%" : "1 1 auto" }}>
                <Field
                    name={`${name}.state`}
                    component={FormDropDown}
                    validator={addressValidator}
                    label={"State:"}
                    data={stateList}
                    textField="name"
                    dataItemKey="name"
                    disabled={isDisabled}
                    showDisabledValue={true}
                />
            </div>

            <div style={{ flex: isHorizontal ? "1 1 10%" : "1 1 auto" }}>
                <Field
                    name={`${name}.zipCode`}
                    component={FormMaskedTextBox}
                    validator={addressValidator}
                    mask="00000"
                    label={"Zip:"}
                    disabled={isDisabled}
                    keepValue={true}
                />
            </div>
        </div>
    );
};
