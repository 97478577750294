import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { axiosInstance as axios } from "../../../../services/axiosInstance";
import {
    ListBox,
    ListBoxToolbar,
    processListBoxData,
} from "@progress/kendo-react-listbox";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { ToastContext } from "../../../../providers/ToastProvider.jsx";

const SELECTED_FIELD = "selected";
const ListBoxComponent = ({ employeeData }) => {
    const { showToast } = useContext(ToastContext);
    // initialize state with passed allowedTasks, initialize assigned and unassigned
    const [listBoxSkillState, setListBoxSkillState] = useState({
        assigned: [],
        unassigned: [],
        draggedItem: {},
    });

    useEffect(() => {
        axios
            .get("/HumanResources/Employee/Task/GetAll")
            .then((response) => {
                const assigned = [];
                const unassigned = [];
                response.data.data.map((item) => {
                    // Check if the task is found in the employee's allowedTasks
                    if (
                        // change this below:
                        employeeData.allowedTasks?.find((x) => x.id === item.id)
                    ) {
                        // If found, add the permission to the "assigned" array
                        assigned.push(item);
                    } else {
                        // If not found, add the permission to the "unassigned" array
                        unassigned.push(item);
                    }
                });
                // Sort the assigned and unassigned arrays alphabetically by the 'name' property
                assigned.sort((a, b) => a.name.localeCompare(b.name));
                unassigned.sort((a, b) => a.name.localeCompare(b.name));

                // Update the state with the categorized allowedTasks
                setListBoxSkillState({
                    assigned,
                    unassigned,
                    draggedItem: {},
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }, [employeeData]);

    const handleItemClick = (event, data, connectedData) => {
        setListBoxSkillState({
            ...listBoxSkillState,
            [data]: listBoxSkillState[data].map((item) => {
                if (item.name === event.dataItem.name) {
                    item[SELECTED_FIELD] = !item[SELECTED_FIELD];
                } else if (!event.nativeEvent.ctrlKey) {
                    item[SELECTED_FIELD] = false;
                }
                return item;
            }),
            [connectedData]: listBoxSkillState[connectedData].map((item) => {
                item[SELECTED_FIELD] = false;
                return item;
            }),
        });
    };
    const handleToolBarClick = (e) => {
        let toolName = e.toolName || "";
        let result = processListBoxData(
            listBoxSkillState.assigned,
            listBoxSkillState.unassigned,
            toolName,
            SELECTED_FIELD
        );
        setListBoxSkillState({
            ...listBoxSkillState,
            assigned: result.listBoxOneData,
            unassigned: result.listBoxTwoData,
        });
    };
    const saveSkills = async () => {
        try {
            const taskIds = listBoxSkillState.assigned.map((item) => item.id);
            const employeeId = employeeData.id;
            const requestData = {
                employeeId,
                allowedTaskIds: taskIds,
            };

            await axios.post(
                "/HumanResources/Employee/Task/SetAllowedTasks",
                requestData
            );

            employeeData.allowedTasks = listBoxSkillState.assigned;
            showToast("Skills saved successfully", "success");
        } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error("Error saving permissions:", error);
        }
    };

    return (
        <div>
            <div className="listbox-container">
                <div className="col k-pr-2">
                    <h5>Assigned Skills</h5>
                    <ListBox
                        style={{
                            height: 400,
                            width: "100%",
                        }}
                        data={listBoxSkillState.assigned}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e) =>
                            handleItemClick(e, "assigned", "unassigned")
                        }
                    />
                </div>
                <div className="col k-pr-2">
                    <h5>Unassigned Skills</h5>
                    <ListBox
                        style={{
                            height: 400,
                            width: "100%",
                        }}
                        toolbar={() => {
                            return (
                                <ListBoxToolbar
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    tools={[
                                        "transferTo",
                                        "transferFrom",
                                        "transferAllTo",
                                        "transferAllFrom",
                                    ]}
                                    data={listBoxSkillState.assigned}
                                    dataConnected={listBoxSkillState.unassigned}
                                    onToolClick={handleToolBarClick}
                                />
                            );
                        }}
                        data={listBoxSkillState.unassigned}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e) =>
                            handleItemClick(e, "unassigned", "assigned")
                        }
                    />
                </div>
            </div>
            <div className="save-button-container">
                <ActionButton
                    requiredPermissions={[
                        PermissionsEnum.SetEmployeeAllowedTasks,
                    ]}
                    onClick={saveSkills}
                    style={{ marginTop: "10px" }}
                >
                    Save Skills
                </ActionButton>
            </div>
        </div>
    );
};

export default ListBoxComponent;
