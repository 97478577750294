import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ActionButton } from "../Buttons/ActionButton.jsx";

const AlertModal = ({ message, visible, onClose }) => {
    return (
        <>
            {visible && (
                <Dialog
                    title={"Alert"}
                    onClose={onClose}
                    width={300}
                    height={"auto"}
                >
                    <p
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            margin: "10px",
                        }}
                    >
                        {message}
                    </p>
                    <ActionButton
                        onClick={onClose}
                        style={{ width: "100px", margin: "10px" }}
                    >
                        OK
                    </ActionButton>
                </Dialog>
            )}
        </>
    );
};
export default AlertModal;
