import { useContext, useMemo } from "react";
import { AuthContext } from "../providers/Deprecated/Authentication/User/AuthProvider.jsx";

/**
 * @function useUserPermissions
 * @description A hook to get the permissions of the current user
 * @return {Array} - Array of permissions
 */
export const useUserPermissions = () => {
    const authContext = useContext(AuthContext);
    const permissions = authContext?.user?.role?.permissions ?? [];

    return useMemo(() => permissions, [authContext?.user?.role?.permissions]);
};
